import {createContext, FC, ReactNode, useEffect, useMemo, useState} from "react";
import {config} from "../../config";
import {useInterval} from "../../util/useInterval";
import {MatchedItem, SavedMatch, useApiCall, useApiEndpoint} from "../../../../lib/src/api";
import {usePermissions} from "../../resources/PermissionsHook";
import {Call} from "../Call";
import {formatCallPhoneNumber} from "../formatCallPhoneNumber";
import {useTelephoneSocket} from "../hooks/useTelephoneSocket";
import {useParsedMessages} from "../hooks/useParsedMessages";

export type MatchMap = {
  [matchType: string]: {
    [matchId: number]: SavedMatch[]
  }
}
export type MatchCache = {
  [formattedPhoneNumber: string]: MatchedItem[]|'loading'
}


type TelephoneContextParams = {
  children: ReactNode
}
type TelephoneMatchesContextType = {
  matchedMap: MatchMap,
  isSearchingMatch: (call: Call) => boolean,
  findCachedMatch: (call: Call) => MatchedItem|undefined,
  clearCachedMatch: (call: Call) => void,
}
export const TelephoneMatchesContext = createContext<TelephoneMatchesContextType>({} as TelephoneMatchesContextType)

export const TelephoneMatchesProvider: FC<TelephoneContextParams> = ({children}) => {
  const {telephone} = useApiCall(config)
  const socket = useTelephoneSocket()
  const {calls} = useParsedMessages(socket.status === 'ready' ? socket.messages : [])
  const {canAccessPhone} = usePermissions()
  const {resource, reload} = useApiEndpoint(() => telephone.browseMatches(), canAccessPhone)
  const [matchedCache, setMatchedCache] = useState<MatchCache>({})

  useEffect(() => {
    Object.values(calls)
      .filter(call => {
        return matchedCache[formatCallPhoneNumber(call)] === undefined
      })
      .forEach(call => {
        const phoneNumber = formatCallPhoneNumber(call)
        setMatchedCache({...matchedCache, [phoneNumber]: 'loading'})
        telephone.matchPhone(phoneNumber)
          .then(matches => {
            setMatchedCache({...matchedCache, [phoneNumber]: matches})
          })
          .catch(() => {
            setMatchedCache({...matchedCache, [phoneNumber]: []})
          })
      })
  }, [matchedCache, calls]);

  useEffect(() => {
    if (canAccessPhone) reload()
  }, [canAccessPhone])
  useInterval(() => {
    if (canAccessPhone) {
      reload()
    }
  }, 1000 * 5)
  const matchedMap = useMemo(() => {
    return resource?.reduce((acc, match): MatchMap => {
      if (!(match.match_type in acc)) {
        acc[match.match_type] = {}
      }
      if (!(match.match_id in acc[match.match_type])) {
        acc[match.match_type][match.match_id] = []
      }
      acc[match.match_type][match.match_id].push(match)
      return acc
    }, {} as MatchMap) ?? ({} as MatchMap)
  }, [resource])

  return <TelephoneMatchesContext.Provider value={{
    matchedMap,
    isSearchingMatch: (call: Call) => {
      return matchedCache[formatCallPhoneNumber(call)] === 'loading'
    },
    findCachedMatch: (call: Call) => {
      const value = matchedCache[formatCallPhoneNumber(call)]
      if (value === undefined) {
        return undefined
      }
      if (value === 'loading') {
        return undefined
      }
      if (value.length !== 1) {
        return undefined
      }
      return value[0]
    },
    clearCachedMatch: (call: Call) => {
      const formattedPhoneNumber = formatCallPhoneNumber(call)
      if (matchedCache[formattedPhoneNumber] !== 'loading') {
        setMatchedCache(old => Object.fromEntries(Object.entries(old).filter(([key]) => key !== formattedPhoneNumber)))
      }
    },
  }}>
    {children}
  </TelephoneMatchesContext.Provider>
}
