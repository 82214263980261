import React, {FC, useState} from "react";
import * as fa from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface ButtonProps {
  type: "primary"|"secondary"|"danger"|"white"
  size: "xs"|"sm"|"md"|"lg"
  text: string
  icon?: fa.IconDefinition
  submit?: true
  disabled?: boolean
  loading?: boolean
  onClick?: () => void|Promise<void>
}

export const Button: FC<ButtonProps> = props => {
  const height: string = {
    "xs": "h-8 flex-row",
    "sm": "h-10 flex-row",
    "md": "h-16 flex-row",
    "lg": "h-24 flex-col gap-2 text-lg"
  }[props.size]
  const type: string = {
    "primary": "bg-brand-primary text-brand-on-primary",
    "secondary": "bg-transparent hover:bg-brand-100 text-brand-600 hover:text-brand-700 border border-slate-200",
    "danger": "bg-transparent hover:bg-red-100 text-red-600 hover:text-red-700",
    "white": "bg-white hover:bg-brand-100 text-brand-600 hover:text-brand-700",
  }[props.type]
  const disabledStyle: string = {
    'primary': 'bg-slate-500 text-white',
    'secondary': 'text-slate-500',
    'danger': 'bg-slate-500 text-white',
    'white': 'bg-slate-500 text-white',
  }[props.type]
  const [isSubmittingState, setIsSubmitting] = useState(false)
  const isSubmitting = props.loading || isSubmittingState
  const onClick = async () => {
    if (props.disabled || isSubmitting || !props.onClick) return
    const result = props.onClick()
    if (result instanceof Promise) {
      setIsSubmitting(true)
      await result
      setIsSubmitting(false)
    }
  }
  return <button type={props.submit ? "submit" : "button"}
                 onClick={onClick}
                 disabled={props.disabled || isSubmitting}
                 className={`${height} ${(props.disabled||isSubmitting) ? `${disabledStyle} cursor-not-allowed` : type} font-medium rounded-full px-5 flex justify-center items-center whitespace-nowrap`}
  >
    {(props.icon || isSubmitting) && <FontAwesomeIcon icon={isSubmitting ? fa.faCircleNotch : props.icon!} spin={isSubmitting} className={"mr-3 w-4"} />}{props.text}
  </button>
}
export const IconButton: FC<Pick<ButtonProps, 'type'|'disabled'|'size'|'icon'|'onClick'> & {icon: fa.IconDefinition}> = props => {
  const height: string = {
    "xs": "px-2 h-8 w-8",
    "sm": "px-5 h-10 w-10",
    "md": "px-5 h-16 w-16",
    "lg": "px-5 h-24 text-lg"
  }[props.size]
  const type: string = {
    "primary": "bg-brand-primary text-brand-on-primary",
    "secondary": "bg-transparent hover:bg-brand-100 text-brand-600 hover:text-brand-700 border border-slate-200",
    "danger": "bg-transparent hover:bg-red-100 text-red-600 hover:text-red-700",
    "white": "bg-white hover:bg-brand-100 text-brand-600 hover:text-brand-700",
  }[props.type]
  const disabledStyle: string = {
    'primary': 'bg-slate-500 text-white',
    'secondary': 'text-slate-500',
    'danger': 'bg-slate-500 text-white',
    'white': 'bg-slate-500 text-white',
  }[props.type]
  const [isSubmitting, setIsSubmitting] = useState(false)
  const onClick = async () => {
    if (props.disabled || isSubmitting || !props.onClick) return
    const result = props.onClick()
    if (result instanceof Promise) {
      setIsSubmitting(true)
      await result
      setIsSubmitting(false)
    }
  }
  return <button onClick={onClick} className={`${height} ${(props.disabled||isSubmitting) ? `${disabledStyle} cursor-not-allowed` : type} font-medium rounded-full flex justify-center items-center whitespace-nowrap`} type={"button"}>
    <FontAwesomeIcon icon={isSubmitting ? fa.faCircleNotch : props.icon} spin={isSubmitting} />
  </button>
}