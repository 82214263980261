import React, {useEffect, useMemo, useState} from "react"
import {useEmployeesData} from "../../resources/EmployeeContext";
import {usePermissions} from "../../resources/PermissionsHook";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import { ContentContainer } from "@jane/lib/src/components/content/ContentContainer";
import { Loading } from "@jane/lib/src/components/Loading";
import TwoFactorResetForm from "../../components/two-factor-reset-form";
import PictureForm from "../../components/picture-form";
import TeamsForm from "../../components/teams-form";
import RolesForm from "../../components/roles-form";
import PasswordResetForm from "../../components/password-reset-form";
import InviteForm from "../../components/invite-form";
import SyncStatus from "../../components/sync-status";
import ProfileCard from "../../components/profile-card";
import { PageHeader } from "@jane/lib/src/components/content/PageHeader";
import {Employee, useApiCall, useApiEndpoint} from "@jane/lib/src/api";
import {config} from "../../config";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWarning} from "@fortawesome/free-solid-svg-icons";
import CertificateForm from "../../components/certificate-form";


export default function EmployeesDetailPage(): JSX.Element {
  const {id} = useParams()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { employees, isLoading } = useEmployeesData()
  const permissions = usePermissions()
  const employee = useMemo(() => {
    return employees.find((e) => e.id === +(id??'0'))
  }, [employees, id])
  const { employees: employeesApi } = useApiCall(config)
  const [enrichedEmployeeResource, setEnrichedEmployeeResource] = useState<Employee>()
  const [isLoadingDetails, setIsLoadingDetails] = useState(false)

  const { teams: teamsApi } = useApiCall(config)

  const { canListTeams } = usePermissions()
  const { resource: teamResource, isLoading: loadingTeam } = useApiEndpoint(() => teamsApi.myTeam(), canListTeams)
  const { resource: secondaryTeams, isLoading: loadingSecondaryTeams } = useApiEndpoint(() => teamsApi.myTeams(), canListTeams)
  const myTeams = useMemo(() => {
    return [teamResource?.id, ...(secondaryTeams || []).map(t => t.id)].filter(x => !! x) as number[]
  }, [teamResource, secondaryTeams])
  const hasLimitedAccess = permissions.canLimitedListAllTeams && (employee?.team_id === null || !myTeams.includes(employee?.team_id ?? -1))
  const loading = isLoading || loadingTeam || loadingSecondaryTeams
  useEffect(() => {
    if (id && !enrichedEmployeeResource && !loading && !permissions.canLimitedListAllTeams) {
      setIsLoadingDetails(true)
      employeesApi.getById(id).then((e) => {
        setEnrichedEmployeeResource(e)
        setIsLoadingDetails(false)
      })
    }
  }, [enrichedEmployeeResource, loading, id, employeesApi, permissions.canLimitedListAllTeams]);
  function goBack() {
    if (searchParams.has('taskContext')) {
      navigate(`/tasks/${searchParams.get('taskContext')}`)
    } else {
      navigate(-1)
    }
  }

  return <ContentContainer>
    <Loading loading={loading}>
      { employee ? <>
        <a className={"underline cursor-pointer text-brand-primary"} onClick={goBack}>Terug naar overzicht</a>

        <PageHeader>{ employee.name }</PageHeader>

        {hasLimitedAccess && <div className={"bg-yellow-100 text-yellow-800 py-4 px-6 rounded-md mb-8"}>
          <FontAwesomeIcon icon={faWarning} className={"text-yellow-800 mr-3"} />
          Let op, je hebt beperkte toegang tot deze medewerker.
        </div>}

        <div className={"flex"}>
          <div className={"my-6 w-64 relative hidden md:block"}>
            <div className={"sticky top-0 flex flex-col space-y-2 py-2"}>
              <a className={"underline cursor-pointer text-brand-primary"} href={"#profile"}>Profiel</a>
              { permissions.canResetEmployee2fa && <a className={"underline cursor-pointer text-brand-primary"} href={"#2fa"}>2FA</a>}
              { permissions.canManageEmployeePicture && !hasLimitedAccess && <a className={"underline cursor-pointer text-brand-primary"} href={"#picture"}>Profielfoto</a>}
              { permissions.canSeeEmployeeLearnCertificates && !hasLimitedAccess && <a className={"underline cursor-pointer text-brand-primary"} href={"#certificaten"}>Certificaten</a>}
              { permissions.canManageEmployeeTeams && !hasLimitedAccess && employee.role === "Coordinator" && <a className={"underline cursor-pointer text-brand-primary"} href={"#teams"}>Teams</a>}
              { permissions.canManageEmployeeRoles && !hasLimitedAccess && ["Werknemer", "Coordinator"].includes(employee.role) && <a className={"underline cursor-pointer text-brand-primary"} href={"#role"}>Rol</a>}
              { permissions.canResetEmployeePassword && !hasLimitedAccess && <a className={"underline cursor-pointer text-brand-primary"} href={"#password_reset"}>Wachtwoord reset</a>}
              { permissions.canSendEmployeeInvite && !hasLimitedAccess && <a className={"underline cursor-pointer text-brand-primary"} href={"#invite"}>Uitnodigen</a>}
              { permissions.canSeeEmployeeSyncStatus && !hasLimitedAccess && <a className={"underline cursor-pointer text-brand-primary"} href={"#sync"}>Sync status</a>}
            </div>
          </div>
          <div className={"flex-1 flex flex-col space-y-8"}>
            <ProfileCard person={employee} />

            { employee.sub ? <>
              { permissions.canResetEmployee2fa && <TwoFactorResetForm employee={employee} />}
              { permissions.canManageEmployeePicture && !hasLimitedAccess && <PictureForm employee={employee} />}
              { permissions.canSeeEmployeeLearnCertificates && !hasLimitedAccess && <CertificateForm employee={employee} />}
              { permissions.canManageEmployeeTeams && !hasLimitedAccess && employee.role === "Coordinator" && <TeamsForm employee={employee} />}
              { permissions.canManageEmployeeRoles && !hasLimitedAccess && ["Werknemer", "Coordinator"].includes(employee.role) && <RolesForm employee={employee} roles={enrichedEmployeeResource!} reload={() => {
                setIsLoadingDetails(true)
                employeesApi.getById(id!).then((e) => {
                  setEnrichedEmployeeResource(e)
                  setIsLoadingDetails(false)
                })
              }} /> }
              { permissions.canResetEmployeePassword && !hasLimitedAccess && <PasswordResetForm subject={employee.sub} />}
              { permissions.canSendEmployeeInvite && !hasLimitedAccess && <InviteForm subject={employee.sub} type="employee" />}
            </> : <>
            </> }
            { permissions.canSeeEmployeeSyncStatus && !hasLimitedAccess && <SyncStatus type="employee" objectId={employee.id} /> }
          </div>
        </div>
      </> : <>
        We kunnen de medewerker die u zoekt niet vinden.
      </> }
    </Loading>
  </ContentContainer>
}
