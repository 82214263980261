import React, {FC, Fragment} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {faPhone} from "@fortawesome/free-solid-svg-icons";
import { usePersistentState } from "@jane/lib/src/util/usePersistentState";
import {TelephoneSidebarLoginForm} from "../telephone/components/TelephoneSidebarLoginForm";
import {TelephoneSidebarCallManager} from "../telephone/components/TelephoneSidebarCallManager";
import {useTelephoneAuthentication} from "../telephone/hooks/useTelephoneAuthentication";
import {usePermissions} from "../resources/PermissionsHook";

export const Toolbar: FC<{}> = () => {
  const [showTelephone, setShowTelephone] = usePersistentState<boolean>('toolbar.showTelephone',true)
  const phoneAuth = useTelephoneAuthentication()
  const { canSeePhoneSidebar } = usePermissions()
  const toolbarButtons = [
    // Telephone
    ...((canSeePhoneSidebar && (phoneAuth.status === 'login_required' || phoneAuth.status === 'ready')) ? [
      <ToolbarButton icon={faPhone} selected={showTelephone} setSelected={setShowTelephone} />
    ] : []),
  ]
  if (toolbarButtons.length === 0) {
    return <></>
  }
  return <aside
    className={`bg-brand-on-secondary text-brand-secondary transition-all border-l border-slate-200 flex items-stretch overflow-hidden`}>
    {/* Navigation */}
    {showTelephone && <TelephoneBanner />}
    <div className={'flex flex-col w-16'}>
      {toolbarButtons.map((button, i) => <Fragment key={i}>{button}</Fragment>)}
    </div>
  </aside>
}
const ToolbarButton: FC<{icon: IconProp, selected: boolean, setSelected: (newVal: boolean) => void}> = props => {
  return <button className={`w-12 h-12 mt-2 mx-2 rounded ${props.selected ? 'text-brand-primary' : 'text-brand-secondary'} ${props.selected ? 'bg-brand-primary bg-opacity-10' : 'bg-transparent'}`} onClick={() => props.setSelected(!props.selected)}>
    <FontAwesomeIcon icon={props.icon}/>
  </button>
}
const TelephoneBanner: FC<{}> = () => {
  const phoneAuth = useTelephoneAuthentication()
  return <div className={'border-r border-slate-200 flex flex-col w-80 pt-2 justify-start bg-brand-on-secondary'}>
    <div className={"h-14 flex items-center font-bold pb-2 text-lg border-b px-3 border-slate-200"}>
      <h2 className={""}>Telefooncentrale</h2>
    </div>
    <div className={'px-3 py-2'}>
      {phoneAuth.status === "login_required" && <TelephoneSidebarLoginForm />}
      {phoneAuth.status === "ready" && <TelephoneSidebarCallManager />}
    </div>
  </div>
}