import React from "react"
import { ContentContainer } from "@jane/lib/src/components/content/ContentContainer"
import {useNavigate} from "react-router-dom";
import { Button } from "@jane/lib/src/components/form/Button"
import { useAuth } from "@jane/lib/src/auth/hook";
import { PageHeader } from "@jane/lib/src/components/content/PageHeader";
import { SectionHeader } from "@jane/lib/src/components/content/SectionHeader";
import { Card } from "@jane/lib/src/components/Card";
import {faCircleNotch, faPeopleGroup, faPerson, faSignIn} from "@fortawesome/free-solid-svg-icons";
import {faSquareCheck} from "@fortawesome/free-regular-svg-icons";
import {useTaskData} from "../resources/TaskContext";
import {useClientData} from "../resources/ClientContext";
import {usePermissions} from "../resources/PermissionsHook";
import {useEmployeesData} from "../resources/EmployeeContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function Home(): JSX.Element {

  const { user } = useAuth()
  const { tasks, isLoading: tasksLoading } = useTaskData()
  const { employees, isLoading: employeesLoading } = useEmployeesData()
  const { clients, isLoading: clientsLoading } = useClientData()
  const permissions = usePermissions()
  const navigate = useNavigate()
  const employee = employees.find((e) => e.sub === user?.sub)

  return <ContentContainer size={'lg'}>
    <PageHeader>Overzicht</PageHeader>
    <div className={"flex flex-col lg:flex-row lg:space-x-16"}>
      <div className={"flex-[2] mb-8"}>
        <SectionHeader>Hallo { user?.given_name }</SectionHeader>
        <p className={"mb-5"}>
          Welkom in de MiepApp. Hier kun je van alles regelen. Bijvoorbeeld een
          sociale kaart aan een regio koppelen. Je kunt collega&apos;s toegang
          geven of juist ontnemen tot verschillende delen van de MiepApp.
          Daarbij kun je de MiepApp helemaal instellen zodat het aansluit bij
          jouw bedrijf.
        </p>
        <div className={"grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-8"}>
          <Card title={'Taken'} icon={faSquareCheck}>
            <div className={"flex flex-col items-center"}>
              <span className={"font-bold text-lg"}>{tasksLoading ? <FontAwesomeIcon icon={faCircleNotch} spin={true} /> : tasks.length }</span>
              <span className={"text-sm"}>{tasks.filter(t => t.status === "pending").length} openstaande taken</span>
            </div>
            <div className={"flex flex-col items-stretch mt-4"}>
              <Button type={'primary'} size={'sm'} text={'Naar taken'} onClick={() => navigate('/tasks')} />
            </div>
          </Card>
          {permissions.canAccessClients && <Card title={'Cliënten'} icon={faPerson}>
            <div className={"flex flex-col items-center"}>
              <span className={"font-bold text-lg"}>{clientsLoading ?
                <FontAwesomeIcon icon={faCircleNotch} spin={true}/> : clients.length}</span>
              <span className={"text-sm"}>netwerk & toegang</span>
            </div>
            <div className={"flex flex-col items-stretch mt-4"}>
              <Button type={'primary'} size={'sm'} text={'Alle cliënten'} onClick={() => navigate('/clients')} />
            </div>
          </Card>}
          { permissions.canAccessEmployees && <Card title={'Medewerkers'} icon={faPeopleGroup}>
            <div className={"flex flex-col items-center"}>
              <span className={"font-bold text-lg"}>{employeesLoading ?
                <FontAwesomeIcon icon={faCircleNotch} spin={true}/> : employees.length}</span>
              <span className={"text-sm"}>team & rechtenbeheer</span>
            </div>
            <div className={"flex flex-col items-stretch mt-4"}>
              <Button type={'primary'} size={'sm'} text={'Alle medewerkers'} onClick={() => navigate('/employees')} />
            </div>
          </Card>}
        </div>
      </div>
      <div className={"flex-1 space-y-8"}>
        <SectionHeader>Inloggen bij</SectionHeader>
        <Card>
          <div className={"flex flex-col items-stretch"}>
            <div className={'h-20 flex items-center justify-center'}>
              <img alt={""} className={"max-h-10 max-w-[70%]"} src={'/img/afas.png'}/>
            </div>
            <Button
              type="primary"
              icon={faSignIn}
              text={"Log in bij AFAS"}
              onClick={() => {window.open('https://88777.afasinsite.nl', '_blank')}}
              size={'sm'}
            />
          </div>
        </Card>
        <Card>
          <div className={"flex flex-col items-stretch"}>
            <div className={'h-20 flex items-center justify-center'}>
              <img alt={""} className={"max-h-10 max-w-[70%]"} src={'/img/ons.png'}/>
            </div>
            <Button
              type="primary"
              text={"Log in bij ONS"}
              icon={faSignIn}
              onClick={() => {
                window.open('https://miep.startmetons.nl/auth/oidc/e369bc76-0ba1-4e53-aa29-e3b0dad9f75e', '_blank')
              }}
              size={'sm'}
            />
          </div>
        </Card>
        {employee && <>
          <SectionHeader>Jouw gegevens</SectionHeader>
          <Card>
            <dl>
              <dt className={"font-medium"}>Naam</dt>
              <dd>{employee?.name}</dd>
              <hr className={'-mx-6 border-brand-primary my-3 opacity-20'} />
              <dt className={"font-medium"}>Personeelsnummer</dt>
              <dd>{employee.employee_number}</dd>
              <hr className={'-mx-6 border-brand-primary my-3 opacity-20'} />
              <dt className={"font-medium"}>Telefoon / E-mail</dt>
              <dd>{employee.preferred_username || employee.email}</dd>
            </dl>
          </Card>
        </>}

        {/*{permissions.canAccessEmployees && employee ? <>*/}
        {/*  <div className="my-5">*/}
        {/*    <div>*/}
        {/*      <dl>*/}
        {/*        <dt>Naam</dt>*/}
        {/*        <dd>{employee.name}</dd>*/}
        {/*        <dt>Personeelsnummer</dt>*/}
        {/*        <dd>{employee.employee_number}</dd>*/}
        {/*        <dt>Telefoon / E-mail</dt>*/}
        {/*        <dd>{employee.preferred_username || employee.email}</dd>*/}
        {/*      </dl>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</> : <>*/}
        {/*  <div className="my-5">*/}
        {/*    <div>*/}
        {/*      <dl>*/}
        {/*        <dt>Naam</dt>*/}
        {/*        <dd>{user?.name}</dd>*/}
        {/*        <dt>Telefoon / E-mail</dt>*/}
        {/*        <dd>{user?.preferred_username}</dd>*/}
        {/*      </dl>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</>}*/}
      </div>
    </div>
  </ContentContainer>
}
