import {createContext, FC, ReactNode, useCallback, useContext, useEffect, useState} from "react";
import {usePermissions} from "../../resources/PermissionsHook";
import {config} from "../../config";
import * as Sentry from "@sentry/react";
import {useInterval} from "../../util/useInterval";
import {useTelephoneAuthentication} from "../hooks/useTelephoneAuthentication";
import {useToasts} from "../../../../lib/src/toasts/toasts";

type TelephoneContextParams = {
  children: ReactNode
}
type TelephoneSocketContextType = {
  status: "no_access"
} | {
  status: "ready",
  connected: boolean
  messages: any[],
  forwardCall: (extension: string, callId: string) => void,
  holdCall: (callId: string) => void,
  reconnectCall: (callId: string) => void,
  disconnectCall: (callId: string) => void,
  pickupCall: (callId: string) => void,
  dialCall: (target: string) => void,
}
export const TelephoneSocketContext = createContext<TelephoneSocketContextType>({} as TelephoneSocketContextType)

export const TelephoneSocketProvider: FC<TelephoneContextParams> = ({children}) => {
  const { canAccessPhone } = usePermissions()
  const auth = useTelephoneAuthentication()

  const [connected, setConnected] = useState(false)
  const [socket, setSocket] = useState<WebSocket>()
  const [messages, setMessages] = useState<any[]>([])
  const {showToast} = useToasts()

  const connect = useCallback(() => {
    if (auth.status !== 'ready') {
      return
    }
    const s = new WebSocket(config.telephoneServiceUrl)
    s.onopen = () => {
      setConnected(true)
      setSocket(s)
    }
    s.onmessage = (e) => {
      Sentry.addBreadcrumb({
        message: 'Received Telephone Message',
        data: e.data,
      })
      let data: any
      try {
        data = JSON.parse(e.data)
      } catch (e) {
        Sentry.captureException(e)
        console.error('Error parsing data', data, e)
        return
      }
      if (data.type === 'ping') {
        s.send(JSON.stringify({command: 'pong', payload: ''}))
      } else if (data.type === 'hello') {
        s.send(JSON.stringify({command: 'login', payload: auth.credentials.token}))
      } else if (data.type === 'goodbye') {
        s.close()
      } else if (data.type === 'login' && data.payload?.success === false) {
        s.close()
        auth.onLoginFailed('Inloggen mislukt, gebruikersnaam en wachtwoord niet geldig.')
      } else if (data.type === 'hold') {
        if (data.payload?.success === false) {
          showToast('Telefooncentrale', 'Gesprek kon niet in de wacht worden gezet')
        }
      } else if (data.type === 'forward') {
        if (data.payload?.success === false) {
          showToast('Telefooncentrale', 'Doorverbinden mislukt')
        }
      } else if (data.type === 'reconnect') {
        if (data.payload?.success === false) {
          showToast('Telefooncentrale', 'Gesprek kon niet uit de wacht worden gehaald')
        }
      } else if (data.type === 'disconnect') {
        if (data.payload?.success === false) {
          showToast('Telefooncentrale', 'Ophangen mislukt')
        }
      } else {
        setMessages((messages) => [...(messages.slice(Math.max(messages.length - 30,0))), data])
      }
    }
    s.onclose = () => {
      setConnected(false)
    }
    return s
  }, [auth])

  useInterval(() => {
    if (!connected && auth.status === 'ready') {
      console.log('reconnecting...')
      connect()
    }
  }, 3000)

  const forwardCall = (extension: string, callId: string) => {
    socket?.send(JSON.stringify({command: 'forward', payload: {extension: callId, callId: extension}}))
  }
  const holdCall = (callId: string) => {
    socket?.send(JSON.stringify({command: 'hold', payload: {callId}}))
  }
  const reconnectCall = (callId: string) => {
    socket?.send(JSON.stringify({command: 'reconnect', payload: {callId}}))
  }
  const disconnectCall = (callId: string) => {
    socket?.send(JSON.stringify({command: 'disconnect', payload: {callId}}))
  }
  const pickupCall = (callId: string) => {
    socket?.send(JSON.stringify({command: 'pickup', payload: {callId}}))
  }
  const dialCall = (target: string) => {
    socket?.send(JSON.stringify({command: 'dial', payload: {target}}))
  }

  if (auth.status !== 'ready' || !canAccessPhone) {
    return <TelephoneSocketContext.Provider value={{status: "no_access"}}>{children}</TelephoneSocketContext.Provider>
  }

  return <TelephoneSocketContext.Provider value={{
    status: "ready",
    connected,
    messages,
    forwardCall,
    holdCall,
    reconnectCall,
    disconnectCall,
    pickupCall,
    dialCall,
  }}>
    {children}
  </TelephoneSocketContext.Provider>
}
