import {FC, ReactNode, useMemo, useState} from "react";
import {useTelephoneSocket} from "../hooks/useTelephoneSocket";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faCircleNotch,
  faPhoneFlip,
  faPhone,
  IconDefinition,
  faHashtag
} from "@fortawesome/free-solid-svg-icons";
import {faPause as fasPause} from "@fortawesome/free-solid-svg-icons";
import {useTelephoneAuthentication} from "../hooks/useTelephoneAuthentication";
import {useParsedMessages} from "../hooks/useParsedMessages";
import {Call} from "../Call";
import {useMatches} from "../hooks/useMatches";
import {formatCallPhoneNumber} from "../formatCallPhoneNumber";
import {Button} from "../../../../lib/src/components/form/Button";
import {
  faForward,
  faShuffle,
  faPause,
  faPhoneHangup,
  faPhoneArrowUp,
  faPerson,
  faUser,
  faUsers, faQuestion
} from "@fortawesome/pro-light-svg-icons";
import {JaneEmployee, useEmployeesData} from "../../resources/EmployeeContext";
import {useClientData} from "../../resources/ClientContext";
import {useClientContactData} from "../../resources/ClientContactContext";
import {useTeamData} from "../../resources/TeamContext";
import {Client, ClientContactDetails, Team, useApiCall, useApiEndpoint} from "../../../../lib/src/api";
import {useModal} from "../../components/ModalProvider";
import {ForwardCallModal} from "../../modals/ForwardCallModal";
import {AddCallbackRequestModal} from "../../modals/AddCallbackRequestModal";
import { Input } from "@jane/lib/src/components/form/Input";
import {config} from "../../config";
import {BuddyTeam, PhoneDirectoryEntry} from "../../../../lib/src/api/repositories/buddy-teams-repository";

export const TelephoneSidebarCallManager: FC<{}> = props => {
  const auth = useTelephoneAuthentication()
  const socket = useTelephoneSocket()
  const {incomingCalls, holdCalls, focussedCall} = useParsedMessages(socket.status === 'ready' ? socket.messages : [])
  const { buddyTeams: buddyTeamsApi, teams: teamsApi } = useApiCall(config)
  const { resource: buddyTeams, isLoading, reload } = useApiEndpoint(() => buddyTeamsApi.all())
  const { teams } = useTeamData()
  if (socket.status !== "ready" || auth.status !== "ready") {
    return <></>
  }
  return <div className={"flex flex-col items-stretch text-sm text-brand-on-background"}>

    {/* Connection status */}
    <div>
      {socket.connected ? <div className={"text-xs font-medium text-green-700 inline-flex items-center h-6 pl-1 pr-2 rounded bg-green-100"}>
        <FontAwesomeIcon icon={faCheckCircle} className={'w-4 mr-1'} />
        <div>Verbonden</div>
      </div> : <div className={"text-xs font-medium text-slate-700 inline-flex items-center h-6 pl-1 pr-2 rounded bg-slate-100"}>
        <FontAwesomeIcon icon={faCircleNotch} spin={true} className={'w-4 mr-1'}/>
        <div>Verbinden...</div>
      </div>}
    </div>

    {/* Profile */}
    <div className={"rounded border border-slate-200 px-2 py-1 my-2 flex items-end justify-between"}>
      <div>
        <div className={"text-xs font-medium text-slate-800"}>Ingelogd als</div>
        <div className={'overflow-ellipsis'}>{auth.credentials.user}</div>
      </div>
      <button className={"border border-slate-200 rounded h-8 w-8 my-1 flex items-center justify-center hover:bg-slate-100 hover:text-brand-primary"} onClick={() => auth.onLoginFailed()}>
        <FontAwesomeIcon icon={faShuffle} />
      </button>
    </div>

    {/* New call */}
    <TelephoneCallMangerSection icon={faHashtag} title={'Nieuw gesprek'}>
      <DialComponent />
    </TelephoneCallMangerSection>

    {/* Current call */}
    <TelephoneCallMangerSection icon={faPhoneFlip} title={'Huidig gesprek'}>
      {/*<DialComponent />*/}
      {focussedCall ? <FocussedCallComponent call={focussedCall} buddyTeams={buddyTeams?.buddyTeams ?? []} teams={teams} directory={buddyTeams?.directory ?? []} /> : <>
        <div className={"bg-slate-100 rounded-lg text-slate-500 text-xs text-center py-3"}>Geen huidig of recent gesprek</div>
      </>}
    </TelephoneCallMangerSection>

    {/* On-Hold calls */}
    <TelephoneCallMangerSection icon={fasPause} title={'Gesprekken in de wacht'}>
      {holdCalls.length > 0 ? <>
        {holdCalls.map((c, k) => <OnHoldCallComponent call={c} key={k} />)}
      </> : <>
        <div className={"bg-slate-100 rounded-lg text-slate-500 text-xs text-center py-3"}>Geen gesprekken in de wacht</div>
      </>}
    </TelephoneCallMangerSection>

    {/* Incoming calls */}
    <TelephoneCallMangerSection icon={faPhone} title={'Inkomende gesprekken'}>
      {incomingCalls.length > 0 ? <>
        {incomingCalls.map((c, k) => <IncomingCallComponent call={c} key={k} />)}
      </> : <>
        <div className={"bg-slate-100 rounded-lg text-slate-500 text-xs text-center py-3"}>Geen inkomende gesprekken</div>
      </>}
    </TelephoneCallMangerSection>
  </div>
}

const FocussedCallComponent: FC<{call: Call, buddyTeams: BuddyTeam[], directory: PhoneDirectoryEntry[], teams: Team[]}> = props => {
  const {employees} = useEmployeesData()
  const {clients} = useClientData()
  const {findCachedMatch} = useMatches()
  const socket = useTelephoneSocket()
  const cachedMatch = findCachedMatch(props.call)
  const callbackRequestModal = useModal({title: "Terugbelverzoek aanmaken", body: <AddCallbackRequestModal clients={clients} cachedMatch={cachedMatch} employees={employees} call={props.call} teams={props.teams} buddyTeams={props.buddyTeams} directory={props.directory} />})
  const forwardModal = useModal({title: "Gesprek doorsturen", body: <ForwardCallModal onConfirmed={(extension) => {
    if (socket.status !== 'ready') return
    socket.forwardCall(props.call.callId, extension)
  }} />})
  if (socket.status !== 'ready') {
    return <></>
  }
  return <div
    className={'border border-brand-primary-container bg-brand-background text-brand-on-primary-container px-3 py-2 rounded-lg'}>
    <div className={'font-medium'}>{formatCallPhoneNumber(props.call)}</div>
    <div className={"flex items-center"}>
    </div>
    <div className={"rounded border border-slate-200 px-2 py-1 my-2 flex items-center justify-between"}>
      <CallInformation call={props.call}/>
    </div>
    <div className={'grid grid-cols-2 gap-x-1 mb-1 mt-2'}>
      <Button type={'secondary'} disabled={props.call.type === 'release'} size={'xs'} icon={faPause} text={'In-wacht'} onClick={() => socket.holdCall(props.call.callId) }/>
      <Button type={'secondary'} disabled={props.call.type === 'release'} size={'xs'} icon={faForward} text={'Doorsturen'} onClick={() => {
        forwardModal.open()
      }}/>
    </div>
    <div className={'flex flex-col items-stretch mb-1 mt-2'}>
      <Button type={'primary'} disabled={props.call.type === 'release'} size={'xs'} icon={faPhoneHangup} text={'Ophangen'} onClick={() => {
        socket.disconnectCall(props.call.callId)
      }}/>
    </div>
    <div className={'flex flex-col items-stretch mb-1 mt-2'}>
      <Button type={props.call.type === 'release' ? 'primary' : 'secondary'} size={'xs'} text={'Terugbelverzoek aanmaken'} onClick={() => {
        callbackRequestModal.open()
      }}/>
    </div>
  </div>
}

const OnHoldCallComponent: FC<{ call: Call }> = props => {
  const socket = useTelephoneSocket()
  if (socket.status !== 'ready') {
    return <></>
  }
  return <div className={"rounded border border-slate-200 px-2 py-1 mb-2 flex items-center justify-between"}>
    <div className={'flex-1'}>
      <CallInformation call={props.call}/>
    </div>
    <button
      className={"ml-2 border border-slate-200 rounded h-8 w-8 my-1 flex items-center justify-center hover:bg-slate-100 hover:text-brand-primary"}
      onClick={() => {
        socket.reconnectCall(props.call.callId)
      }}>
      <FontAwesomeIcon icon={faPhoneArrowUp}/>
    </button>
    {/*<button*/}
    {/*  className={"ml-2 border border-slate-200 rounded h-8 w-8 my-1 flex items-center justify-center hover:bg-slate-100 hover:text-brand-primary"}*/}
    {/*  onClick={() => {*/}
    {/*    socket.disconnectCall(props.call.callId)*/}
    {/*  }}>*/}
    {/*  <FontAwesomeIcon icon={faPhoneHangup}/>*/}
    {/*</button>*/}
  </div>
}

const IncomingCallComponent: FC<{ call: Call }> = props => {
  const socket = useTelephoneSocket()
  const forwardModal = useModal({title: "Gesprek doorsturen", body: <ForwardCallModal onConfirmed={(extension) => {
    if (socket.status !== 'ready') return
    socket.forwardCall(props.call.callId, extension)
  }} />})
  if (socket.status !== 'ready') {
    return <></>
  }
  return <div className={"rounded border border-slate-200 px-2 py-1 mb-2 flex items-center justify-between"}>
    <div className={'flex-1'}>
      <CallInformation call={props.call}/>
    </div>
    <button
      className={"ml-2 border border-slate-200 rounded h-8 w-8 my-1 flex items-center justify-center hover:bg-slate-100 hover:text-brand-primary"}
      onClick={() => {
        forwardModal.open()
      }}>
      <FontAwesomeIcon icon={faForward}/>
    </button>
    <button
      className={"ml-2 border border-slate-200 rounded h-8 w-8 my-1 flex items-center justify-center hover:bg-slate-100 hover:text-brand-primary"}
      onClick={() => {
        socket.pickupCall(props.call.callId)
      }}>
      <FontAwesomeIcon icon={faPhoneArrowUp}/>
    </button>
  </div>
}

const TelephoneCallMangerSection: FC<{ icon: IconDefinition, title: string, children: ReactNode }> = props => {
  return <div className={'border-t border-slate-200 -mx-3 px-3 pb-2'}>
    <h2 className={"h-12 flex items-center text-sm font-medium text-slate-800"}><FontAwesomeIcon icon={props.icon} className={"text-slate-500 mr-2"} />{props.title}</h2>
    <div>
      {props.children}
    </div>
  </div>
}

const DialComponent: FC<{}> = props => {
  const [target, setTarget] = useState('')
  const socket = useTelephoneSocket()
  function submit(event: React.FormEvent) {
    event.preventDefault()
    if (socket.status !== 'ready') {
      return
    }
    if (target.length > 0) {
      socket.dialCall(target)
    }
  }
  if (socket.status !== 'ready') {
    return <></>
  }
  return <form className={'flex items-end space-x-2 mb-2'} onSubmit={submit}>
    <Input type={'text'} placeholder={'000'} label={'Nummer/Extensie'}  value={target} onChange={setTarget} />
    <Button type={'primary'} submit size={'sm'} text={'Bel'} />
  </form>
}

const CallInformation: FC<{ call: Call }> = props => {
  const {findCachedMatch, isSearchingMatch} = useMatches()
  const {clients, isLoading: isLoadingClients} = useClientData()
  const {clientContacts, isLoading: isLoadingContacts} = useClientContactData()
  const {teams, isLoading: isLoadingTeams} = useTeamData()
  const {employees} = useEmployeesData()

  const clientMap = useMemo(() => {
    return clients.reduce((map, client) => {
      map.set(client.id, client)
      return map
    }, new Map<number, Client>())
  }, [clients])

  const clientContactMap = useMemo(() => {
    return clientContacts.reduce((map, contact) => {
      map.set(Number(contact.id), contact)
      return map
    }, new Map<number,ClientContactDetails>())
  }, [clientContacts])

  const teamMap = useMemo(() => {
    return teams.reduce((map, team) => {
      map.set(team.id, team)
      return map
    }, new Map<number,Team>())
  }, [teams])

  const employeeMap = useMemo(() => {
    return employees.reduce((map, employee) => {
      map.set(employee.id, employee)
      return map
    }, new Map<number,JaneEmployee>())
  }, [employees])
  const match = findCachedMatch(props.call)
  const isLoading = isSearchingMatch(props.call) || isLoadingClients || isLoadingContacts || isLoadingTeams
  if (isLoading) {
    return <div>
      <div className={"text-xs font-medium text-slate-800"}>Zoeken...</div>
      <div><FontAwesomeIcon icon={faCircleNotch} spin={true} /></div>
    </div>
  }
  if (match === undefined) {
    return <div className={"flex"}>
      <div className={"h-7 w-7 bg-brand-primary rounded-full text-brand-on-primary flex items-center justify-center mr-2"}><FontAwesomeIcon icon={faQuestion} /></div>
      <div className={"flex-1"}>
        <div className={"text-xs font-medium text-slate-800"}>Onbekend</div>
        <div>{formatCallPhoneNumber(props.call)}</div>
      </div>
    </div>
  }
  if (match.type === 'client') {
    return <div className={"flex items-center"}>
      <div className={"h-7 w-7 bg-brand-primary rounded-full text-brand-on-primary flex items-center justify-center mr-2"}><FontAwesomeIcon icon={faPerson} /></div>
      <div className={"flex-1"}>
        <div className={"text-xs font-medium text-slate-800"}>Cliënt</div>
        <div>{clientMap.get(match.id)?.name ?? 'Onbekend'}</div>
      </div>
    </div>
  }
  if (match.type === 'employee') {
    const employee = employeeMap.get(match.id);
    return <div>
      <div className={"flex items-center"}>
        <div
          className={"h-7 w-7 bg-brand-primary rounded-full text-brand-on-primary flex items-center justify-center mr-2"}>
          <FontAwesomeIcon icon={faUser}/></div>
        <div className={"flex-1"}>
          <div className={"text-xs font-medium text-slate-800"}>Medewerker</div>
          <div>{employee?.name ?? 'Onbekend'}</div>
        </div>
      </div>
      {employee !== undefined && employee.team_id !== null && <>
        <div className={"flex items-center"}>
          <div className={"flex-1 ml-9 border-t border-slate-200 pt-1 mt-1"}>
            <div className={"text-xs font-medium text-slate-800"}>Team</div>
            <div>{teamMap.get(employee.team_id)?.name ?? 'Onbekend'}</div>
          </div>
        </div>
      </>}
    </div>
  }
  if (match.type === 'client_contact') {
    const client = clientMap.get(match.client_id)
    return <div>
      <div className={"flex items-center"}>
        <div
          className={"h-7 w-7 bg-brand-primary rounded-full text-brand-on-primary flex items-center justify-center mr-2"}>
          <FontAwesomeIcon icon={faUsers}/></div>
        <div className={"flex-1"}>
          <div className={"text-xs font-medium text-slate-800"}>Contactpersoon</div>
          <div>{clientContactMap.get(match.id)?.name ?? 'Onbekend'}</div>
        </div>
      </div>
      {client !== undefined && <>
        <div className={"flex items-center"}>
          <div className={"flex-1 ml-9 border-t border-slate-200 pt-1 mt-1"}>
            <div className={"text-xs font-medium text-slate-800"}>Belt voor Cliënt</div>
            <div>{client.name}</div>
          </div>
        </div>
      </>}
    </div>
  }
  return <div>
    <div className={"text-xs font-medium text-slate-800"}>-</div>
    <div>?</div>
  </div>
}