import {Team, useApiCall, useApiEndpoint} from "@jane/lib/src/api";
import { Checkbox } from "@jane/lib/src/components/form/Checkbox";
import { Input } from "@jane/lib/src/components/form/Input";
import { useRefresh } from "@jane/lib/src/components/RefreshController";
import React, {FC, useMemo, useState} from "react";
import {ModalFooter} from "../components/ModalProvider";
import {config} from "../config";
import {useTeamData} from "../resources/TeamContext";
import {usePermissions} from "../resources/PermissionsHook";
import {useTeamMap} from "../components/team-select";
import { Select } from "@jane/lib/src/components/form/Select";
import { Paragraph } from "@jane/lib/src/components/Paragraph";
import { useToasts } from "@jane/lib/src/toasts/toasts";

export const AssignLearnModuleToTeamModal: FC<{onFinished: () => void, moduleId: string, teamOptions: Map<number, [string, number]>}> = (props) => {
  const {learn} = useApiCall(config)
  const [team, setTeam] = useState<number|null>(null)
  const {canListAllTeams} = usePermissions()
  const teamFilterOptions = useMemo(() => {
    return Array.from(props.teamOptions).map(([id, [name, indentation]]: [number, [string, number]]): [string, string] => {
      return [id.toString(), (canListAllTeams ? "\xA0\xA0".repeat(indentation) : "") + name]
    })
  }, [props.teamOptions, canListAllTeams])
  const toasts = useToasts()

  const save = async () => {
    if (team === null) return
    await learn.assignModuleToTeam(props.moduleId, team)
    toasts.showToast('Onderwerp toegewezen', `De relevante personen krijgen hier een melding van.`)
    props.onFinished()
  }
  return <div className={"flex flex-col space-y-3"}>
    <Select label={"Team"} options={teamFilterOptions} value={team === null ? '-1' : team.toString()} onChange={(v) => setTeam(v === '-1' ? null : Number(v))} />
    <Paragraph>Wanneer je een onderwerp uitrolt bij een team krijgen alle leden (en die van onderliggende teams) van dat team een melding dat er een nieuw onderwerp klaar staat in hun kennisbank.</Paragraph>
    <ModalFooter text={'Opslaan'} onSubmit={save}/>
  </div>
}
