import {FC, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleNotch} from "@fortawesome/free-solid-svg-icons";

export const Toggle: FC<{value: boolean, onChange: (newValue: boolean) => void|Promise<void>, label?: string}> = props => {
  if (props.label) {
    return <label className={"flex items-center h-16 border border-brand-secondary rounded px-4 cursor-pointer"}>
      <ToggleSwitch value={props.value} onChange={props.onChange} />
      <span className={"ml-3"}>{props.label}</span>
    </label>
  }
  return <label><ToggleSwitch value={props.value} onChange={props.onChange} /></label>
}

const ToggleSwitch: FC<{value: boolean, onChange: (newValue: boolean) => void|Promise<void>}> = props => {
  const [changing, setChanging] = useState(false)
  const handleChange = async () => {
    setChanging(true)
    await props.onChange(!props.value)
    setChanging(false)
  }
  return <div className={`block rounded-full w-12 h-6 ${props.value ? 'bg-brand-primary ' : 'bg-brand-secondary '} transition-colors relative cursor-pointer`}>
    {/* Invisible input */}
    <input type="checkbox" checked={props.value} onChange={(e) => handleChange()} className={"hidden"}/>
    {/* Ball */}
    <div className={`h-6 w-6 border bg-white rounded-full absolute transition-all flex items-center justify-center top-0 ${props.value ? 'left-6 border-brand-800' : 'left-0 border-slate-200'}`}>
      {changing && <FontAwesomeIcon className={"text-sm text-slate-400"}  icon={faCircleNotch} spin={true} />}
    </div>
  </div>
}