import {useApiCall, useApiEndpoint} from "@jane/lib/src/api"
import { ContentContainer } from "@jane/lib/src/components/content/ContentContainer";
import { PageHeader } from "@jane/lib/src/components/content/PageHeader";
import { Loading } from "@jane/lib/src/components/Loading";
import React from "react"
import DataTable from "../../components/data-table/data-table";
import {config} from "../../config";
import {usePermissions} from "../../resources/PermissionsHook";
import {NavLink, useNavigate} from "react-router-dom";
import { Button } from "@jane/lib/src/components/form/Button";
import {faChevronRight, faPlus} from "@fortawesome/free-solid-svg-icons";
import DynamicDataTable from "../../components/data-table/dynamic-data-table";

export default function BroadcastsListPage(): JSX.Element {
  const {broadcasts: broadcastsApi} = useApiCall(config)
  const {resource: broadcasts, isLoading} = useApiEndpoint(() => broadcastsApi.get())
  const navigate = useNavigate()

  const permissions = usePermissions()

  if (! permissions.canManageBroadcasts) {
    return <ContentContainer>
      <PageHeader>Berichten</PageHeader>
      <Loading loading={isLoading}>
        {broadcasts && <DataTable keyProperty={'id'} data={broadcasts.sort((a,b) => b.updated_at.localeCompare(a.updated_at))} columns={[
          {
            property: 'subject',
            header: 'Bericht',
            transform: (text) => text.slice(0, 50),
          },
          {
            property: 'updated_at',
            header: 'Ontvangen op op',
            transform: (date) => new Date(date).toLocaleDateString('nl'),
          },
          {header: "Acties", property: 'id', transform: (id) => {
              return <NavLink to={`/broadcasts/${id}`} className={'text-brand-primary underline'}>Bekijken</NavLink>
            }},
        ]} /> }

      </Loading>
    </ContentContainer>;
  }

  return <div>
    <ContentContainer>
      <PageHeader>Publicaties</PageHeader>
      <div className={"flex items-end"}>
        <p className={"flex-1"}>
          Met publicaties kun je berichten sturen naar clienten of medewerkers binnen een bepaalde regio, of allemaal tegelijk.
        </p>
        <Button type={'primary'} size={'sm'} text={'Nieuwe publicatie'} icon={faPlus} onClick={() => navigate('/broadcasts/new')} />
      </div>

      <Loading loading={isLoading}>
        {broadcasts && <DynamicDataTable searchableProperties={["subject", "message"]} availableFilters={[
          {
            title: "Status",
            property: "status",
            options: {
              "draft": "Concept",
              "published": "Verzonden",
            },
            defaultValue: "draft"
          }
        ]} keyProperty={'id'} data={broadcasts} columns={[
          {
            property: 'subject',
            header: 'Bericht',
            transform: (text) => text.slice(0, 50),
          },
          {
            property: 'status',
            header: 'Status',
            transform: (status) => status === "draft" ? "Concept" : "Verzonden",
          },
          {
            property: 'target_type',
            header: 'Aan',
            transform: (location, row) => row.target_type === "clients" ? `Clienten` : `Medewerkers`,
          },
          {header: "Acties", property: 'id', transform: (id, row) => {
              return <Button type={'secondary'} size={'xs'} text={row.status === 'draft' ? 'Bewerken' : 'Bekijken'} icon={faChevronRight} onClick={() => navigate(`/broadcasts/${id}`)} />
            }},
        ]} /> }

      </Loading>
    </ContentContainer>
  </div>
}
