import { Button } from "@jane/lib/src/components/form/Button";
import { Input } from "@jane/lib/src/components/form/Input";
import React, {FC, FormEvent, useState} from "react";
import {useTelephoneAuthentication} from "../hooks/useTelephoneAuthentication";

export const TelephoneSidebarLoginForm: FC<{}> = props => {
  const auth = useTelephoneAuthentication()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const login = (e: FormEvent) => {
    e.preventDefault()
    if (auth.status !== 'login_required') {
      return
    }
    setIsSubmitting(true)
    auth.saveCredentials(username, password).finally(() => setIsSubmitting(false))
  }
  return <form className={"flex flex-col items-stretch space-y-3"} onSubmit={login}>
    <p className={"text-sm mb-0 text-brand-on-background"}>Log in met je ReceptiePlus gegevens om gebruik te kunnen maken van de Telefooncentrale</p>
    <Input label={"Gebruikersnaam"} type={"text"} value={username} placeholder={'ext000@miep.nu'} onChange={setUsername} />
    <Input label={"Wachtwoord"} type={"password"} value={password} placeholder={'**********'} onChange={setPassword} />
    {auth.status === 'login_required' && auth.errors.map((error, i) => {
      return <div key={i} className={"text-brand-error my-2 font-medium"}>{error}</div>
    })}
    <p className={"text-sm mb-0 text-brand-on-background"}>Wachtwoord niet bij de hand? Neem contact op met <strong>Applicatiebeheer</strong>.</p>
    <Button type={'primary'} size={'sm'} text={'Inloggen'} loading={isSubmitting} submit={true} />
  </form>
}