import { ContentContainer } from "@jane/lib/src/components/content/ContentContainer"
import {Button} from "@jane/lib/src/components/form/Button"
import {
  faChevronLeft, faChevronRight,
  faChevronDown, faChevronUp,
  faCircleInfo, faCircleNotch,
  faEdit,
  faPencil,
  faPlus, faSave,
  faTimes,
  faTrash, faCheckCircle, faCheck, faMinus, faTimesCircle, faSearch
} from "@fortawesome/free-solid-svg-icons";
import { Team, useApiCall, useApiEndpoint} from "@jane/lib/src/api";
import {config} from "../../config";
import { Loading } from "@jane/lib/src/components/Loading";
import {LearnAssignment, LearnComponent, LearnModule} from "@jane/lib/src/api/repositories/learn-repository";
import React, {FC, useEffect, FormEvent, useMemo, useState, Dispatch, SetStateAction, MouseEventHandler} from "react";
import {useModal} from "../../components/ModalProvider";
import {EditLearnModuleModal} from "../../modals/EditLearnModuleModal";
import {useNavigate, useParams} from "react-router-dom";
import { SectionHeader } from "@jane/lib/src/components/content/SectionHeader";
import { SectionDivider } from "@jane/lib/src/components/content/SectionDivider";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AddLearnComponentModal} from "../../modals/AddLearnComponentModal";
import { Breadcrumbs } from "@jane/lib/src/components/content/Breadcrumbs";
import {EditLearnComponentModal} from "../../modals/EditLearnComponentModal";
import {ConfirmationModal} from "../../modals/ConfirmationModal";
import {AssignLearnModuleToTeamModal} from "../../modals/AssignLearnModuleToTeamModal";
import {useTeamData} from "../../resources/TeamContext";
import {usePermissions} from "../../resources/PermissionsHook";
import {useTeamMap} from "../../components/team-select";
import {JaneEmployee, useEmployeesData} from "../../resources/EmployeeContext";
import {Input} from "@jane/lib/src/components/form/Input";
import {AssignLearnModuleToEmployeeModal} from "../../modals/AssignLearnModuleToEmployeeModal";
export const LearnModuleDetailPage: React.FC = () => {
  const {learn} = useApiCall(config)
  const {moduleId} = useParams()
  const {resource, reload} = useApiEndpoint(() => learn.readModule(moduleId!), moduleId !== undefined)
  const editModal = useModal({title: "Onderwerp bewerken", body: <EditLearnModuleModal module={resource!} onFinished={() => reload()} />})

  const parseDescription = (description: string | undefined) => {
    if (!description) return description;
    const urlRegex = /https?:\/\/[^\s]+/g;
    const matches = description.match(urlRegex) || [];
    return description.split(urlRegex).reduce((url, part, index) => {
      url.push(part);
      if (index < matches.length) {
        url.push(
          <a key={index} href={matches[index]} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline hover:cursor-pointer">
            {matches[index]}
          </a>
        );
      }
      return url;
    }, [] as (string | JSX.Element)[]);
  };

  return <ContentContainer>
    <Loading loading={resource === undefined}>
      <Breadcrumbs currentPage={resource?.name??''} crumbs={[
        {label: 'Kennisbank', href: '/learn'}
      ]} />
      <div className={'flex items-center'}>
        <h1 className={"h-12 flex items-center text-2xl leading-tight font-bold"}>Onderwerp: {resource?.name}</h1>
        {resource?.mandatory && <span
          className={'ml-4 py-1 px-2 text-xs font-medium rounded border border-brand-on-primary-container'}>Verplicht</span>}
        <div className={"flex-1 flex justify-end"}>
          <Button type={'secondary'} size={'sm'} text={'Onderwerp bewerken'} icon={faPencil} onClick={() => editModal.open()}/>
        </div>
      </div>
      <div className={"flex justify-between"}>
        <div className={"max-w-2xl"}>
          <pre className={"whitespace-pre-wrap font-sans text-sm"}>{parseDescription(resource?.description)}</pre>
        </div>
      </div>
      <SectionDivider />
      <section>
        {resource && <ComponentEditor moduleId={moduleId!} module={resource} components={resource?.components ?? []}
                          reload={reload}/>}
        {/*{resource?.components?.map(component => <ComponentCard moduleId={moduleId!} component={component} />)}*/}
      </section>
      <SectionDivider />
      <section>
        <TeamsAndProgress moduleId={moduleId!} assignments={resource?.assignments ?? []} reload={reload} />
      </section>
    </Loading>
  </ContentContainer>
}

const ComponentEditor: FC<{moduleId: string, module: LearnModule, components: LearnComponent[], reload: () => void}> = props => {
  const addModal = useModal({title: "Nieuw onderdeel", body: <AddLearnComponentModal moduleId={props.moduleId!} module={props.module} onFinished={() => props.reload()} />})
  const [mode, setMode] = useState<'view' | 'edit'>('view')
  const sortComponentsByOrder = (a: LearnComponent, b: LearnComponent) => a.order > b.order ? 1 : -1
  const [editingComponents, setEditingComponents] = useState(props.components?.sort(sortComponentsByOrder))
  const {learn} = useApiCall(config)
  const startEdit = () => {
    setEditingComponents([...(props.components || [])].sort(sortComponentsByOrder))
    setMode('edit');
  }
  const cancelEdit = () => {
    setEditingComponents([...(props.components || [])].sort(sortComponentsByOrder));
    setMode('view');
  }
  const saveEdit = async () => {
    const newlyOrderedComponents = editingComponents.map((component, i) => ({...component, order: i+1}))
    await learn.editComponentsOrder(props.moduleId, newlyOrderedComponents)
    props.reload()
    setMode('view');
  }
  const switchComponents = (a: LearnComponent, b: LearnComponent) => {
    setEditingComponents(old => {
      return old.map((component) => {
        if (component.id === a.id) return {...b}
        if (component.id === b.id) return {...a}
        return {...component}
      })
    })
  }

  useEffect(() => {
    setEditingComponents(props.components?.sort(sortComponentsByOrder))
  }, [props.components]);

  function filterComponentsByChapter(components: LearnComponent[], chapter: string) {
    return components.filter(x => x.chapter === chapter)
  }
  function filterComponentsWithoutChapter(components: LearnComponent[]) {
    const knownChapters = props.module.chapters
    return components.filter(x => !x.chapter || !knownChapters.includes(x.chapter))
  }

  return <section>
    <div className={"flex items-center justify-between mb-4"}>
      <div className={"flex items-center space-x-3"}>
        <div className={"-mb-4"}>
          <SectionHeader>Onderdelen</SectionHeader>
        </div>
        {mode === 'view' ? <>
          <Button type={'secondary'} size={'sm'} text={'Bewerken'} icon={faEdit} onClick={startEdit}/>
        </> : <>
          <Button type={'primary'} size={'sm'} text={'Opslaan'} icon={faSave} onClick={saveEdit}/>
          <Button type={'secondary'} size={'sm'} text={'Sluiten'} icon={faTimes} onClick={cancelEdit}/>
        </>}
      </div>

      <Button type={'primary'} size={'sm'} text={'Nieuw onderdeel'} icon={faPlus} onClick={() => addModal.open()}/>
    </div>

    {mode === 'edit' && <ChapterEditor module={props.module} reload={props.reload} /> }

    <div>
      {(props.module.chapters ?? []).map((chapter, i) => {
        const cards = filterComponentsByChapter(props.components, chapter)
        const editingCards = filterComponentsByChapter(editingComponents, chapter)
        return <div key={i} className={""}>
          <h3 className={'text-lg font-bold mt-8 mb-4'}>{i + 1}. {chapter}</h3>
          <div className={"grid grid-cols-4 gap-8"}>
            {(mode === 'view' ? cards.sort(sortComponentsByOrder) : editingCards).map((component, i) => ({
              ...component,
              order: i + 1
            })).map((component, i, list) => {
              const isFirst = i === 0
              const isLast = i === list.length - 1
              return <ComponentPreview
                reload={props.reload}
                module={props.module}
                moduleId={props.moduleId}
                component={component}
                key={component.id}
                onMoveUp={!isFirst && mode === 'edit' ? () => switchComponents(component, list[i - 1]) : undefined}
                onMoveDown={!isLast && mode === 'edit' ? () => switchComponents(component, list[i + 1]) : undefined}
                canEdit={mode === 'edit'}
                canDelete={mode === 'edit'}
              />;
            })}
          </div>
        </div>;
      })}
      {(props.components.filter(x => !x.chapter).sort(sortComponentsByOrder).length + editingComponents.filter(x => !x.chapter).length) > 0 && <>
        <h3 className={'text-lg font-bold mt-8 mb-4'}>Geen hoofdstuk toegewezen</h3>
      </>}
      <div className={"grid grid-cols-4 gap-8"}>
        {(mode === 'view' ? filterComponentsWithoutChapter(props.components).sort(sortComponentsByOrder) : filterComponentsWithoutChapter(editingComponents)).map((component, i) => ({
          ...component,
          order: i + 1
        })).map((component, i, list) => {
          const isFirst = i === 0
          const isLast = i === list.length - 1
          return <ComponentPreview
            reload={props.reload}
            module={props.module}
            moduleId={props.moduleId}
            component={component}
            key={component.id}
            onMoveUp={!isFirst && mode === 'edit' ? () => switchComponents(component, list[i - 1]) : undefined}
            onMoveDown={!isLast && mode === 'edit' ? () => switchComponents(component, list[i + 1]) : undefined}
            canEdit={mode === 'edit'}
            canDelete={mode === 'edit'}
          />;
        })}
      </div>
    </div>
  </section>
}

const ComponentPreview: FC<{
  moduleId: string,
  module: LearnModule,
  component: LearnComponent,
  canEdit: boolean,
  canDelete: boolean,
  onMoveUp?: () => void,
  onMoveDown?: () => void,
  reload: () => void
}> = props => {
  const navigate = useNavigate()
  const {learn} = useApiCall(config)
  const editModal = useModal({title: "Onderdeel bewerken", body: <EditLearnComponentModal moduleId={props.moduleId} module={props.module} component={props.component} onFinished={() => props.reload()} />})
  const deleteModal = useModal({title: "Onderdeel verwijderen", body: <ConfirmationModal text={'Weet je zeker dat je dit onderdeel wilt verwijderen?'} onConfirmed={async () => {
      await learn.deleteComponent(props.moduleId, props.component.id)
      props.reload()
    }} />})
  return <div
      className={`bg-brand-primary-container text-brand-on-primary-container border-2 ${(props.onMoveUp || props.onMoveDown) ? 'border-brand-primary' : 'border-brand-primary-container'} px-4 py-4 rounded-xl flex flex-col items-stretch relative`}>
    <div className={"flex items-center"}>
      <FontAwesomeIcon icon={faCircleInfo}/>
      <h3 className={'ml-3 font-medium leading-tight'}>{props.component.name}</h3>
    </div>
    <div className={"flex flex-col space-y-2 mb-4 mt-2 items-stretch flex-1 justify-end"}>
      <div className={"flex items-center justify-between"}>
        <span className={"font-light"}>Kaarten</span>
        <span className={"font-bold"}>{props.component.cards?.length ?? 0}</span>
      </div>
      <div className={"flex items-center justify-between ml-3"}>
        <span className={"font-light"}>Tekst</span>
        <span className={"font-bold"}>{props.component.cards?.filter(x => x.type === "text").length ?? 0}</span>
      </div>
      <div className={"flex items-center justify-between ml-3"}>
        <span className={"font-light"}>Beeld</span>
        <span
          className={"font-bold"}>{props.component.cards?.filter(x => x.type === "media").length ?? 0}</span>
      </div>
      <div className={"flex items-center justify-between ml-3"}>
        <span className={"font-light"}>Vragen</span>
        <span
          className={"font-bold"}>{props.component.cards?.filter(x => x.type === "question").length ?? 0}</span>
      </div>
    </div>
    <div className={"flex flex-col items-stretch space-y-2"}>
      {props.canEdit ? <Button type={'primary'} size={'xs'} text={'Bewerken'} icon={faPencil} onClick={editModal.open} /> : <></>}
      {props.canDelete ? <Button type={'primary'} size={'xs'} text={'Verwijderen'} icon={faTrash} onClick={deleteModal.open} /> : <></>}
      {props.canEdit || props.canDelete ? <></> : <Button type={'primary'} size={'xs'} text={'Kaarten bekijken'} onClick={() => navigate(`/learn/${props.moduleId}/${props.component.id}`)}/>}
    </div>
    {props.onMoveUp ? <button className={"h-6 w-6 absolute top-1/2 -translate-y-1/2 -left-3 bg-brand-primary text-brand-on-primary hover:shadow-lg rounded-full flex items-center justify-center"} onClick={props.onMoveUp}><FontAwesomeIcon icon={faChevronLeft} /></button> : <></>}
    {props.onMoveDown ? <button className={"h-6 w-6 absolute top-1/2 -translate-y-1/2 -right-3 bg-brand-primary text-brand-on-primary hover:shadow-lg rounded-full flex items-center justify-center"} onClick={props.onMoveDown}><FontAwesomeIcon icon={faChevronRight} /></button> : <></>}
  </div>
}

const ChapterEditor: FC<{module: LearnModule, reload: () => void}> = props => {
  const {learn} = useApiCall(config)
  const [chapters, setChapters] = useState(props.module.chapters)
  const [newChapter, setNewChapter] = useState('')
  const [saving, setSaving] = useState(false)
  useEffect(() => {
    setChapters(props.module.chapters)
  }, [props.module.chapters]);
  const addChapter = async (e: FormEvent) => {
    e.preventDefault()
    if (newChapter.trim() === '') return
    let newChaptersValue = Array.from(new Set([...chapters, newChapter]))
    setChapters(newChaptersValue)
    setNewChapter('')
    await saveChapters(newChaptersValue)
  }
  const moveChapterUp = (index: number) => {
    const a = chapters[index]
    const b = chapters[index-1]
    if (!a || !b) return

    let newChaptersValue = [...chapters]
    newChaptersValue[index] = b
    newChaptersValue[index-1] = a
    setChapters(newChaptersValue)
    saveChapters(newChaptersValue)
  }
  const moveChapterDown = (index: number) => {
    const a = chapters[index]
    const b = chapters[index+1]
    if (!a || !b) return

    let newChaptersValue = [...chapters]
    newChaptersValue[index] = b
    newChaptersValue[index+1] = a
    setChapters(newChaptersValue)
    saveChapters(newChaptersValue)
  }
  const removeChapter = (chapter: string) => {
    let newChaptersValue = chapters.filter(c => c !== chapter)
    setChapters(newChaptersValue)
    saveChapters(newChaptersValue)
  }
  const saveChapters = async (chapters: string[]) => {
    setSaving(true)
    await learn.editModule(props.module.category_id!,props.module.id, props.module.name, props.module.description, props.module.mandatory, chapters)
    props.reload()
    setSaving(false)
  }
  return <div>
    <div className={"flex items-center"}>
      <SectionHeader>Hoofdstukken</SectionHeader>
      {saving && <div className={'ml-4 text-slate-600 font-medium text-sm mb-4'}><FontAwesomeIcon icon={faCircleNotch} spin={true} className={'mr-2'}/>Opslaan...</div>}
    </div>
    <ol>
      {chapters.map((c, i) => <li key={i} className={"flex items-center space-x-2 h-8"}>
        <div className={'w-6'}>
          {i !== (chapters.length - 1) && <button
            onClick={() => moveChapterDown(i)}
            className={"h-6 w-6 bg-brand-primary text-brand-on-primary hover:shadow-lg rounded-full flex items-center justify-center"}>
            <FontAwesomeIcon icon={faChevronDown}/></button>}
        </div>
        <div className={'w-6'}>
          {i !== 0 && <button
            onClick={() => moveChapterUp(i)}
            className={"h-6 w-6 bg-brand-primary text-brand-on-primary hover:shadow-lg rounded-full flex items-center justify-center"}>
            <FontAwesomeIcon icon={faChevronUp}/></button>}
        </div>
        <span className={'w-6 text-right font-medium text-lg'}>{i + 1}.</span>
        <div className={'font-medium text-lg pr-4'}>{c}</div>
        <button
          onClick={() => removeChapter(c)}
          className={"h-6 w-6 hover:bg-brand-primary text-brand-primary hover:text-brand-on-primary hover:shadow-lg rounded-full flex items-center justify-center"}>
          <FontAwesomeIcon icon={faTimes}/></button>
      </li>)}
    </ol>
    <form onSubmit={addChapter} className={"flex items-end space-x-4 mb-8 mt-4"}>
      <Input type={'text'} label={'Nieuw hoofdstuk'} placeholder={'Nieuw hoofdstuk'} value={newChapter}
             onChange={setNewChapter}/>

      <Button type={'primary'} size={'sm'} text={'Toevoegen'} submit/>
    </form>
  </div>
}

const TeamsAndProgress: FC<{moduleId: string, assignments: LearnAssignment[], reload: () => void}> = (props) => {
  const { teams } = useTeamData()
  const { employees } = useEmployeesData()

  const { teams: teamsApi } = useApiCall(config)
  const { canListTeams } = usePermissions()
  const { resource: teamResource } = useApiEndpoint(() => teamsApi.myTeam(), canListTeams)
  const { resource: secondaryTeams } = useApiEndpoint(() => teamsApi.myTeams(), canListTeams)
  const teamMap = useTeamMap(teams, teamResource ?? null, secondaryTeams ?? [])
  const teamOptionsMap: Map<number, [string, number]> = useMemo(() => {
    return new Map([
      [-1, ['Alle', 0]],
      ...teamMap
        .map<[Team, number]>(teams => {
          const team = teams.slice(-1)[0]
          return [team, teams.length]
        })
        .map<[number, [string, number]]>(([team, indentation]) => [team.id, [team.name, indentation]])
    ])
  }, [teamMap])
  const assignToTeamModal = useModal({title: "Uitrollen bij team", body: <AssignLearnModuleToTeamModal moduleId={props.moduleId} onFinished={() => {
    props.reload()
  }} teamOptions={teamOptionsMap} />})
  const assignToEmployeeModal = useModal({title: "Uitrollen bij medewerker", body: <AssignLearnModuleToEmployeeModal moduleId={props.moduleId} onFinished={() => {
      props.reload()
  }} employees={employees} />})
  return <div>
    <div className={"flex items-center justify-between mb-4"}>
      <SectionHeader>Teams en voortgang</SectionHeader>
      <div className={'flex items-center space-x-2'}>
        <Button type={'primary'} size={'sm'} text={'Uitrollen bij team'} icon={faPlus} onClick={() => assignToTeamModal.open()}/>
        <Button type={'primary'} size={'sm'} text={'Uitrollen bij medewerker'} icon={faPlus} onClick={() => assignToEmployeeModal.open()}/>
      </div>
    </div>
    <div className={'rounded-lg border border-l-brand-secondary-container bg-white min-h-32'}>
      <LearnProgressExplorer moduleId={props.moduleId} assignments={props.assignments} teams={secondaryTeams??[]} employees={employees} />
    </div>
  </div>
}

interface EmployeeProgress {
  team: Team|null
  employee: JaneEmployee|null
  assignment: LearnAssignment
  progress: {
    cards: number
    viewed: number
    finished: number
  }
}
const LearnProgressExplorer: FC<{moduleId: string, assignments: LearnAssignment[], teams: Team[], employees: JaneEmployee[]}> = (props) => {
  const {learn} = useApiCall(config)
  const [selectedEmployees, setSelectedEmployees] = useState<number[]>([])
  const [search, setSearch] = useState('')
  const employeesMap = useMemo(() => {
    const map: {[id: number]: JaneEmployee} = {}
    props.employees.forEach((e) => {
      map[e.id] = e
    })
    return map
  }, [props.employees])
  const teamsMap = useMemo(() => {
    const map: {[id: number]: Team} = {}
    props.teams.forEach((t) => {
      map[t.id] = t
    })
    return map
  }, [props.teams])
  const employeesProgress = useMemo(() => {
    const employeesProgress: EmployeeProgress[] = []
    props.assignments.forEach(assignment => {
      const progressMap: {[employeeId: string]: {cards: number, viewed: number, finished: number}}  = {}
      assignment.card_progress?.forEach(progress => {
        if (progressMap[progress.employee_id] === undefined) {
          progressMap[progress.employee_id] = {cards: 0, viewed: 0, finished: 0}
        }
        progressMap[progress.employee_id].cards++
        if (progress.viewed_at !== null) progressMap[progress.employee_id].viewed++
        if (progress.finished_at !== null) progressMap[progress.employee_id].finished++
      })
      Object.entries(progressMap).forEach(([employeeId, progress]) => {
        const employee: JaneEmployee|undefined = employeesMap[parseInt(employeeId)]
        employeesProgress.push({
          assignment: assignment,
          employee: employee ?? null,
          team: employee?.team_id ? (teamsMap[employee?.team_id] ?? null) : null,
          progress: progress,
        })
      })
    })
    return employeesProgress.filter((ep) =>
      ep.employee?.name.toLowerCase().includes(search.toLowerCase())
    );
  }, [props.assignments, employeesMap, teamsMap, search]);
  const rootTeam = useMemo(() => {
    return props.teams.find(t => t.parentObjectId === null || t.parentObjectId === 0)
  }, [props.teams])
  const teamIdsWithAssignments = useMemo(() => {
    const teamIdsSet = new Set(employeesProgress.map(ep => ep.employee?.team_id).filter(x => typeof x === "number") as number[])
    function getSubTeamsWithAssignments(teamId: number): number[] {
      const directSubTeams = props.teams.filter(t => t.parentObjectId === teamId).map(t => t.id)
      const subTeams = [...directSubTeams.filter(t => teamIdsSet.has(t)), ...directSubTeams.flatMap(t => getSubTeamsWithAssignments(t))]
      return subTeams.length > 0 ? [teamId, ...subTeams] : []
    }
    return rootTeam ? Array.from(new Set(getSubTeamsWithAssignments(rootTeam.id))) : Array.from(teamIdsSet)
  }, [employeesProgress, props.teams, rootTeam])
  const teamTreeEndNodes = useMemo(() => {
    const parents = teamIdsWithAssignments.map(t => teamsMap[t]?.parentObjectId).filter(x => typeof x === "number") as number[]
    return teamIdsWithAssignments.filter(t => !parents.includes(t))
  }, [teamsMap, teamIdsWithAssignments])

  const [collapsedTeams, setCollapsedTeams] = useState<number[]>(teamTreeEndNodes)

  useEffect(() => {
    setCollapsedTeams(teamTreeEndNodes)
  }, [teamTreeEndNodes]);

  if (props.assignments.length === 0) {
    return <div className={'h-40 flex items-center justify-center'}>Deze module is nog niet uitgerold.</div>
  }
  return <div className={'py-1 px-4'}>

    <label
      className={'-mx-[calc(1rem+1px)] px-4 py-3 flex items-center -mt-[calc(0.25rem+1px)] border border-slate-200 focus-within:border-brand-primary rounded-t-lg'}>
      <FontAwesomeIcon icon={faSearch} className={'mr-3 text-brand-primary'}/>
      <input className={"flex-1 outline-none bg-transparent"} type={'text'} placeholder={"Zoek op specifieke gebruiker"}
             value={search} onKeyDown={e => e.key === 'Escape' ? setSearch('') : null}
             onChange={e => setSearch(e.target.value)}/>
      <FontAwesomeIcon icon={faTimesCircle} className={'p-1 cursor-pointer text-slate-500 hover:text-brand-primary'}
                       onClick={() => setSearch('')}/>
    </label>
    <div className={'-mx-4 px-4 py-3 flex items-center border-b border-slate-200 font-medium text-sm'}>
      <div
        className={`font-medium text-sm ${selectedEmployees.length === 0 ? 'text-slate-400' : ''}`}>{selectedEmployees.length} geselecteerd
      </div>
      <button className={'text-brand-primary hover:bg-brand-primary-container px-2 py-1 -my-1 rounded-full ml-2'}
              onClick={() => {
                const finishedEpIds = employeesProgress.filter(ep => ep.employee !== undefined)
                  .filter(ep => ep.progress.finished === ep.progress.cards)
                  .map(ep => ep.employee!.id)
                setSelectedEmployees(finishedEpIds)
              }}>Afgerond
      </button>
      <button className={'text-brand-primary hover:bg-brand-primary-container px-2 py-1 -my-1 rounded-full ml-2'}
              onClick={() => {
                const notFinishedEpIds = employeesProgress.filter(ep => ep.employee !== undefined)
                  .filter(ep => ep.progress.finished !== ep.progress.cards)
                  .map(ep => ep.employee!.id)
                setSelectedEmployees(notFinishedEpIds)
              }}>Niet-afgerond
      </button>
      <button className={'text-brand-primary hover:bg-brand-primary-container px-2 py-1 -my-1 rounded-full ml-2'}
              onClick={() => {
                const epIds = employeesProgress.filter(ep => ep.employee !== undefined)
                  .map(ep => ep.employee!.id)
                setSelectedEmployees(Array.from(new Set(epIds)))
              }}>Alle
      </button>
      <button className={'text-brand-primary hover:bg-brand-primary-container px-2 py-1 -my-1 rounded-full ml-2'}
              onClick={() => {
                setSelectedEmployees([])
              }}>Geen
      </button>
      <div className={'flex-1'}></div>
      <div
        className={`font-medium text-sm ${selectedEmployees.length === 0 ? 'text-slate-400' : ''}`}>Met geselecteerde:
      </div>
      <button className={'text-brand-primary hover:bg-brand-primary-container px-2 py-1 -my-1 rounded-full ml-2'}
              onClick={async () => {
                const blob = await learn.exportModuleCsv(props.moduleId, selectedEmployees)
                const url = window.URL.createObjectURL(blob)
                const a = document.createElement('a')
                a.href = url
                a.download = `Resultaten.csv`
                a.click()
                window.URL.revokeObjectURL(url)
                document.body.removeChild(a)
              }}>Exporteer resultaten (.csv)
      </button>
    </div>
    {rootTeam && <LearnProgressExplorerTeamRow
      team={rootTeam}
      teams={props.teams}
      collapsedTeams={collapsedTeams}
      setCollapsedTeams={setCollapsedTeams}
      teamIdsWithAssignments={teamIdsWithAssignments}
      employeesProgress={employeesProgress}
      selectedEmployees={selectedEmployees}
      setSelectedEmployees={setSelectedEmployees}
      toggleSelectEmployee={(id) => {
        setSelectedEmployees(old => old.includes(id) ? old.filter(x => x !== id) : [...old, id])
      }}
    />}
  </div>
}
const LearnProgressExplorerTeamRow: FC<{
  team: Team,
  teams: Team[],
  teamIdsWithAssignments: number[],
  employeesProgress: EmployeeProgress[],
  collapsedTeams: number[],
  setCollapsedTeams: Dispatch<SetStateAction<number[]>>,
  selectedEmployees: number[],
  setSelectedEmployees: Dispatch<SetStateAction<number[]>>,
  toggleSelectEmployee: (id: number) => void
}> = props => {
  const localProgress = props.employeesProgress.filter(progress => progress.team?.id === props.team.id)
  const localProgressSummary = useMemo(() => {
    if (localProgress.length === 0) {
      return null
    }
    const totalAssignments = localProgress.length
    const startedAssignments = localProgress.filter(p => p.progress.viewed > 0).length
    const finishedAssignments = localProgress.filter(p => p.progress.finished === p.progress.cards).length
    return {totalAssignments, startedAssignments, finishedAssignments}
  }, [localProgress])
  return <div>
    <div
      className={`h-10 flex items-center justify-between rounded-lg px-3 -mx-3 cursor-pointer hover:bg-brand-surface`} onClick={() => {
        props.setCollapsedTeams(old => old.includes(props.team.id) ? old.filter(x => x !== props.team.id) : [...old, props.team.id])
      }}
    >
      <FontAwesomeIcon icon={props.collapsedTeams.includes(props.team.id) ? faChevronRight : faChevronDown} className={'text-base w-4'}/>
      {localProgressSummary && <>
        <CheckBox state={localProgress.every(ep => props.selectedEmployees.includes(ep.employee?.id ?? -1)) ? 'checked' : (
          localProgress.some(ep => props.selectedEmployees.includes(ep.employee?.id ?? -1)) ? 'partial' : 'unchecked'
        )} onSelectAll={() => {
          props.setSelectedEmployees(old => {
            const localEmployeeIds = localProgress.map(ep => ep.employee?.id ?? -1)
            return Array.from(new Set([...old, ...localEmployeeIds.filter(id => !old.includes(id ?? -1))]))
          })
        }} onSelectNone={() => {
          props.setSelectedEmployees(old => {
            const localEmployeeIds = localProgress.map(ep => ep.employee?.id)
            return Array.from(new Set(old.filter(id => !localEmployeeIds.includes(id))))
          })
        }} />
      </>}
      <h2 className={'flex-1 px-3'}>{props.team.name}{localProgress.length>0?` (${localProgress.length})` : ''}</h2>
      {localProgressSummary && <>
        <div className={'ml-3'}>
          <div className={'text-xs font-medium uppercase tracking-normal w-20 -mb-1'}>Gestart</div>
          <InlineProgress
            percentage={localProgressSummary.startedAssignments / localProgressSummary.totalAssignments * 100}/>
        </div>
        <div className={'ml-3'}>
          <div className={'text-xs font-medium uppercase tracking-normal w-20 -mb-1'}>Afgerond</div>
          <InlineProgress percentage={localProgressSummary.finishedAssignments / localProgressSummary.totalAssignments * 100} />
        </div>
      </>}
    </div>
    {props.collapsedTeams.includes(props.team.id) ? <></> : <>
      <div className={'ml-2 pl-2 border-l border-slate-100'}>
        {props.teams.filter(subTeam => subTeam.parentObjectId === props.team.id && props.teamIdsWithAssignments.includes(subTeam.id)).map((subTeam, i) =>
          <LearnProgressExplorerTeamRow
            key={i}
            team={subTeam}
            teams={props.teams}
            teamIdsWithAssignments={props.teamIdsWithAssignments}
            employeesProgress={props.employeesProgress}
            collapsedTeams={props.collapsedTeams}
            setCollapsedTeams={props.setCollapsedTeams}
            selectedEmployees={props.selectedEmployees}
            setSelectedEmployees={props.setSelectedEmployees}
            toggleSelectEmployee={props.toggleSelectEmployee}
          />)}
      </div>
      <div className={'ml-2 pl-2 border-l border-slate-100'}>
        {localProgress.map((progress, i) => {
          return <div
            key={i}
            className={`h-10 flex items-center justify-between rounded-lg font-medium px-3 -mx-3 hover:bg-brand-surface`}>
            <CheckBox
              state={props.selectedEmployees.includes(progress.employee?.id ?? -1) ? 'checked' : 'unchecked'}
              onSelectAll={() => props.toggleSelectEmployee(progress.employee?.id ?? -1)}
              onSelectNone={() => props.toggleSelectEmployee(progress.employee?.id ?? -1)}
            />
            <h2 className={'flex-1 px-3'}>{progress.employee?.name}</h2>
            <div className={'ml-3'}>
              <div className={'text-xs font-medium uppercase tracking-normal w-20 -mb-1'}>Gestart</div>
              <InlineProgress
                percentage={progress.progress.viewed / progress.progress.cards * 100}/>
            </div>
            <div className={'ml-3'}>
              <div className={'text-xs font-medium uppercase tracking-normal w-20 -mb-1'}>Afgerond</div>
              <InlineProgress
                percentage={progress.progress.finished / progress.progress.cards * 100}/>
            </div>
          </div>
        })}
      </div>
    </>}
  </div>
}
const InlineProgress: FC<{ percentage: number }> = props => {
  if (props.percentage === 100) return <FontAwesomeIcon icon={faCheckCircle} from={'solid'} className={"text-brand-primary"}/>
  return <div className={'inline-block w-full h-2 bg-brand-surface-variant rounded-full overflow-hidden relative'}>
    <div className={'absolute top-0 left-0 h-2 bg-brand-primary rounded-full'}
         style={{width: `${props.percentage / 100 * 40}px`}}></div>
  </div>
}
const CheckBox: FC<{state: 'checked'|'partial'|'unchecked', onSelectNone: () => void, onSelectAll: () => void}> = props => {
    const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
      e.stopPropagation()
      if (props.state === 'checked') {
        props.onSelectNone()
      } else {
        props.onSelectAll()
      }
    }
    return <button onClick={handleClick} className={'ml-2 -mr-2 h-8 w-8 hover:bg-brand-surface-variant rounded-full flex items-center justify-center'}>
      <div className={`h-4 w-4 flex items-center justify-center rounded border-2 ${props.state === 'unchecked' ? 'border-brand-secondary bg-transparent' : 'border-brand-primary bg-brand-primary text-brand-on-primary'}`}>
        {props.state === 'checked' && <FontAwesomeIcon icon={faCheck} className={'w-3'} />}
        {props.state === 'partial' && <FontAwesomeIcon icon={faMinus} className={'w-3'} />}
      </div>
    </button>
}