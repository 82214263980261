import React, {FC, useState} from "react";
import {ModalFooter, useModalControls} from "../components/ModalProvider";
import {Paragraph} from "../../../lib/src/components/Paragraph";
import { Input } from "@jane/lib/src/components/form/Input";

export const ForwardCallModal: FC<{onConfirmed: (target: string) => void}> = (props) => {
  const [target,setTarget] = useState('')
  const {close} = useModalControls()
  return <div className={"flex flex-col space-y-3"}>
    <Paragraph>Je staat op het punt om een gesprek door te sturen. Vul hieronder de extensie van de collega in waarnaar je het gesprek wilt doorsturen</Paragraph>
    <Input type={'text'} label={'Extensie'} value={target} onChange={setTarget} placeholder={'000'} />
    <ModalFooter text={'Doorsturen'} onSubmit={() => {
      props.onConfirmed(target);
      close();
    }} />
  </div>
}