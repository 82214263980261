import React, {FC} from "react";

interface SelectProps {
  label: string
  options: {[key: string]: string}|[string,string][]
  value: string
  onChange: (newValue: string) => void
  inline?: boolean
}

export const Select: FC<SelectProps> = (props) =>  {
  return <label className={`flex ${props.inline ? 'flex-row whitespace-nowrap items-center' : 'flex-col'} text-sm font-medium text-brand-900 w-full max-w-md`}>
    <span className={props.inline ? 'mr-2' : ''}>{props.label}</span>
    <select
      className={"border-2 border-slate-200 outline-brand-700 text-black rounded text-base font-normal px-2 mt-1 h-10 w-full"}
      onChange={(e) => props.onChange(e.target.value)}
      value={props.value}
    >
      {(Array.isArray(props.options) ? props.options : Object.entries(props.options)).map(([key, option]) => {
        return <option value={key} key={key}>{option}</option>
      })}
    </select>
  </label>
}