import {useMemo} from "react";
import {Call} from "../Call";
import {parseBroadsoftMessage} from "../parseBroadsoftMessage";

export const useParsedMessages = (messages: any[]) => {

  const parsedMessages: Call[] = useMemo(() => {
    return messages.map((m) => {
      return parseBroadsoftMessage(m)
    }).filter(m => m !== null) as Call[]
  }, [messages])

  // const parsedMessages = [
  //   {
  //     "id": "b9337286-dd93-4136-9772-12c7a49a53af",
  //     "callId": "callhalf-11067147099:0",
  //     "type": "ring",
  //     "number": {
  //       "countryCode": "31",
  //       "number": "tel:+31655539669",
  //       "name": "test crm  -  0655539669"
  //     },
  //     "startTime": new Date("2024-10-30T09:37:11.550Z"),
  //   },
  //   {
  //     "id": "54025f69-8bcc-42e7-9ecd-ee3e6337fbc6",
  //     "callId": "callhalf-11067147099:0",
  //     "type": "answer",
  //     "number": {
  //       "countryCode": "31",
  //       "number": "tel:+31655539669",
  //       "name": "0655539669"
  //     },
  //     "startTime": new Date("2024-10-30T09:37:11.550Z"),
  //     "answerTime": new Date("2024-10-30T09:37:13.263Z"),
  //   },
  //   // {
  //   //   "id": "b7392e7a-3153-4f3e-bb0d-f3aa983063b1",
  //   //   "callId": "callhalf-11067147099:0",
  //   //   "type": "release",
  //   //   "number": {
  //   //     "countryCode": "31",
  //   //     "number": "tel:+31655539669",
  //   //     "name": "0655539669"
  //   //   },
  //   //   "startTime": new Date("2024-10-30T09:37:11.550Z"),
  //   //   "releaseTime": new Date("2024-10-30T09:37:18.877Z"),
  //   // }
  // ] as Call[];

  // const parsedMessages = [
  //   {
  //     id: '1',
  //     callId: '12',
  //     type: 'ring',
  //     number: {countryCode: "31", name: "0651950267", number: "tel:+31651950267"},
  //     startTime: new Date(),
  //   },
  //   {
  //     id: '1',
  //     callId: '12',
  //     type: 'answer',
  //     number: {countryCode: "31", name: "0651950267", number: "tel:+31651950267"},
  //     startTime: new Date(),
  //   },
  //   {
  //     id: '1',
  //     callId: '13',
  //     type: 'ring',
  //     number: {countryCode: "31", name: "0651950267", number: "tel:+31651950267"},
  //     startTime: new Date(),
  //   },
  //   {
  //     id: '1',
  //     callId: '14',
  //     type: 'ring',
  //     number: {countryCode: "31", name: "0651950267", number: "tel:+31651950267"},
  //     startTime: new Date(),
  //   },
  // ] as Call[];

  const calls = useMemo(() => {
    return parsedMessages.reduce<{[callId: string]: Call}>((carry, previous) => {
      carry[previous.callId] = previous
      return carry
    }, {})
  }, [parsedMessages])

  const isRinging = useMemo(() => {
    return Object.values(calls).some((call) => call.type === 'ring')
  }, [calls])

  const incomingCalls = useMemo(() => {
    return Object.values(calls).filter((call) => call.type === 'ring')
  }, [calls])
  const holdCalls = useMemo(() => {
    return Object.values(calls).filter((call) => call.type === 'hold')
  }, [calls])

  const focussedCall: Call|undefined = useMemo(() => {
    const ordered = Object.values(calls).sort((a,b) => b.startTime > a.startTime ? 1 : -1)
    const activeCall: Call|undefined = ordered.find((call) => call.type === 'answer' || call.type === 'unhold')
    const answeredCalls = parsedMessages.filter((call) => call.type === 'answer' || call.type === 'unhold')
    const lastCall: Call|undefined = ordered
      .filter(c => c.type === 'release')
      .filter(c => answeredCalls.map(c => c.callId).includes(c.callId))
      .filter(c => !holdCalls.map(c => c.callId).includes(c.callId))
      .sort((a, b) => b.startTime > a.startTime ? 1 : -1)[0]
    return activeCall ?? lastCall
  }, [calls])

  return {
    parsedMessages,
    incomingCalls,
    holdCalls,
    calls,
    focussedCall,
    isRinging,
  }
}