import {Employee, useApiCall} from "@jane/lib/src/api";
import React, {FormEvent, useEffect, useState} from "react"
import {JaneEmployee, useEmployeesData} from "../resources/EmployeeContext";
import {config} from "../config";
import { useToasts } from "@jane/lib/src/toasts/toasts";
import { Select } from "@jane/lib/src/components/form/Select";
import { Button } from "@jane/lib/src/components/form/Button";
import { Card } from "@jane/lib/src/components/Card";

export default function RolesForm(props: {employee: JaneEmployee, roles: Employee, reload: () => void}): JSX.Element {
    const currentRole = props.employee.role === "Werknemer" ? "employee" : "coordinator"
      const rolesMap = {
          "employee": "Werknemer",
          "coordinator": "Coordinator",
      }
    const [role, setRole] = useState<"employee"|"coordinator">(currentRole)
    const [additionalRoles, setAdditionalRoles] = useState<string[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')
    const { employees } = useApiCall(config)
    const { showToast } = useToasts()
    console.log(props.roles)

    // @ts-ignore
    const roles = Array.isArray(props.roles?.roles) ? props.roles.roles : [];
    const filteredRoles = ['telephone', 'hoofdcoordinator', 'learn'];
    useEffect(() => {
      setAdditionalRoles(roles.filter((role: string) => filteredRoles.includes(role)));
    }, [roles]);

    function save() {
        setError('')
        setIsLoading(true)
        employees.updateRole(props.employee.sub, role, additionalRoles)
            .then(() => {
                showToast('Wijzigingen opgeslagen', 'Het kan een minuut duren voordat de wijziging is doorgevoerd, herlaad straks de pagina om de wijziging te zien.')
                setRole(role)
                props.reload()
            })
            .catch(err => {
                setError(err)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

  const handleCheckboxChange = (roleName: string, isChecked: boolean) => {
    setAdditionalRoles((prevRoles) => {
      if (isChecked) {
        return [...prevRoles, roleName];
      } else {
        return prevRoles.filter((role) => role !== roleName);
      }
    });
  };

    return <Card title={'Rol aanpassen'} id={"role"}>
      <div className={"space-y-4"}>
        <Select options={rolesMap} value={role} onChange={(e) => setRole(e as "employee" | "coordinator")}
                label={'Role'}/>

        {role === 'coordinator' && <>
            <h1 className={"pt-3 font-bold"}>Extra rollen</h1>
            <label className="flex space-x-2 hover:cursor-pointer">
              <input type="checkbox" onChange={(e) => handleCheckboxChange('hoofdcoordinator', e.target.checked)} checked={additionalRoles.includes('hoofdcoordinator')}/>
              <h1>Hoofdcoordinator</h1>
            </label>
            <label className="flex space-x-2 hover:cursor-pointer">
              <input type="checkbox" onChange={(e) => handleCheckboxChange('learn', e.target.checked)} checked={additionalRoles.includes('learn')}/>
              <h1>Learn</h1>
            </label>
            <label className="flex space-x-2 hover:cursor-pointer">
              <input type="checkbox" onChange={(e) => handleCheckboxChange('telephone', e.target.checked)} checked={additionalRoles.includes('telephone')}/>
              <h1>Telefonie</h1>
            </label>
          </>}

        <Button type={'primary'} size={'sm'} disabled={isLoading} text={'Opslaan'} onClick={save}/>
        {error === "" ? "" : <>
          <div className={"text-danger mt-2"}>{error}</div>
        </>}
      </div>
    </Card>
}
