import { useApiCall } from "@jane/lib/src/api";
import { Checkbox } from "@jane/lib/src/components/form/Checkbox";
import { Input } from "@jane/lib/src/components/form/Input";
import { useRefresh } from "@jane/lib/src/components/RefreshController";
import {FC, useState} from "react";
import {ModalFooter} from "../components/ModalProvider";
import {config} from "../config";

export const AddLearnCategoryModal: FC<{parentId: string|null, onFinished: () => void}> = (props) => {
  const {learn} = useApiCall(config)
  const [name, setName] = useState('')
  const save = async () => {
    await learn.addCategory(name, props.parentId)
    props.onFinished()
  }
  return <div className={"flex flex-col space-y-3"}>
    <Input type={'text'} label={'Naam'} value={name} onChange={setName} />
    <ModalFooter text={'Opslaan'} onSubmit={save} />
  </div>
}