import React, {FC, useMemo, useState} from "react";
import {ModalFooter, useModalControls} from "../components/ModalProvider";
import {Paragraph} from "../../../lib/src/components/Paragraph";
import { Input } from "@jane/lib/src/components/form/Input";
import {JaneEmployee} from "../resources/EmployeeContext";
import {Select} from "../../../lib/src/components/form/Select";
import {formatCallPhoneNumber} from "../telephone/formatCallPhoneNumber";
import {Call} from "../telephone/Call";
import {Client, MatchedItem, Team, useApiCall} from "../../../lib/src/api";
import {config} from "../config";
import {useToasts} from "../../../lib/src/toasts/toasts";
import {BuddyTeam, PhoneDirectoryEntry} from "../../../lib/src/api/repositories/buddy-teams-repository";

export const AddCallbackRequestModal: FC<{cachedMatch: MatchedItem|undefined, clients: Client[], employees: JaneEmployee[], call: Call, teams: Team[], buddyTeams: BuddyTeam[], directory: PhoneDirectoryEntry[]}> = (props) => {
  const coordinators = props.employees.filter(e => e.role === "Coordinator")
  const [message, setMessage] = useState(generateDefaultMessage(props.cachedMatch, props.call, props.clients, props.employees))
  const {close} = useModalControls()
  const {showToast} = useToasts()
  const {telephone} = useApiCall(config)
  const [selectedBuddyTeam,setSelectedBuddyTeam] = useState(props.cachedMatch?.buddy_team_id?.toString() ?? "-")
  const selectOptions = useMemo(() => {
    const enrichedBuddyTeams = props.buddyTeams.map(buddyTeam => {
      const team = props.teams.find(t => t.id === buddyTeam.team_id)
      const directory = props.directory.find(d => d.extension === buddyTeam.target)
      return {
        ...buddyTeam,
        team,
        directory
      }
    }).sort((a, b) => {
      const testA = a.team?.name !== undefined ? a.team.name : Infinity
      const testB = b.team?.name !== undefined ? b.team?.name : Infinity
      if (testA === testB) return 0;
      return testA > testB ? 1 : -1;
    })
    return Object.fromEntries([
      ["-", " -- Selecteer een team -- "],
      ...enrichedBuddyTeams.map(buddyTeam => [buddyTeam.id.toString(), `${buddyTeam.team?.name}`]),
    ])
  }, [props.teams, props.buddyTeams, props.directory])
  const submit = async () => {
    await telephone.addCallbackRequest(selectedBuddyTeam, message).then(() => {
      showToast('Aangemaakt', 'Terugbelverzoek aangemaakt')
    }).catch(() => {
      showToast('Oeps', 'Er is iets misgegaan bij het aanmaken van het terugbelverzoek')
    })
  }
  if (coordinators.length === 0) {
    return <div className={"flex flex-col space-y-3"}>
      <Paragraph>Er zijn geen collega's beschikbaar om een terugbelverzoek aan te maken</Paragraph>
      <ModalFooter text={'Sluiten'} onSubmit={close} />
    </div>
  }
  return <div className={"flex flex-col space-y-3"}>
    <Paragraph>Je staat op het punt om een gesprek door te sturen. Vul hieronder de extensie van de collega in waarnaar je het gesprek wilt doorsturen</Paragraph>
    <Select label={'Coördinator'} options={selectOptions} value={selectedBuddyTeam} onChange={setSelectedBuddyTeam} />
    <Input type={'textarea'} label={'Bericht'} value={message} focus={true} onChange={setMessage} />
    <ModalFooter text={'Terugbelverzoek aanmaken'} isDisabled={selectedBuddyTeam === '-'} onSubmit={async () => {
      await submit()
      close()
    }} />
  </div>
}

function generateDefaultMessage(match: MatchedItem|undefined, call: Call, clients: Client[], employees: JaneEmployee[]) {
  if (match?.type === 'client') {
    const client = clients.find(c => c.id === match.id)
    if (client === undefined) {
      return `Cliënt met nummer (${formatCallPhoneNumber(call)}) heeft gebeld.\n\n`
    }
    return `${client.name} (${formatCallPhoneNumber(call)}) heeft gebeld.\n\n`
  }
  if (match?.type === 'employee') {
    const employee = employees.find(e => e.id === match.id)
    if (employee === undefined) {
      return `Medewerker met nummer (${formatCallPhoneNumber(call)}) heeft gebeld.\n\n`
    }
    return `${employee.name} (${formatCallPhoneNumber(call)}) heeft gebeld.\n\n`
  }
  if (match?.type === 'client_contact') {
    const client = clients.find(c => c.id === match.client_id)
    if (client === undefined) {
      return `Contactpersoon van een cliënt heeft gebeld met nummer: (${formatCallPhoneNumber(call)}).\n\n`
    }
    return `Contactpersoon van cliënt: ${client.name} (#${client.client_number}) heeft gebeld met nummer: (${formatCallPhoneNumber(call)}).\n\n`
  }
  // Fallback
  return `${formatCallPhoneNumber(call)} heeft gebeld.\n\n`
}