import {AbstractRepository} from "../repository";

export type BuddyTeam = {
  id: string,
  team_id: number
  target: string
  enabled: boolean
  email: string|null|undefined
  created_at: string
  updated_at: string
}
export type PhoneDirectoryEntry = {
  email_address: string|null
  extension: string
  first_name: string
  group_id: string|null
  last_name: string
  number: string|null
  user_id: string
}


export default class BuddyTeamsRepository extends AbstractRepository {
  /**
   * List all teams in the system
   */
  public async all(): Promise<{buddyTeams: BuddyTeam[], directory: PhoneDirectoryEntry[]}> {
    let response = await this.request<{ data: {buddy_teams: { [id: number]: BuddyTeam }, directory: { [id: number]: PhoneDirectoryEntry }} }>('GET', 'telephone/buddy_teams');
    return {
      buddyTeams: Array.isArray(response.data.buddy_teams) ? response.data.buddy_teams : Object.values(response.data.buddy_teams),
      directory: Array.isArray(response.data.directory) ? response.data.directory : Object.values(response.data.directory),
    }
  }

  public async addOrUpdate(teamId: number, target: string, enabled: boolean, email?: string|null): Promise<BuddyTeam> {
    let r = await this.request<{ data: BuddyTeam }>('POST', 'telephone/buddy_teams', {
      team_id: teamId,
      target,
      enabled,
      email: email ?? null,
    });
    return r.data;
  }

  public async del(buddyTeamId: string): Promise<void> {
    await this.request<{}>('DELETE', `telephone/buddy_teams/${buddyTeamId}`);
  }
}
