import { useApiCall } from "@jane/lib/src/api";
import {LearnComponent, LearnModule} from "@jane/lib/src/api/repositories/learn-repository";
import { Checkbox } from "@jane/lib/src/components/form/Checkbox";
import { Input } from "@jane/lib/src/components/form/Input";
import { Select } from "@jane/lib/src/components/form/Select";
import { useRefresh } from "@jane/lib/src/components/RefreshController";
import {FC, useState} from "react";
import {ModalFooter} from "../components/ModalProvider";
import {config} from "../config";

export const EditLearnComponentModal: FC<{onFinished: () => void, moduleId: string, module: LearnModule, component: LearnComponent}> = (props) => {
  const {learn} = useApiCall(config)
  const [name, setName] = useState(props.component.name)
  const [chapter, setChapter] = useState(props.component.chapter ?? '-')

  const save = async () => {
    await learn.editComponent(props.moduleId, props.component.id, name, chapter === '-' ? null : chapter)
    props.onFinished()
  }
  return <div className={"flex flex-col space-y-3"}>
    <Input type={'text'} label={'Naam'} value={name} onChange={setName} />
    <Select label={'Hoofdstuk'} options={{'-': 'Geen', ...Object.fromEntries(props.module.chapters.map((chapter,i) => [chapter, `${i+1}. ${chapter}`]))}} value={chapter} onChange={setChapter} />
    <ModalFooter text={'Opslaan'} onSubmit={save} />
  </div>
}