import {AbstractRepository} from "../repository";

export type LearnCategory = {
  id: string,
  parent_category_id: string|null,
  name: string,
  created_at: string
  updated_at: string
}
export type LearnModule = {
  id: string,
  category_id: string|null,
  name: string,
  description: string,
  mandatory: boolean,
  chapters: string[]
  created_at: string
  updated_at: string
  components?: LearnComponent[]
  assignments?: LearnAssignment[]
}
export type LearnComponent = {
  id: string,
  name: string,
  order: number,
  chapter: string|null,
  created_at: string
  updated_at: string
  cards?: LearnCard[]
}
export type LearnCard = {
  id: string,
  type: string,
  order: number,
  title: string,
  description: string,
  media: string|null,
  properties: any,
  created_at: string
  updated_at: string
}
export type LearnAssignment = {
  id: string,
  module_id: string,
  team_id: number|null,
  employee_ons_id: number|null,
  notified_at: string|null,
  created_at: string
  updated_at: string
  card_progress?: LearnCardProgress[]
}
export type LearnCardProgress = {
  id: string
  learn_module_card_uuid: string,
  learn_module_assignment_uuid: string,
  answer: any,
  employee_id: number
  viewed_at: string|null
  finished_at: string|null
  created_at: string
  updated_at: string
}
export type LearnCertificate = {
  id: string
  module_id: string
  employee_ons_id: number
  pdf: string
  created_at: string
  updated_at: string
  learn_module?: LearnModule
}


export default class LearnRepository extends AbstractRepository {
  /**
   * List all teams in the system
   */
  public async listCategories(): Promise<Array<LearnCategory>> {
    let response = await this.request<{ data: { [id: number]: LearnCategory } }>('GET', 'learn/categories');
    return this.unpackDataCollectionResponse(response);
  }

  public async addCategory(name: string, parentCategoryId: string|null): Promise<LearnCategory> {
    let r = await this.request<{ data: LearnCategory }>('POST', 'learn/categories', {
      parent_category_id: parentCategoryId,
      name,
    });
    return r.data;
  }
  public async deleteCategory(categoryId: string): Promise<void> {
    await this.request<{ data: LearnCategory }>('DELETE', `learn/categories/${categoryId}`);
  }

  public async listModules(): Promise<Array<LearnModule>> {
    let response = await this.request<{ data: { [id: number]: LearnModule } }>('GET', 'learn/modules');
    return this.unpackDataCollectionResponse(response);
  }

  public async addModule(name: string, description: string, mandatory: boolean): Promise<LearnModule> {
    let r = await this.request<{ data: LearnModule }>('POST', 'learn/modules', {
      name,
      description,
      mandatory,
    });
    return r.data;
  }
  public async editModule(leanCategoryId: string, moduleId: string, name: string, description: string, mandatory: boolean, chapters: string[]): Promise<LearnModule> {
    let r = await this.request<{ data: LearnModule }>('POST', `learn/modules/${moduleId}`, {
      learn_category_id: leanCategoryId,
      name,
      description,
      mandatory,
      chapters,
    });
    return r.data;
  }
  public async moveModuleToCategory(moduleId: string, categoryId: string|null): Promise<LearnModule> {
    let r = await this.request<{ data: LearnModule }>('POST', `learn/modules/${moduleId}`, {
      learn_category_id: categoryId,
    });
    return r.data;
  }
  public async readModule(moduleId: string): Promise<LearnModule> {
    let r = await this.request<{ data: LearnModule }>('GET', `learn/modules/${moduleId}`);
    return r.data;
  }
  public async addComponent(moduleId: string, name: string, chapter: string|null): Promise<LearnComponent> {
    let r = await this.request<{ data: LearnComponent }>('POST', `learn/modules/${moduleId}/components`, {
      name,
      chapter,
    });
    return r.data;
  }
  public async listComponents(moduleId: string): Promise<Array<LearnComponent>> {
    let response = await this.request<{ data: { [id: number]: LearnComponent } }>('GET', `learn/modules/${moduleId}/components`);
    return this.unpackDataCollectionResponse(response);
  }
  public async readComponent(moduleId: string, componentId: string): Promise<LearnModule> {
    let r = await this.request<{ data: LearnModule }>('GET', `learn/modules/${moduleId}/components/${componentId}`);
    return r.data;
  }
  public async editComponent(moduleId: string, componentId: string, name: string, chapter: string|null): Promise<LearnComponent> {
    let r = await this.request<{ data: LearnComponent }>('POST', `learn/modules/${moduleId}/components/${componentId}`, {
      name,
      chapter,
    });
    return r.data;
  }

  public async editComponentsOrder(moduleId: string, components: LearnComponent[]): Promise<LearnComponent> {
    let r = await this.request<{ data: LearnComponent }>('POST', `learn/modules/${moduleId}/components/reorder`, {
      components: Object.fromEntries(components.map(component => [component.id, component.order])),
    });
    return r.data;
  }

  public async deleteComponent(moduleId: string, componentId: string): Promise<void> {
    await this.request<{}>('DELETE', `learn/modules/${moduleId}/components/${componentId}`);
  }

  public async addTextCard(moduleId: string, componentId: string, title: string, description: string): Promise<LearnCard> {
    let r = await this.request<{ data: LearnCard }>('POST', `learn/modules/${moduleId}/components/${componentId}/cards`, {
      type: 'text',
      title,
      description,
    });
    return r.data;
  }
  public async editTextCard(moduleId: string, componentId: string, cardId: string, title: string, description: string): Promise<LearnCard> {
    let r = await this.request<{ data: LearnCard }>('POST', `learn/modules/${moduleId}/components/${componentId}/cards/${cardId}`, {
      type: 'text',
      title,
      description,
    });
    return r.data;
  }
  public async addQuestionCard(moduleId: string, componentId: string, title: string, description: string, options: string[], hasCorrectOption: boolean, correctOption: number|number[]|null, selectMultiple: boolean,  openFields?: number|number[]|null): Promise<LearnCard> {
    let r = await this.request<{ data: LearnCard }>('POST', `learn/modules/${moduleId}/components/${componentId}/cards`, {
      type: 'question',
      title,
      description,
      properties: {
        options,
        has_correct_option: hasCorrectOption,
        correct_option: correctOption,
        can_select_multiple: selectMultiple,
        open_fields: openFields,
      }
    });
    return r.data;
  }
  public async editQuestionCard(moduleId: string, componentId: string, cardId: string, title: string, description: string, options: string[], hasCorrectOption: boolean, correctOption: number|number[]|null, selectMultiple: boolean, openFields?: number|number[]|null): Promise<LearnCard> {
    let r = await this.request<{ data: LearnCard }>('POST', `learn/modules/${moduleId}/components/${componentId}/cards/${cardId}`, {
      type: 'question',
      title,
      description,
      properties: {
        options,
        has_correct_option: hasCorrectOption,
        correct_option: correctOption,
        can_select_multiple: selectMultiple,
        open_fields: openFields,
      }
    });
    return r.data;
  }
  public async addMediaQuestionCard(moduleId: string, componentId: string, title: string, description: string, options: string[], hasCorrectOption: boolean, correctOption: number|number[]|null, selectMultiple: boolean): Promise<LearnCard> {
    let r = await this.request<{ data: LearnCard }>('POST', `learn/modules/${moduleId}/components/${componentId}/cards`, {
      type: 'media_question',
      title,
      description,
      properties: {
        options,
        has_correct_option: hasCorrectOption,
        correct_option: correctOption,
        can_select_multiple: selectMultiple,
      }
    });
    return r.data;
  }
  public async editMediaQuestionCard(moduleId: string, componentId: string, cardId: string, title: string, description: string, options: string[], hasCorrectOption: boolean, correctOption: number|number[]|null, selectMultiple: boolean): Promise<LearnCard> {
    let r = await this.request<{ data: LearnCard }>('POST', `learn/modules/${moduleId}/components/${componentId}/cards/${cardId}`, {
      type: 'media_question',
      title,
      description,
      properties: {
        options,
        has_correct_option: hasCorrectOption,
        correct_option: correctOption,
        can_select_multiple: selectMultiple,
      }
    });
    return r.data;
  }
  public async addMediaToMediaQuestionCard(moduleId: string, componentId: string, cardId: string, title: string, description: string, file?: File): Promise<LearnCard> {
    const formData = new FormData()
    formData.append('type', 'media_question')
    formData.append('title', title)
    formData.append('description', description)
    if (file) {
      formData.append('media', file)
    }
    let r = await this.formPostRequest<{ data: LearnCard }>(`learn/modules/${moduleId}/components/${componentId}/cards/${cardId}`, formData);
    return r.data;
  }
  public async addMediaCard(moduleId: string, componentId: string, title: string, description: string, file: File, options?: string[], hasCorrectOption?: boolean, correctOption?: number|number[]|null, openFields?: number|number[]|null): Promise<LearnCard> {
    const formData = new FormData()
    formData.append('type', 'media')
    formData.append('title', title)
    formData.append('description', description)
    formData.append('media', file)
    const properties = {
        options,
        has_correct_option: hasCorrectOption,
        correct_option: correctOption,
        open_fields: openFields,
      }
    formData.append('properties', JSON.stringify(properties));
    let r = await this.formPostRequest<{ data: LearnCard }>(`learn/modules/${moduleId}/components/${componentId}/cards`, formData);
    return r.data;
  }
  public async editMediaCard(moduleId: string, componentId: string, cardId: string, title: string, description: string, file?: File): Promise<LearnCard> {
    const formData = new FormData()
    formData.append('type', 'media')
    formData.append('title', title)
    formData.append('description', description)
    if (file) {
      formData.append('media', file)
    }
    let r = await this.formPostRequest<{ data: LearnCard }>(`learn/modules/${moduleId}/components/${componentId}/cards/${cardId}`, formData);
    return r.data;
  }
  public async editMediaCardWithQuestions(moduleId: string, componentId: string, cardId: string, title: string, description: string, options?: string[], hasCorrectOption?: boolean, correctOption?: number|number[]|null, file?: File, openFields?: number|number[]|null): Promise<LearnCard> {
    const formData = new FormData()
    formData.append('type', 'media_question')
    formData.append('title', title)
    formData.append('description', description)
    if (file) {
      formData.append('media', file)
    }
    const properties = {
      options,
      has_correct_option: hasCorrectOption,
      correct_option: correctOption,
      open_fields: openFields,
    }
    formData.append('properties', JSON.stringify(properties));
    let r = await this.formPostRequest<{ data: LearnCard }>(`learn/modules/${moduleId}/components/${componentId}/cards/${cardId}`, formData);
    return r.data;
  }
  public async addOpenCard(moduleId: string, componentId: string, title: string, description: string): Promise<LearnCard> {
    let r = await this.request<{ data: LearnCard }>('POST', `learn/modules/${moduleId}/components/${componentId}/cards`, {
      type: 'open',
      title,
      description,
    });
    return r.data;
  }
  public async editOpenCard(moduleId: string, componentId: string, cardId: string, title: string, description: string): Promise<LearnCard> {
    let r = await this.request<{ data: LearnCard }>('POST', `learn/modules/${moduleId}/components/${componentId}/cards/${cardId}`, {
      type: 'open',
      title,
      description,
    });
    return r.data;
  }
  public async deleteCard(moduleId: string, componentId: string, cardId: string): Promise<void> {
    await this.request<{}>('DELETE', `learn/modules/${moduleId}/components/${componentId}/cards/${cardId}`);
  }

  public async editCardsOrder(moduleId: string, componentId: string, cards: LearnCard[]): Promise<LearnComponent> {
    let r = await this.request<{ data: LearnComponent }>('POST', `learn/modules/${moduleId}/components/${componentId}/cards/reorder`, {
      cards: Object.fromEntries(cards.map(card => [card.id, card.order])),
    });
    return r.data;
  }

  public async assignModuleToTeam(moduleId: string, teamId: number): Promise<number> {
    const r = await this.request<{data: {amount_assigned: number}}>('POST', `learn/modules/${moduleId}/assign`, {
      team: teamId,
    });
    return r.data.amount_assigned
  }
  public async assignModuleToEmployee(moduleId: string, employeeId: number): Promise<number> {
    const r = await this.request<{data: {amount_assigned: number}}>('POST', `learn/modules/${moduleId}/assign`, {
      employee: employeeId,
    });
    return r.data.amount_assigned
  }
  public async listMyCertificates(): Promise<Array<LearnCertificate>> {
    let response = await this.request<{ data: { [id: number]: LearnCertificate } }>('GET', 'learn/my_certificates');
    return this.unpackDataCollectionResponse(response);
  }
  public async listEmployeeCertificates(employeeId: number): Promise<Array<LearnCertificate>> {
    let response = await this.request<{ data: { [id: number]: LearnCertificate } }>('GET', `employee/${employeeId}/learn_certificates`);
    return this.unpackDataCollectionResponse(response);
  }

  public async exportModuleCsv(moduleId: string, employeeIds: number[]): Promise<Blob> {
    const response = await this.rawRequest('POST', `learn/modules/${moduleId}/export`, {
      format: 'csv',
      employee_ids: employeeIds,
    })
    return response.blob()
  }
}
