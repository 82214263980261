import {useCallback, useEffect, useMemo, useState} from "react"

import { Loading } from "@jane/lib/src/components/Loading";
import { PageHeader } from "@jane/lib/src/components/content/PageHeader";
import { useToasts } from "@jane/lib/src/toasts/toasts";
import {SocialCard, Location, useApiCall, useApiEndpoint} from "@jane/lib/src/api";
import useFileUrlFactory from "@jane/lib/src/api/file-url-factory";
import { ObjectAutocomplete } from "@jane/lib/src/components/form/Autocomplete";
import { Button } from "@jane/lib/src/components/form/Button";
import { Input } from "@jane/lib/src/components/form/Input";
import {config} from "../../config";
import { Card } from "@jane/lib/src/components/Card";
import { ContentContainer } from "@jane/lib/src/components/content/ContentContainer";

export default function SettingsSocialCardPage(): JSX.Element {
  const { locations: locationsApi } = useApiCall(config)
  const { resource: locations, isLoading, reload } = useApiEndpoint(() => locationsApi.all())

  return <ContentContainer>
    <PageHeader>Sociale kaarten</PageHeader>

    <p>
      Sociale kaarten zijn gekoppeld aan locaties. Locaties zijn gekoppeld aan cliënten.
      Het kan voorkomen dat de locatie waaraan een cliënt gekoppeld is geen sociale kaart
      bevat. Omdat locaties onder andere locaties kunnen vallen, wordt er in zo&apos;n geval
      gekeken naar bovenliggende locaties.
    </p>

    <AddCardForm locations={locations} onCardAdded={() => reload()} />
    <Loading loading={isLoading}>
      <CardList locations={locations} />
    </Loading>
  </ContentContainer>
}

function AddCardForm(props: { locations?: Location[], onCardAdded: () => void }): JSX.Element {

  const [url, setUrl] = useState<string>("")
  const [selectedLocation, setSelectedLocation] = useState<Location|null>(null)
  const [, setIsSaving] = useState(false)

  const { locations } = useApiCall(config)
  const { showToast } = useToasts()

  const locationNameMap = useMemo(() => {
    return props.locations?.reduce<{[locationId: number]: string}>((map, location) => {
      map[location.id] = location.name
      return map
    }, {}) || {}
  }, [props.locations])

  const resolveLocationName = useCallback((location: Location): string => {
    return location.materializedPath
      .split('.')
      .map((id: string): number => Number(id))
      .map((id: number): string|undefined => locationNameMap[id])
      .filter(name => typeof name === "string")
      .join(' / ')
  }, [locationNameMap])

  const save = async () => {
    if (! selectedLocation || ! url) {
      return
    }
    setIsSaving(true)
    await locations.createSocialCard(selectedLocation, url)
    setIsSaving(false)
    showToast("Kaart toegevoegd", `Sociale kaart voor ${selectedLocation.name} is toegevoegd.`)
    setUrl('')
    setSelectedLocation(null)
    props.onCardAdded()
  }

  console.log(selectedLocation)

  return <Card title={"Sociale kaart toevoegen"}>
    <div className={"flex flex-col space-y-3 items-start"}>
      <Input label={'Sociale kaart URL'} type={'text'} value={url} onChange={setUrl} />
      <ObjectAutocomplete<Location>
        options={props.locations || [] as Location[]}
        value={selectedLocation ?? props.locations?.[0] ?? null}
        onChange={(e) => {console.log({e}); setSelectedLocation(e)}}
        displayItem={obj => resolveLocationName(obj)}
        label={'Locatie'}
        idProperty={'id'}
      />
      <Button type={'primary'} size={'sm'} text={'Toevoegen'} disabled={ ! url || ! selectedLocation } onClick={() => {save()}} />
    </div>
  </Card>
}
interface SocialCardLocation extends SocialCard {
  hasChildrenWithSocialCards: boolean
}

interface TreeNode<T = Location> {
  location: T
  children: TreeNodeAggregate<T>
}
interface TreeNodeAggregate<T = Location> {
  [id: number]: TreeNode<T>
}
interface TreeRoot<T = Location> extends TreeNodeAggregate<T> {}

function CardList(props: { locations?: Location[] }): JSX.Element {
  const { locations } = useApiCall(config)
  const { resource, isLoading, reload } = useApiEndpoint(() => locations.socialCards(), props.locations === undefined || props.locations.length === 0)
  const socialCards = resource ?? []
  useEffect(() => {
    if (resource === undefined && !isLoading) {
      reload()
    }
  }, [resource, isLoading])

  const locationTree: TreeRoot = useMemo(() => {
    const locations = props.locations || []
    let tree: TreeRoot = {}
    /** First, we sort every location on the amount of parent nodes */
    const sorted = locations.sort((a,b) => {
      const na = (a.materializedPath.match(/\./g) || []).length
      const nb = (b.materializedPath.match(/\./g) || []).length
      return na - nb
    })
    /** Next, we go through the paths of the locations, and link nodes. */
    sorted.forEach(location => {
      let reference: {[id: number]: TreeNode} = tree
      let path = location.materializedPath
        .split('.')
        .map(idString => Number(idString))
        .filter(id => id !== location.id)
      while (path.length > 0) {
        const nextId = path.shift() as number
        // eslint-disable-next-line no-prototype-builtins
        if (! reference.hasOwnProperty(nextId)) {
          return
        }
        reference = reference[nextId].children
      }
      reference[location.id] = {
        location: location,
        children: {},
      }
    })

    return tree
  }, [props.locations])

  const socialCardTree: TreeRoot<SocialCardLocation> = useMemo(() => {
    if (socialCards.length === 0 || Object.keys(locationTree).length === 0) {
      return {}
    }
    const mapLocationNode = (node: TreeNode<Location>): TreeNode<SocialCardLocation> => {
      const children: TreeNodeAggregate<SocialCardLocation> = Object.fromEntries(
        Object.entries(node.children)
          .map(([key, value]) =>  [key, mapLocationNode(value)])
      )
      const hasChildWithSocialCard = Object.values(children).reduce((carry, child): boolean => {
        return child.location.socialCardUrl !== "" || child.location.hasChildrenWithSocialCards || carry
      }, false)
      const socialCard = socialCards.find(c => c.id === node.location.id)
      return {
        location: {
          hasChildrenWithSocialCards: hasChildWithSocialCard,
          socialCardUrl: socialCard?.socialCardUrl || "",
          socialCardType: socialCard?.socialCardType || "",
          ...node.location,
        },
        children
      }
    }
    return Object.fromEntries(
      Object.entries(locationTree)
        .map(([key, value]) =>  [key, mapLocationNode(value)])
    )
  }, [locationTree, socialCards])

  return <>
    <h2 className={"mt-5"}>Locaties met sociale kaarten</h2>
    <p className={"mb-5"}>
      Sociale kaart aanpassen? Voeg de sociale kaart nogmaals aan de
      locatie toe om het te overschrijven.
    </p>

    { Object.values(socialCardTree).map(node => <LocationTreeNode node={node} key={node.location.id} />) }
  </>
}

function LocationTreeNode(props: {node: TreeNode<SocialCardLocation>}): JSX.Element {
  const {fileViewerBaseUrl} = config
  const createUrl = useFileUrlFactory(fileViewerBaseUrl)
  const isCardNode = props.node.location.hasChildrenWithSocialCards || props.node.location.socialCardUrl !== ""
  if (! isCardNode) return <></>
  return <div>
    <div className={"font-weight-bold"}>
      { props.node.location.name }
      { props.node.location.socialCardUrl !== "" && props.node.location.socialCardUrl !== null && <> (<a href={createUrl(props.node.location.socialCardType, props.node.location.socialCardUrl)} className={"text-brand-primary underline"} target={"_blank"} rel={"noreferrer"}>Kaart</a>)</> }
    </div>
    <div className={"pl-4"}>
      { Object.values(props.node.children).map(node => <LocationTreeNode node={node} key={node.location.id} />) }
    </div>
  </div>
}