import classNames from "classnames"
import React, { Key, ReactNode, useEffect, useRef } from "react"

export type KeyProperties<T> = {
    [P in keyof T]: T[P] extends Key ? P : never;
}[keyof T]

export type PossibleColumns<T> = { [P in keyof T]: TableColumn<T, P> }[keyof T]

export interface BaseTableProps<T> {
    keyProperty: KeyProperties<T>
    data: T[]
    columns: Array<PossibleColumns<T>>

    /**
     * Additional CSS classes to add to this component.
     */
    className?: string

    /**
     * An ID reference to an element describing the contents of this table.
     * Optional but recommended.
     */
    labelledBy?: string

    /**
     * Show a loading state while fetching content.
     */
    loading?: boolean
    // eslint-disable-next-line no-unused-vars
    headerMapper?: ( props: BaseTableProps<T> ) => JSX.Element[]
    compact?: boolean
}

export interface TableProps<T> extends BaseTableProps<T> {
    /**
     * Whether to add a tab index to the wrapper around this table when
     * horizontal scrolling is needed to see all its content. This should be set
     * to true for tables that don't have tab stops in their content, since
     * otherwise keyboard users will have no way of horizontally scrolling
     * through the content. If this is enabled, ensure a label for the wrapper
     * is also provided by setting the `labelledBy` prop.
     */
    enableScrollTabIndex?: boolean
}

export interface TableColumn<T, P extends keyof T> {
    property: P
    header: string
    wrap?: boolean
    // eslint-disable-next-line no-unused-vars
    transform?: ( property: T[P], row: T ) => ReactNode
}

export default function DataTable<T> ( props: TableProps<T> ): JSX.Element {
    const container = React.createRef<HTMLDivElement>()
    const xScrollable = useRef<boolean>( false )
    useEffect( () => {
        xScrollable.current =
            props.enableScrollTabIndex === true &&
            container.current !== null &&
            container.current.scrollWidth > container.current.offsetWidth
    }, [props, container] )

    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
    return (
        <div
            ref={container}
            tabIndex={xScrollable.current ? 0 : undefined}
            role={xScrollable.current ? "group" : undefined}
            aria-labelledby={props.labelledBy}
            className={`-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 ${
                props.className || ""
            }`}
        >
            {renderTable( props, mapHeaders )}
        </div>
    )
}

export function renderTable<T> (
    props: BaseTableProps<T>,
    // eslint-disable-next-line no-unused-vars
    headerMapper: ( props: BaseTableProps<T> ) => JSX.Element[]
): JSX.Element {
    const isLoading = props.loading || false

    return (
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden border border-gray-200 sm:rounded-lg">
                <table
                    className="min-w-full divide-y divide-gray-200"
                    aria-labelledby={props.labelledBy}
                >
                    <thead>
                    <tr>{(props.headerMapper ?? headerMapper)( props )}</tr>
                    </thead>
                    <tbody>
                    {isLoading ? renderLoadingState( props ) : mapRows( props )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

function mapHeaders<T> ( props: TableProps<T> ): JSX.Element[] {
    return props.columns.map( ( column ) =>
        <th
            key={column.header}
            className={`${props.compact ? 'px-3 py-3' : 'px-4 py-3'} bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-no-wrap`}
        >
            {column.header}
        </th>
    )
}

function mapRows<T> ( props: TableProps<T> ): JSX.Element[] {
    return props.data.map( ( row, index ) => {
        const key = row[props.keyProperty] as unknown as Key
        return (
            <tr key={key} className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}>
                {mapCells( row, props )}
            </tr>
        )
    } )
}

function mapCells<T> ( row: T, props: TableProps<T> ): JSX.Element[] {
    return props.columns.map( ( column, index ) => {
        const spacing = props.compact ? 'px-3 py-1' : 'px-4 py-3'
        const cellClasses = classNames( {
            [`${spacing} text-sm text-left leading-5`]: true,
            "whitespace-no-wrap": column.wrap !== true,
            "font-medium text-gray-900": index === 0,
            "text-gray-500": index !== 0,
        } )
        const cellContent = column.transform
            ? column.transform( row[column.property], row )
            : row[column.property] as JSX.Element
        if ( index === 0 ) {
            return (
                <th key={column.header} className={cellClasses} scope="row">
                    {cellContent}
                </th>
            )
        } else {
            return (
                <td key={column.header} className={cellClasses}>
                    {cellContent}
                </td>
            )
        }
    } )
}

function renderLoadingState<T> ( props: TableProps<T> ): JSX.Element {
    return (
        <tr>
            <td colSpan={props.columns.length} className="py-16 text-center bg-white">
                {/*<LoadingContent className="text-2xl text-brand-500" />*/}
                Loading...
            </td>
        </tr>
    )
}
