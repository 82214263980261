import {
  Client,
  ClientContactDetails, Employee,
  MatchedItem,
  RelationType, SavedMatch,
  SearchResult,
  Team,
  useApiCall
} from "@jane/lib/src/api";
import { Loading } from "@jane/lib/src/components/Loading";
import React, {FC, Fragment, useCallback, useEffect, useMemo, useState} from "react"
import {usePermissions} from "../../resources/PermissionsHook";
import {config} from "../../config";
import { SectionHeader } from "@jane/lib/src/components/content/SectionHeader";
import { Input } from "@jane/lib/src/components/form/Input";
import { Button } from "@jane/lib/src/components/form/Button";
import {useClientData} from "../../resources/ClientContext";
import {useClientContactData} from "../../resources/ClientContactContext";
import {useTeamData} from "../../resources/TeamContext";
import {useDebounce} from "../../util/useDebounce";
import { useToasts } from "@jane/lib/src/toasts/toasts";
import {ModalFooter, useModal, useModalControls} from "../../components/ModalProvider";
import {JaneEmployee, useEmployeesData} from "../../resources/EmployeeContext";
import {RelatedCoordinator, SearchData} from "@jane/lib/src/api/repositories/telephone-repository";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowsRotate,
  faChevronRight,
  faHourglass, faLink,
  faPerson, faPhone, faPhoneVolume,
  faSearch, faShuffle,
  faTimes,
  faUser,
  faUserCircle
} from "@fortawesome/free-solid-svg-icons";
import { PageHeader } from "@jane/lib/src/components/content/PageHeader";
import { Select } from "@jane/lib/src/components/form/Select";
import {useTelephoneAuthentication} from "../../telephone/hooks/useTelephoneAuthentication";
import {useTelephoneSocket} from "../../telephone/hooks/useTelephoneSocket";
import {useParsedMessages} from "../../telephone/hooks/useParsedMessages";
import {useMatches} from "../../telephone/hooks/useMatches";
import {formatCallPhoneNumber} from "../../telephone/formatCallPhoneNumber";
import { MatchMap } from "../../telephone/context/TelephoneMatchesContext";
import {Call} from "../../telephone/Call";

export const TelephonePage: FC = () => {
  const auth = useTelephoneAuthentication()
  const { canAccessPhone } = usePermissions()
  const {telephone} = useApiCall(config)
  const [username, setUsername] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const [credentials, setCredentials] = useState<{ token: string|null, user: string|null }|null>(null)
  const [isLoadingCredentials, setIsLoadingCredentials] = useState(false)
  const [isSavingCredentials, setIsSavingCredentials] = useState(false)
  const [errors, setErrors] = useState<string[]>([])
  useEffect(() => {
    setIsLoadingCredentials(true)
    telephone.getCredentials().then((credentials) => {
      setCredentials(credentials)
      setIsLoadingCredentials(false)
    })
  }, [])
  function saveCredentials() {
    setIsSavingCredentials(true)
    if (auth.status !== "login_required") {
      return
    }
    auth.saveCredentials(username, password).finally(() => setIsSavingCredentials(false))
  }
  console.log(credentials)
  if (! canAccessPhone) {
    return <></>
  }
  return <div className={"max-w-screen-lg mx-auto px-4"}>
    {(credentials !== null && auth.status !== "login_required") ? <TelephoneManager /> : <div>
      <Loading loading={isLoadingCredentials}>
        <div>
          <SectionHeader>Inloggen</SectionHeader>
          {errors.map((error, i) => {
            return <div key={i} className={"alert alert-danger"}>{error}</div>
          })}
          <div className={"max-w-sm"}>
            <Input type={'text'} value={username} onChange={setUsername} label={'Gebruikersnaam'} />
            <Input type={'password'} value={password} onChange={setPassword} label={'Wachtwoord'} />
            <Button type={'primary'} size={'md'} text={'Inloggen'} disabled={isSavingCredentials} onClick={saveCredentials} />
          </div>
        </div>
      </Loading>
    </div>}
  </div>
}

// eslint-disable-next-line no-unused-vars
const TelephoneManager: FC = props => {
  const auth = useTelephoneAuthentication()
  const socket = useTelephoneSocket()
  const {calls, isRinging, focussedCall} = useParsedMessages(socket.status === 'ready' ? socket.messages : [])

  const {matchedMap} = useMatches()

  return <div className={""}>
    <div className={"flex items-center justify-between space-x-2 mb-8"}>
      <div className={"-mb-6"}>
        <PageHeader>Telefonie</PageHeader>
      </div>
      <div className={"flex flex-col lg:flex-row lg:items-center"}>
        <span className={"font-medium"}>Ingelogd als: {(auth.status === 'ready' && auth.credentials.user) ?? '?'}</span>
        <button className={"text-sm underline hover:bg-slate-200 py-2 px-3 rounded-full text-slate-800"} onClick={() => {
          if (auth.status !== "ready") {
            return
          }
          return auth.onLoginFailed();
        }}>
          <FontAwesomeIcon icon={faShuffle} className={"mr-2"} /> Gebruiker wisselen
        </button>
      </div>
      <span
        className={`py-2 px-3 rounded-full flex items-center font-medium text-sm ${socket.status === 'ready' && socket.connected ? 'bg-green-100 text-green-600' : 'bg-red-100 text-red-600'}`}><div
        className={`w-3 h-3 rounded-full mr-2 ${socket.status === 'ready' && socket.connected ? 'bg-green-600' : 'bg-red-600'}`}></div>
        {socket.status === 'ready' && socket.connected ? 'Verbonden' : 'Opnieuw verbinden...'}</span>
    </div>
    {isRinging && <>
      <div className={"flex-1 flex flex-col items-center my-16 space-y-4"}>
        <FontAwesomeIcon icon={faPhone} className={"text-3xl text-slate-500"} bounce={true} />
        <div className={"text-2xl text-slate-500"}>De telefoon gaat</div>
        <p className={"text-slate-500 text-sm text-center max-w-screen-sm"}>
          De telefoon gaat over, zodra je een gesprek opneemt krijg je hier informatie te zien over het gesprek.
        </p>
      </div>
    </>}
    {focussedCall ? <div className={"flex-1 flex flex-col"}>
      {(focussedCall.type === 'answer' || focussedCall.type === 'release' || focussedCall.type === 'redirect') && <>
        {focussedCall.type === 'release' || focussedCall.type === 'redirect' ? <div
          className={"bg-red-50 text-2xl px-4 py-3 rounded-lg space-x-4 text-red-900 font-bold flex items-center"}>
          <FontAwesomeIcon icon={faPhone} className={"rotate-[135deg]"} />
          <div className={"flex-1"}>{focussedCall.type === 'release' ? 'Opgehangen' : 'Doorverbonden'}</div>
          <div className={""}>{formatCallPhoneNumber(focussedCall)}</div>
        </div> : <div
          className={"bg-red-50 text-2xl px-4 py-3 rounded-lg space-x-4 text-red-900 font-bold flex items-center"}>
          <FontAwesomeIcon icon={faPhone} className={""} />
          <div className={"flex-1"}>Inkomend gesprek</div>
          <div className={""}>{formatCallPhoneNumber(focussedCall)}</div>
        </div>}
        <ConversationInformation call={focussedCall} forwardCall={(extension: string) => {
          if (socket.status !== "ready") {
            return
          }
          socket.forwardCall(extension, focussedCall?.callId ?? '');
        }} />
      </>}
    </div> : <div className={"flex-1 flex flex-col items-center my-16 space-y-4"}>
      {!isRinging && <>
        <FontAwesomeIcon icon={faPhone} className={"text-3xl text-slate-500"} />
        <div className={"text-2xl text-slate-500"}>Geen gesprekken</div>
        <p className={"text-slate-500 text-sm text-center max-w-screen-sm"}>
          Op het moment dat je de telefoon opneemt zie je hier informatie over het gesprek. We proberen dan zo veel
          mogelijk informatie bij het telefoonnummer te zoeken.
        </p>
      </>}
      <SearchInformation phoneNumber={null} onMatchAdded={() => {}} matchedMap={matchedMap} />
    </div>}

    <aside className={"mt-32"}>
      <strong className={"mb-2 block text-slate-700"}>Recente gesprekken</strong>
      {Object.entries(calls).length === 0 && <>
        <p className={"text-slate-500 text-sm"}>
          Hier zie je gesprekken die je recent opgenomen hebt.
        </p>
      </>}
      <ul className={"mt-2"}>
        {Object.entries(calls).sort((a, b) => b[1].startTime > a[1].startTime ? 1 : -1).map(([, call], i) => {
          return <li key={i} className={"flex items-center h-8 " + {
            'ring': 'font-bold',
            'answer': 'font-bold',
            'redirect': 'text-slate-500',
            'release': 'text-slate-500',
            'hold': 'text-slate-500',
            'unhold': 'text-slate-500',
          }[call.type]}>
            {/*{callId}*/}
            <div className={"h-4 w-4 flex items-center justify-center"}>
              {call.type === 'ring' && <FontAwesomeIcon icon={faPhoneVolume} bounce={true} />}
              {call.type === 'answer' && <FontAwesomeIcon icon={faPhone} />}
              {call.type === 'redirect' && <FontAwesomeIcon icon={faPhone} />}
              {call.type === 'release' && <FontAwesomeIcon icon={faPhone} className={'rotate-[135deg]'} />}
            </div>
            <div className={"flex-1 text-sm mx-2"}>{formatCallPhoneNumber(call)}</div>
            <div className={"text-xs"}>{call.startTime.toLocaleTimeString('nl', {
              hour: '2-digit',
              minute: '2-digit'
            })}</div>
          </li>
        })}
      </ul>
    </aside>
  </div>
}

function ConversationInformation(props: { call: Call, forwardCall: (extension: string) => void }) {
  const [state, setState] = useState<'initial' | 'searching' | 'searched_found' | 'searched_nothing'>('initial')
  const [results, setResults] = useState<MatchedItem[]>([])
  const phoneNumber = useMemo(() => {
    return formatCallPhoneNumber(props.call)
  }, [props.call])
  const {telephone} = useApiCall(config)
  const {matchedMap, clearCachedMatch} = useMatches()
  const searchNumber = useCallback(() => {
    setState('searching')
    telephone.matchPhone(phoneNumber).then((response) => {
      if (response.length > 0) {
        setResults(response)
        setState('searched_found')
      } else {
        setResults([])
        setState('searched_nothing')
      }
    }).catch(() => {
      setState('searched_nothing')
    })
  }, [phoneNumber])

  useEffect(() => {
    if (state === 'initial') {
      searchNumber()
    }
  }, [state, searchNumber])
  if (state === 'searching' || state === 'initial') {
    return <div className={"flex-1 flex flex-col items-center my-16 space-y-4"}>
      <FontAwesomeIcon icon={faPhone} className={"text-3xl text-slate-500"} />
      <div className={"text-2xl text-slate-500"}>Zoeken...</div>
      <p className={"text-slate-500 text-sm text-center"}>
        We zoeken naar informatie over dit telefoonnummer. Dit kan even duren.
      </p>
    </div>
  }
  if (state === 'searched_nothing') {
    return <>
      <div className={'text-right'}>
        <button className={"text-sm text-red-800 rounded underline hover:bg-red-50"} onClick={() => searchNumber()}><FontAwesomeIcon icon={faArrowsRotate} className={"mr-1"} />Opnieuw zoeken</button>
      </div>
      <SearchInformation phoneNumber={phoneNumber === "Anoniem" ? null : phoneNumber} matchedMap={matchedMap} onMatchAdded={() => {
        clearCachedMatch(props.call)
        searchNumber();
      }} />
    </>
  }
  if (state === 'searched_found') {
    return <>
      <div className={'text-right'}>
        <button className={"text-sm text-red-800 rounded underline hover:bg-red-50"} onClick={() => searchNumber()}><FontAwesomeIcon icon={faArrowsRotate} className={"mr-1"} />Opnieuw zoeken</button>
      </div>
      <FoundInformation results={results} matchedMap={matchedMap} forwardCall={props.forwardCall} toSearch={() => setState('searched_nothing')} />
    </>
  }
  return <div></div>
}

function SearchInformation(props: {phoneNumber: string|null, onMatchAdded: () => void, matchedMap: MatchMap}) {
  const {clients, isLoading: clientsLoading} = useClientData()
  const {clientContacts, isLoading: clientContactsLoading} = useClientContactData()
  const {teams, isLoading: teamsLoading} = useTeamData()

  const onMatchAdded = async () => {
    await new Promise(resolve => setTimeout(resolve, 1000))
    props.onMatchAdded()
  }

  const clientMap = useMemo(() => {
    return clients.reduce((map, client) => {
      map.set(client.id, client)
      return map
    }, new Map<number,Client>())
  }, [clients])

  const clientContactMap = useMemo(() => {
    return clientContacts.reduce((map, contact) => {
      map.set(Number(contact.id), contact)
      return map
    }, new Map<number,ClientContactDetails>())
  }, [clientContacts])

  const teamMap = useMemo(() => {
    return teams.reduce((map, team) => {
      map.set(team.id, team)
      return map
    }, new Map<number,Team>())
  }, [teams])

  const {telephone, network} = useApiCall(config)
  const [search, setSearch] = useState<string>()
  const debouncedSearch = useDebounce(search, 500)
  const [isSearching, setIsSearching] = useState(false)
  const [results, setResults] = useState<SearchResult[]>([])
  useEffect(() => {
    if (debouncedSearch !== undefined) {
      if (debouncedSearch.length > 2) {
        setIsSearching(true)
        telephone.search(debouncedSearch).then((results) => {
          setResults(results)
          setIsSearching(false)
        })
      } else if (debouncedSearch.length === 0) {
        setResults([])
      }
    }
  }, [debouncedSearch])
  const [relationTypes, setRelationTypes] = useState<RelationType[]>([])
  useEffect(() => {
    network.relationTypes().then((types) => {
      setRelationTypes(types)
    })
  }, [])

  const [scope, setScope] = useState<'client' | 'client_contact' | 'employee' | 'all'>('all')
  const scopedResults = useMemo(() => {
    if (scope === 'client') {
      return results.filter(r => r.type === 'client')
    }
    if (scope === 'client_contact') {
      return results.filter(r => r.type === 'client_contact')
    }
    return results
  }, [scope, results])

  const [isSaving, setIsSaving] = useState<boolean>(false)
  const {showToast} = useToasts()
  const saveDirectMatch = async (type: 'client'|'client_contact', id: number) => {
    if (props.phoneNumber === null) {
      return
    }
    setIsSaving(true)
    await telephone.addMatch(props.phoneNumber, id, type)
      .then(async () => {
        await onMatchAdded()
        setIsSaving(false)
      })
      .catch(() => {
        showToast('Error bij opslaan', 'Er is iets mis gegaan, wij hebben hier een melding van gehad en gaan voor je aan de slag.')
        setIsSaving(false)
      })
  }
  if (clientsLoading || clientContactsLoading || teamsLoading) {
    return <div>
      <h2>Laden...</h2>
      <p>We zijn bezig met het laden van gegevens van clienten, contactpersonen en teams...</p>
    </div>
  }
  return <div className={"mt-8"}>
    {props.phoneNumber ? <>
      <h2>Geen informatie gevonden</h2>
      <p>We konden geen informatie bij dit telefoonnummer vinden, zoek hier door alle clienten en hun contactpersonen, en koppel het telefoonnummer hieraan voor de volgende keer.</p>
    </> : <p className={"block min-w-[700px] text-sm text-slate-500 mt-8"}>
      Je kunt ook direct zoeken in de lijst met cliënten.
    </p>}
    <div className={'mt-2 mb-4'}>
      <Input type={'search'} label={'Zoeken'} value={search??''} onChange={setSearch} maxWidth={'full'} />
    </div>
    {results.length === 0 ? <>
      {isSearching ? <div className={"text-slate-500"}>Zoeken...</div> : <div className={"text-slate-500"}>Geen resultaten</div>}
    </> : <>
      <div className={"flex items-center space-x-2"}>
        <button className={`px-3 py-2 rounded-full ${scope === 'all' ? 'bg-red-800 text-white' : 'bg-red-50 text-red-800'}`} onClick={() => setScope('all')}>Alle</button>
        <button className={`px-3 py-2 rounded-full ${scope === 'client' ? 'bg-red-800 text-white' : 'bg-red-50 text-red-800'}`} onClick={() => setScope('client')}>Cliënten</button>
        <button className={`px-3 py-2 rounded-full ${scope === 'client_contact' ? 'bg-red-800 text-white' : 'bg-red-50 text-red-800'}`} onClick={() => setScope('client_contact')}>Contactpersonen</button>
      </div>
      {scopedResults.map((result, i) => {
        if (result.type === 'client') {
          const client = clientMap.get(result.id)
          if (!client) {
            return <Fragment key={i}></Fragment>
          }
          return <div key={i} className={"bg-red-50 rounded-lg mt-6"}>
            <div className={"flex-1"}>
              <ClientInfo client={client} searchData={result.all} matches={props.matchedMap['client']?.[result.id]??[]} />
              {client.team_id && teamMap.has(client.team_id) && <>
                <TeamInfo team={teamMap.get(client.team_id!)!} />
              </>}
            </div>
            {props.phoneNumber && <div className={"mx-3 py-3 flex space-x-3"}>
              <button className={"px-3 py-2 rounded-full bg-red-700 disabled:bg-slate-500 text-white"} disabled={isSaving} onClick={() => saveDirectMatch('client', result.id)}>
                <FontAwesomeIcon icon={faLink} className={"mr-2"} />Koppel direct als cliënt
              </button>
              <AddContactButton relationTypes={relationTypes} clientId={result.id} phoneNumber={props.phoneNumber} onAdded={() => onMatchAdded()} />
            </div>}
          </div>
        }
        if (result.type === 'client_contact' && result.client_id !== null && clientMap.has(result.client_id) && clientContactMap.has(result.id)) {
          const client = clientMap.get(result.client_id)!
          return <div key={i} className={"bg-red-50 rounded-lg mt-6"}>
            <div className={"flex-1"}>
              <ClientContactInfo contact={clientContactMap.get(result.id)!} matches={props.matchedMap['client_contact']?.[result.id]??[]} />
              <div className={"border-2 border-red-200 rounded-lg mx-3 my-3"}>
                <ClientInfo client={client} searchData={result.all} matches={props.matchedMap['client']?.[result.client_id]??[]} />
                {client.team_id && teamMap.has(client.team_id!) && <>
                  <TeamInfo team={teamMap.get(client.team_id!)!}/>
                </>}
                {props.phoneNumber && <div className={"mx-3 py-3 flex space-x-3"}>
                  <button className={"px-3 py-2 rounded-full bg-red-700 disabled:bg-slate-500 text-white"} disabled={isSaving} onClick={() => saveDirectMatch('client', result.client_id!)}>
                    <FontAwesomeIcon icon={faLink} className={"mr-2"} />Koppel direct als cliënt
                  </button>
                  <AddContactButton relationTypes={relationTypes} clientId={result.client_id!} phoneNumber={props.phoneNumber} onAdded={() => onMatchAdded()} />
                </div>}
              </div>
            </div>
            {props.phoneNumber && <div className={"mx-3 py-3 flex space-x-3"}>
              <button className={"px-3 py-2 rounded-full bg-red-700 disabled:bg-slate-500 text-white"} disabled={isSaving} onClick={() => saveDirectMatch('client_contact', result.id)}>
                <FontAwesomeIcon icon={faLink} className={"mr-2"} />Koppel direct als {clientContactMap.get(result.id)!.given_name}
              </button>
            </div>}
          </div>
        }
        return <></>
      })}
    </>}
  </div>
}

function AddContactButton(props: {relationTypes: RelationType[], clientId: number, phoneNumber: string, onAdded: () => void}) {
  const {add} = useClientContactData()
  const onClientContactCreated = (contact: ClientContactDetails) => {
    add(contact)
    props.onAdded()
  }
  const addClientContactModal = useModal({title: "Contactpersoon toevoegen", body: <ClientContactModal relationTypes={props.relationTypes} clientId={props.clientId} phoneNumber={props.phoneNumber} onComplete={onClientContactCreated} />})
  return <button
    className={"px-3 py-2 rounded-full border-2 border-red-700 text-red-700 disabled:border-slate-500 disabled:text-slate-500"}
    onClick={() => addClientContactModal.open()}>
    <FontAwesomeIcon icon={faLink} className={"mr-2"} />Koppel als contactpersoon
  </button>
}
// eslint-disable-next-line no-unused-vars
function ClientContactModal(props: {relationTypes: RelationType[], clientId: number, phoneNumber: string, onComplete: (contact: ClientContactDetails) => void}) {
  const [role, setRole] = useState(props.relationTypes[0].id)
  const [gender, setGender] = useState('M')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [saving, setSaving] = useState(false)
  const [error, setError] = useState('')
  const {network} = useApiCall(config)
  const save = async () => {
    setSaving(true)
    await network.addClientContactForClient(role, firstName, lastName, gender, props.clientId, props.phoneNumber)
      .then(clientContact => props.onComplete(clientContact))
      .catch((e) => {
        setError(e.message ?? e.error ?? 'Er is iets mis gegaan, wij hebben hier een melding van gehad en gaan er mee aan de slag.')
      })
  }
  return <div className={"flex flex-col space-y-3"}>
    <Select label={"Type"} value={String(role)} onChange={(v) => setRole(Number(v))} options={Object.fromEntries(props.relationTypes.sort((a,b) => a.name.localeCompare((b.name))).map(r => [String(r.id), r.name]))} />
    <Input type={'text'} label={'Voornaam'} value={firstName} onChange={setFirstName} />
    <Input type={'text'} label={'Achternaam'} value={lastName} onChange={setLastName} />
    <Select label={"Geslacht"} value={gender} onChange={setGender} options={{'M': 'Man', 'F': 'Vrouw'}} />
    {error !== "" && <div>
      <p className={"alert alert-danger"}>{error}</p>
    </div>}
    {saving && <div>
      <p>
        <FontAwesomeIcon icon={faHourglass} className={"mr-2"} />
        <span>Bezig met opslaan, dit contactpersoon wordt ook direct in ONS aangemaakt. Dit kan even duren...</span>
      </p>
    </div>}
    <ModalFooter text={'Contactpersoon toevoegen'} onSubmit={save} />
  </div>
}

function FoundInformation(props: { results: MatchedItem[], toSearch: () => void, matchedMap: MatchMap, forwardCall: (extension: string) => void }) {
  const {clients, isLoading: clientsLoading} = useClientData()
  const {clientContacts, isLoading: clientContactsLoading} = useClientContactData()
  const {teams, isLoading: teamsLoading} = useTeamData()
  const [isForwarding, setIsForwarding] = useState<string|null>(null)
  const {employees} = useEmployeesData()

  const clientMap = useMemo(() => {
    return clients.reduce((map, client) => {
      map.set(client.id, client)
      return map
    }, new Map<number, Client>())
  }, [clients])

  const clientContactMap = useMemo(() => {
    return clientContacts.reduce((map, contact) => {
      map.set(Number(contact.id), contact)
      return map
    }, new Map<number,ClientContactDetails>())
  }, [clientContacts])

  const teamMap = useMemo(() => {
    return teams.reduce((map, team) => {
      map.set(team.id, team)
      return map
    }, new Map<number,Team>())
  }, [teams])

  const employeeMap = useMemo(() => {
    return employees.reduce((map, employee) => {
      map.set(employee.id, employee)
      return map
    }, new Map<number,JaneEmployee>())
  }, [employees])

  const [details, setDetails] = useState<{client?: Client, employee?: Employee, contact?: ClientContactDetails, team?: Team, coordinators?: RelatedCoordinator[]}|null>(null)

  useEffect(() => {
    setDetails(null)
  }, [props.results])
  if (clientsLoading || clientContactsLoading || teamsLoading) {
    return <div>
      <h2>Laden...</h2>
      <p>We zijn bezig met het laden van gegevens van clienten, contactpersonen en teams...</p>
    </div>
  }
  return <div className={"mt-8"}>
    {details !== null ? <div>
      <button className={"mb-4 text-red-800 underline"} onClick={() => setDetails(null)}>Terug naar overzicht</button>
      {details.contact && <>
        <strong className={"block mb-1"}>Contactpersoon</strong>
        <ClientContactInfo contact={details.contact} matches={props.matchedMap['client_contact']?.[details.contact.id]??[]} />
      </>}
      {details.client && <>
        <strong className={"block mt-4 mb-1"}>{details.contact ? 'Belt voor cliënt' : 'Cliënt'}</strong>
        <ClientInfo client={details.client} matches={props.matchedMap['client']?.[details.client.id]??[]} />
        {details.team && <>
          <strong className={"block mt-4 mb-1"}>Team</strong>
          <TeamInfo team={details.team}/>
        </>}
      </>}
      {details.employee && <>
        <strong className={"block mt-4 mb-1"}>Medewerker</strong>
        <EmployeeInfo employee={details.employee} matches={props.matchedMap['employee']?.[details.employee.id]??[]} />
        {details.team && <>
          <strong className={"block mt-4 mb-1"}>Team</strong>
          <TeamInfo team={details.team}/>
        </>}
      </>}
      {details.coordinators && details.coordinators.length > 0 && <>
        <strong className={"block mt-4 mb-1"}>Coördinators</strong>
        {
          details.coordinators
            .sort((a) => a.primary ? -1 : 1)
            .map((coordinator) => <CoordinatorForwardInfo coordinator={coordinator} employeeMap={employeeMap} onForward={isForwarding !== null && isForwarding !== coordinator.extension ? undefined : async () => {
              setIsForwarding(coordinator.extension)
              props.forwardCall(coordinator.extension)
              await new Promise(resolve => setTimeout(resolve, 5000))
              setIsForwarding(null)
            }}/>)
        }
      </>}
    </div>: <div className={"flex flex-col space-y-4 items-stretch"}>
      <button className={'flex items-center px-3 py-2 space-x-3 border-2 border-red-50 rounded-lg hover:bg-red-50 text-red-900'} onClick={() => props.toSearch()}>
        <div className={"h-10 w-10 rounded-full flex items-center justify-center mr-2"}>
          <FontAwesomeIcon icon={faSearch} className={"text-2xl"} />
        </div>

        <div className={"flex-1 flex flex-col items-start"}>
          <div className={"text-sm -mb-1 text-red-800"}>Toch iemand anders aan de lijn?</div>
          <div className={'text-lg font-medium'}>Zoek en koppel</div>
        </div>
        <div>
          <small>Naar zoeken</small>
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
      </button>
      {props.results.map((result, i) => {
        if (result.type === 'employee') {
          const employee = employeeMap.get(result.id)
          if (!employee) {
            return <React.Fragment key={i}></React.Fragment>
          }
          const onClick = () => setDetails({
            employee,
            team: employee?.team_id ? teamMap.get(employee.team_id) : undefined,
            coordinators: result.coordinators,
          })
          return <button
            key={i}
            className={'flex items-center px-3 py-2 space-x-3 rounded-lg bg-red-50 hover:bg-red-100 text-red-900'}
            onClick={() => onClick()}>
            <div className={"h-10 w-10 rounded-full flex items-center justify-center mr-2"}>
              <FontAwesomeIcon icon={faUserCircle} className={"text-2xl"} />
            </div>
            <div className={"flex-1 flex flex-col items-start"}>
              <div className={"text-sm -mb-1 text-red-800"}>Medewerker #{employee.employee_number}</div>
              <div className={'text-lg font-medium'}>{employee.name}</div>
            </div>
            <div>
              <small>Details</small>
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
          </button>
        }
        const client = clientMap.get(result.type === 'client' ? result.id : result.client_id)
        if (!client) {
          return <React.Fragment key={i}></React.Fragment>
        }
        const contact = result.type === 'client_contact' ? clientContactMap.get(result.id) : undefined
        const onClick = () => setDetails({
          client,
          contact,
          team: client?.team_id ? teamMap.get(client.team_id) : undefined,
          coordinators: result.coordinators,
        })
        return <button
          key={i}
          className={'flex items-center px-3 py-2 space-x-3 rounded-lg bg-red-50 hover:bg-red-100 text-red-900'}
          onClick={() => onClick()}>
          <div className={"h-10 w-10 rounded-full flex items-center justify-center mr-2"}>
            <FontAwesomeIcon icon={faUserCircle} className={'text-2xl'} />
          </div>
          {contact && <div className={"flex-1 flex flex-col items-start"}>
            <div className={"text-sm -mb-1 text-red-800"}>Contactpersoon</div>
            <div className={'text-lg font-medium'}>{contact.given_name} {contact.family_name}</div>
          </div>}
          <div className={"flex-1 flex flex-col items-start"}>
            <div className={"text-sm -mb-1 text-red-800"}>Cliënt #{client.client_number}</div>
            <div className={'text-lg font-medium'}>{client.name}</div>
          </div>
          <div>
            <small>Details</small>
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
        </button>
      })}
    </div>}
  </div>
}

function ClientInfo(props: { client: Client, searchData?: SearchData, matches: SavedMatch[] }) {
  return <div className={"bg-red-50 p-3 rounded-lg"}>
    <div className={"flex items-center"}>
      <div className={'h-12 w-12 rounded-full bg-red-500 text-white flex items-center justify-center mr-3'}>
        <FontAwesomeIcon icon={faUser} />
      </div>
      <div className={"flex-1"}>
        <div className={"text-sm -mb-1 text-red-800"}>Client #&nbsp;<span className={"font-medium underline tracking-wider text-black"}>{props.client.client_number}</span></div>
        <div className={'text-lg font-medium'}>{props.client.name}</div>
      </div>
      <ExistingMatchesPill matches={props.matches} />
      {props.searchData?.city || props.searchData?.date_of_birth ? <div className={"ml-4"}>
      </div> : <></>}
    </div>
    <div className={"grid grid-cols-2 ml-16 mt-2 gap-4"}>
      <div className={""}>
        <strong className={"text-red-900 text-sm"}>Contactgegevens</strong>
        <div className={"text-sm text-red-800"}>E-mail: <span className={"text-red-900"}>{props.client.email ?? '-'}</span>
        </div>
      </div>
      {props.searchData?.date_of_birth && <div>
        <strong className={"text-red-900 text-sm"}>Geboortedatum</strong>
        <div className={"text-sm text-red-800"}>{props.searchData.date_of_birth}</div>
      </div>}
      {(props.client.city || props.client.zipcode) && <div>
        <strong className={"text-red-800 text-sm"}>Adres</strong>
        {props.client.zipcode && <div className={"text-sm text-red-800"}>Postcode: <span className={"text-red-900"}>{props.client.zipcode}</span></div>}
        {props.client.city && <div className={"text-sm text-red-800"}>Plaats: <span className={"text-red-900"}>{props.client.city}</span></div>}
      </div>}
    </div>
  </div>
}

function ClientContactInfo(props: { contact: ClientContactDetails, matches: SavedMatch[] }) {
  return <div className={"flex items-center bg-red-50 p-3 rounded-lg"}>
    <div className={'h-12 w-12 rounded-full bg-red-500 text-white flex items-center justify-center mr-3'}>
      <FontAwesomeIcon icon={faPerson} />
    </div>
    <div className={'flex-1'}>
      <div className={"text-sm -mb-1 text-red-800"}>Contactpersoon</div>
      <div className={'text-lg font-medium'}>{props.contact.given_name} {props.contact.family_name}</div>
    </div>
    <ExistingMatchesPill matches={props.matches} />
  </div>
}

function TeamInfo(props: {team: Team}) {
  return <div className={"flex items-center bg-red-50 p-3 rounded-lg"}>
    <div className={'h-12 w-12 rounded-full bg-red-500 text-white flex items-center justify-center mr-3'}>
      <FontAwesomeIcon icon={faPerson} />
    </div>
    <div>
      <div className={"text-sm -mb-1 text-red-800"}>Team {props.team.identificationNo}</div>
      <div className={'text-lg font-medium'}>{props.team.name}</div>
    </div>
  </div>
}

function EmployeeInfo(props: {employee: Employee, matches: SavedMatch[]}) {
  return <div className={"flex items-center bg-red-50 p-3 rounded-lg"}>
    <div className={'h-12 w-12 rounded-full bg-red-500 text-white flex items-center justify-center mr-3'}>
      <FontAwesomeIcon icon={faPerson} />
    </div>
    <div className={"flex-1"}>
      <div className={"text-sm -mb-1 text-red-800"}>Medewerker #&nbsp;<span className={"font-medium underline tracking-wider text-black"}>{props.employee.employee_number}</span></div>
      <div className={'text-lg font-medium'}>{props.employee.name}</div>
    </div>
  </div>
}

// eslint-disable-next-line
function ManageMatchesModal(props: {matches: SavedMatch[], onMatchesChange: (remainingMatches: SavedMatch[]) => void}) {
  const [loading, setLoading] = useState<SavedMatch['id'][]>([])
  const [matches, setMatches] = useState<SavedMatch[]>(props.matches)
  const {telephone} = useApiCall(config)
  const {close} = useModalControls()
  useEffect(() => {
    setMatches(props.matches)
  }, [props.matches])
  useEffect(() => {
    if (matches.length === 0) {
      close()
    }
  }, [matches])
  const remove = (match: SavedMatch) => {
    setLoading(loading => [...loading, match.id])
    telephone.delMatch(match.id).then(() => {
      props.onMatchesChange(matches.filter(m => m.id !== match.id))
      setLoading(loading.filter(l => l !== match.id))
      setMatches(matches => matches.filter(m => m.id !== match.id))
    }).catch(() => {
      setLoading(loading.filter(l => l !== match.id))
    })
  }
  return <div>
    {props.matches.map((match, i) => {
      return <div key={i} className={"flex items-center mb-4"}>
        <div className={"flex-1"}>{match.telephone_number}</div>
        <button className={`h-8 w-8 rounded ${loading.includes(match.id) ? 'bg-slate-100 text-slate-800' : 'bg-red-100 hover:bg-red-200 text-red-800'}`} disabled={loading.includes(match.id)} onClick={() => remove(match)}>
          <FontAwesomeIcon icon={loading.includes(match.id) ? faHourglass : faTimes} className={"text-lg font-medium"} />
        </button>
      </div>
    })}
  </div>
}

function ExistingMatchesPill(props: {matches: SavedMatch[]}) {
  const [matches, setMatches] = useState<SavedMatch[]>(props.matches)
  useEffect(() => {
    setMatches(props.matches)
  }, [props.matches])
  const modal = useModal({title: "Beheer gekoppelde nummers", body: <ManageMatchesModal matches={matches} onMatchesChange={(remainingMatches) => setMatches(remainingMatches)} />})
  return <button disabled={props.matches.length === 0} onClick={() => modal.open()} className={`ml-3 px-3 rounded-full text-sm py-1 ${props.matches.length > 0 ? 'text-red-800 bg-red-100 hover:bg-red-200 cursor-pointer font-medium' : 'text-slate-500'}`}>{props.matches.length} {props.matches.length === 1 ? 'handm. gekoppeld nummer' : 'handm. gekoppelde nummers'}</button>
}

const CoordinatorForwardInfo: FC<{coordinator: RelatedCoordinator, employeeMap: Map<number, JaneEmployee>, onForward?: () => Promise<void>}> = (props) => {
  const employee = props.employeeMap.get(props.coordinator.ons_employee_id)

  if (employee === undefined) return <></>
  return <div className={'bg-brand-primary-container mb-2 px-5 py-3 rounded-lg flex justify-between items-center'}>
    <div className={'text-brand-on-primary-container'}>
      <div className={"mb-1 font-medium text-lg"}>{employee.name}</div>
      {props.coordinator.primary ? <div
        className={'px-3 rounded-full text-sm font-bold border border-brand-primary bg-brand-primary text-brand-on-primary'}>
          Primaire coördinator
      </div> :
        <div className={'px-3 rounded-full text-sm font-bold border border-brand-primary text-brand-primary'}>
          Secundaire coördinator
        </div>}
    </div>
    {/*<Button type={'primary'} size={'sm'} text={`Doorverbinden naar ${props.coordinator.extension}`} disabled={props.onForward === undefined} onClick={props.onForward} />*/}
  </div>
}