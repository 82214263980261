import React, {FC, useEffect, useState} from "react";
import {ReactKeycloakProvider, useKeycloak} from "@react-keycloak/web";
import {UnauthenticatedPlaceholder} from "../components/layout/UnauthenticatedPlaceholder";
import {AuthenticatedLayout, NavigationDefinition} from "../components/layout/AuthenticatedLayout";
import Keycloak from "keycloak-js";
import {ConfigInterface} from "../config";



export const AuthProvider: FC<{children: React.ReactNode, authClient: Keycloak}> = ({children, authClient}) => {
  /**
   * This is a dirty workaround for an imperfection in react-keycloak.
   * It does not re-render the app when the token is refreshed. Usually, this is not a problem because the user is
   * clicking through the application triggering re-renders. But when the user is idle (e.g. on the deployments page on
   * a second monitor), the token will expire and the HTTP requests will start failing.
   * By setting a timer that triggers a re-render every 10 seconds, we can work around this issue. Because incrementing
   * the useState value will trigger a re-render of <ReactKeycloakProvider>, which will handle the token refresh.
   */
  const [, setDirtyWorkaroundCounter] = useState(0)
  useEffect(() => {
    const timer = setInterval(() => {
      authClient.updateToken(60)
      setDirtyWorkaroundCounter(prev => prev + 1)
    }, 10000)
    return () => clearInterval(timer)
  }, [])

  const onTokens = (tokens: {idToken?: string, refreshToken?: string, token?: string}) => {
    setToken(tokens.token ?? null)
  }

  return <ReactKeycloakProvider
    authClient={authClient}
    initOptions={{onLoad: 'login-required'}}
    onEvent={(event, error) => {
      if (error) {
        console.error("AUTH: ", error)
      } else {
        console.debug("AUTH: ", event)
      }
    }}
    onTokens={onTokens}
  >
    {children}
  </ReactKeycloakProvider>
}
export const AuthMiddleware: FC<{children: React.ReactNode, config: ConfigInterface, navigation: NavigationDefinition, allowedRoles: string[], appTitle: string, appSubTitle: string, image: string|null}> = (props) => {
  const {keycloak} = useKeycloak()

  // TODO: Hier mot nog code die er voor zorgt dat je wel bij public pages mag

  if (! keycloak.authenticated) {
    return <UnauthenticatedPlaceholder />
  }
  const roles = keycloak?.realmAccess?.roles ?? []
  if (! props.allowedRoles.some(role => roles.includes(role))) {
    return <UnauthenticatedPlaceholder reason={"Je hebt geen toegang tot deze applicatie"} />
  }
  return <AuthenticatedLayout navigation={props.navigation} title={props.appTitle} subtitle={props.appSubTitle} image={props.image}>{props.children}</AuthenticatedLayout>
}
function setToken(token: string|null): void {
  if (! window?.localStorage) {
    console.error("localStorage not available")
    return
  }
  if (token === null) {
    window.localStorage.removeItem('session')
  } else {
    window.localStorage.setItem("session", token)
  }
}
export function getToken(): string|null {
  if (! window?.localStorage) {
    console.error("localStorage not available")
    return null
  }
  return window.localStorage.getItem("session")
}
