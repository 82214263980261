import { useApiCall } from "@jane/lib/src/api";
import { Input } from "@jane/lib/src/components/form/Input";
import {FC, useState} from "react";
import {ModalFooter} from "../components/ModalProvider";
import {config} from "../config";
import {BuddyTeam} from "../../../lib/src/api/repositories/buddy-teams-repository";

export const EditBuddyTeamModal: FC<{team: BuddyTeam, onFinished: () => void}> = (props) => {
  const {buddyTeams} = useApiCall(config)
  const [email, setEmail] = useState(props.team.email ?? '')
  const save = async () => {
    await buddyTeams.addOrUpdate(props.team.team_id, props.team.target, props.team.enabled, email)
    props.onFinished()
  }
  return <div className={"flex flex-col space-y-3"}>
    <Input type={'email'} label={'Email'} value={email} onChange={setEmail} />
    <ModalFooter text={'Opslaan'} onSubmit={save} />
  </div>
}