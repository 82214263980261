import {Employee, Team, useApiCall, useApiEndpoint} from "@jane/lib/src/api";
import { Checkbox } from "@jane/lib/src/components/form/Checkbox";
import { Input } from "@jane/lib/src/components/form/Input";
import { useRefresh } from "@jane/lib/src/components/RefreshController";
import React, {FC, useMemo, useState} from "react";
import {ModalFooter} from "../components/ModalProvider";
import {config} from "../config";
import {useTeamData} from "../resources/TeamContext";
import {usePermissions} from "../resources/PermissionsHook";
import {useTeamMap} from "../components/team-select";
import { Select } from "@jane/lib/src/components/form/Select";
import { Paragraph } from "@jane/lib/src/components/Paragraph";
import { useToasts } from "@jane/lib/src/toasts/toasts";
import {useEmployeesData} from "../resources/EmployeeContext";

export const AssignLearnModuleToEmployeeModal: FC<{onFinished: () => void, moduleId: string, employees: Employee[]}> = (props) => {
  const {learn} = useApiCall(config)
  const [employee, setEmployee] = useState<number|null>(null)
  const employeeFilterOptions = useMemo(() => {
    return Object.fromEntries(props.employees.map((e) => [e.id.toString(), e.name]))
  }, [props.employees])
  const toasts = useToasts()

  const save = async () => {
    if (employee === null) return
    await learn.assignModuleToEmployee(props.moduleId, employee)
    toasts.showToast('Onderwerp toegewezen', `De relevante personen krijgen hier een melding van.`)
    props.onFinished()
  }
  return <div className={"flex flex-col space-y-3"}>
    <Select label={"Team"} options={employeeFilterOptions} value={employee === null ? '-1' : employee.toString()} onChange={(v) => setEmployee(v === '-1' ? null : Number(v))} />
    <Paragraph>Wanneer je een onderwerp uitrolt bij een medewerker krijgt deze een melding dat er een nieuw onderwerp klaar staat in hun kennisbank.</Paragraph>
    <ModalFooter text={'Opslaan'} onSubmit={save}/>
  </div>
}
