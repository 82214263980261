import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import {BrowserRouter, Route, Routes} from "react-router-dom";
import * as fas from "@fortawesome/free-solid-svg-icons";
import * as far from "@fortawesome/free-regular-svg-icons";
import Keycloak from "keycloak-js";
import {NavigationDefinition} from "@jane/lib/src/components/layout/AuthenticatedLayout";
import {AuthMiddleware, AuthProvider} from "@jane/lib/src/auth/AuthProvider";
import {config} from "./config";
import { ToastContextProvider } from '@jane/lib/src/toasts/toasts';
import OverviewPage from './pages/OverviewPage';
import {EmployeeContextProvider} from "./resources/EmployeeContext";
import {TaskContextProvider} from "./resources/TaskContext";
import {ClientContextProvider} from "./resources/ClientContext";
import {ClientContactContextProvider} from "./resources/ClientContactContext";
import {TeamContextProvider} from "./resources/TeamContext";
import { AuthContextProvider } from '@jane/lib/src/auth/context';
import TasksListPage from "./pages/tasks/TasksListPage";
import EmployeesListPage from "./pages/employees/EmployeesListPage";
import EmployeesDetailPage from "./pages/employees/EmployeesDetailPage";
import ClientsListPage from "./pages/clients/ClientsListPage";
import ClientsDetailsPage from './pages/clients/ClientsDetailsPage';
import SettingsPage from "./pages/settings/SettingsPage";
import SettingsSocialCardPage from "./pages/settings/SettingsSocialCardPage";
import ChatPage from "./pages/chat/ChatPage";
import BroadcastsListPage from "./pages/broadcasts/BroadcastsListPage";
import BroadcastsCreatePage from "./pages/broadcasts/BroadcastCreatePage";
import BroadcastsDetailsPage from "./pages/broadcasts/BroadcastDetailsPage";
import {LearnModulesListPage} from "./pages/learn/LearnModulesListPage";
import {ModalProvider} from "./components/ModalProvider";
import { RefreshController } from '@jane/lib/src/components/RefreshController';
import {LearnModuleDetailPage} from "./pages/learn/LearnModuleDetailsPage";
import {LearnComponentDetailPage} from "./pages/learn/LearnComponentDetailsPage";
import {TelephonePage} from "./pages/telephone/TelephonePage";
import {TaskDetailsPage} from "./pages/tasks/TasksDetailsPage";
import * as Sentry from "@sentry/react";
import {Toolbar} from "./components/Toolbar";
import {TelephoneAuthenticationProvider} from "./telephone/context/TelephoneAuthenticationContext";
import {TelephoneSocketProvider} from "./telephone/context/TelephoneSocketContext";
import {TelephoneMatchesProvider} from "./telephone/context/TelephoneMatchesContext";
import Logs from "./pages/settings/Logs";
import TelephoneSettingsPage from "./pages/settings/TelephoneSettingsPage";


if (config.sentryDsn) {
  Sentry.init({
    dsn: config.sentryDsn,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost",
      /^https:\/\/api\.miep\.janehelpt\.eu\/v1/,
      /^wss:\/\/api\.miep\.janehelpt\.eu\/v1/,
      /^https:\/\/chat01\.janehelpt\.eu\/v1/,
      /^https:\/\/api\.miep\.online\/v1/,
      /^wss:\/\/api\.miep\.online\/v1/,
      /^https:\/\/chat\.miep\.online\/v1/,
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const authClient = new Keycloak({
  url: config.authRealmUrl,
  realm: config.authRealm,
  clientId: config.authClientId,
})

const navigation: NavigationDefinition = [
  {
    link: '/',
    text: 'Overzicht',
    icon: fas.faHome,
  },
  {
    link: '/telephone',
    text: 'Telefonie',
    icon: fas.faPhone,
    requiredPermissions: ['canAccessPhoneReception'],
  },
  {
    link: '/tasks',
    text: 'Taken',
    icon: far.faCheckSquare,
    // TODO: Permissions
  },
  {
    link: '/clients',
    text: 'Cliënten',
    icon: fas.faPerson,
    requiredPermissions: ['canAccessClients'],
  },
  {
    link: '/employees',
    text: 'Medewerkers',
    icon: fas.faPeopleGroup,
    requiredPermissions: ['canAccessEmployees'],
  },
  {
    link: '/broadcasts',
    text: 'Publicaties',
    icon: fas.faBullhorn,
    requiredPermissions: ['canAccessBroadcasts'],
  },
  {
    link: '/learn',
    text: 'Kennisbank',
    icon: fas.faGraduationCap,
    requiredPermissions: ['canManageLearn'],
  },
  {
    link: '/chat',
    text: 'Chat',
    icon: far.faComments,
    // TODO: Permissions
  },
  {
    link: '/settings',
    text: 'Instellingen',
    icon: fas.faCog,
    requiredPermissions: ['canManageSettings'],
  },
]

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <AuthProvider authClient={authClient}>
    {/*<React.StrictMode>*/}
      <BrowserRouter>
        <AuthContextProvider config={config}>
          <ToastContextProvider>
            <AuthMiddleware navigation={navigation} config={config} allowedRoles={["employee", "telephone", "coordinator", "manager"]} appTitle={""} appSubTitle={"voor Medewerkers"} image={'/img/title_miep.png'}>
              <RefreshController>
                <ModalProvider>
                  <EmployeeContextProvider>
                    <ClientContextProvider>
                      <ClientContactContextProvider>
                        <TaskContextProvider>
                          <TeamContextProvider>
                            <TelephoneAuthenticationProvider>
                              <TelephoneSocketProvider>
                                <TelephoneMatchesProvider>
                                  <main className={"flex-1 overflow-y-scroll"} style={{height: 'calc(100vh - 5rem)'}}>
                                    <div className={"mx-6 my-8"}>
                                      <Routes>
                                        <Route path={"/"} element={<OverviewPage/>}/>
                                        <Route path={"/tasks"} element={<TasksListPage/>}/>
                                        <Route path={"/tasks/:id"} element={<TaskDetailsPage/>}/>
                                        <Route path={"/employees"} element={<EmployeesListPage/>}/>
                                        <Route path={"/employees/:id"} element={<EmployeesDetailPage/>}/>
                                        <Route path={"/clients"} element={<ClientsListPage/>}/>
                                        <Route path={"/clients/:id"} element={<ClientsDetailsPage/>}/>
                                        <Route path={"/settings"} element={<SettingsPage/>}/>
                                        <Route path={"/settings/social_card"} element={<SettingsSocialCardPage/>}/>
                                        <Route path={"/settings/telephone"} element={<TelephoneSettingsPage />} />
                                        <Route path={"/logs"} element={<Logs/>}/>
                                        <Route path={"/chat"} element={<ChatPage/>}/>
                                        <Route path={"/broadcasts"} element={<BroadcastsListPage/>}/>
                                        <Route path={"/broadcasts/new"} element={<BroadcastsCreatePage/>}/>
                                        <Route path={"/broadcasts/:id"} element={<BroadcastsDetailsPage/>}/>
                                        <Route path={"/telephone"} element={<TelephonePage/>}/>
                                        <Route path={"/learn"} element={<LearnModulesListPage/>}/>
                                        <Route path={"/learn/:moduleId"} element={<LearnModuleDetailPage/>}/>
                                        <Route path={"/learn/:moduleId/:componentId"} element={<LearnComponentDetailPage/>}/>
                                      </Routes>
                                    </div>
                                  </main>
                                  <Toolbar />
                                </TelephoneMatchesProvider>
                              </TelephoneSocketProvider>
                            </TelephoneAuthenticationProvider>
                          </TeamContextProvider>
                        </TaskContextProvider>
                      </ClientContactContextProvider>
                    </ClientContextProvider>
                  </EmployeeContextProvider>
                </ModalProvider>
              </RefreshController>
            </AuthMiddleware>
          </ToastContextProvider>
        </AuthContextProvider>
      </BrowserRouter>
    {/*</React.StrictMode>*/}
  </AuthProvider>
)
