import React, { createContext, useCallback, useContext, useState } from "react"
import md5 from 'md5'
import {IconButton} from "../components/form/Button";
import {faTimes} from "@fortawesome/free-solid-svg-icons";

const TOAST_CLOSE_TIMEOUT = 4000

interface ToastData {
    id: string
    title: string
    content: string
}

export function Toasts(): JSX.Element {
    
    const { toasts, closeToast } = useToasts()

    const visibleToasts = toasts
        .map((toast, i) => {
            return <div key={i} className={"bg-white min-w-[400px] mb-4 rounded-lg shadow border border-slate-200"}>
                <div className={'flex items-center justify-between h-12 border-b pl-5 pr-2 border-slate-200'}>
                  <strong className="">{ toast.title }</strong>
                  <IconButton size={'xs'} type={'danger'} icon={faTimes} onClick={() => closeToast(toast.id)} />
                </div>
                <div className={'px-5 py-3'}>{ toast.content }</div>
            </div>
        })

    return <>
        { visibleToasts }
    </>
}

interface ToastContextType {
    toasts: ToastData[]
    showToast: (title: string, content: string, timeout?: number) => void
    closeToast: (toastId: string) => void
}
export const ToastContext = createContext<ToastContextType>({} as ToastContextType)
export function ToastContextProvider({children}: {children: React.ReactNode}): JSX.Element {

    const [toasts, setToasts] = useState<ToastData[]>([])

    const closeToast = useCallback((toastId: string): void => {
        setToasts(oldToasts => oldToasts.filter(toast => toast.id !== toastId))
    }, [setToasts])

    const showToast = useCallback((title: string, content: string, timeout?: number): void => {
        const id = md5((new Date()).getTime() + title + content)
        const toast: ToastData = {
            title,
            content,
            id
        }
        setToasts(oldToasts => [...oldToasts, toast])
        setTimeout(() => closeToast(id), timeout || TOAST_CLOSE_TIMEOUT)
    }, [setToasts])

    const context: ToastContextType = { toasts, showToast, closeToast }

    return <ToastContext.Provider value={context}>{children}</ToastContext.Provider>
}

export function useToasts(): ToastContextType {
    return useContext(ToastContext)
}

export function ToastContainer(): JSX.Element {
  return <div className={'fixed right-4 top-24'}>
    <Toasts />
  </div>
}
