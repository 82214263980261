import React, {FC} from "react";
import {useKeycloak} from "@react-keycloak/web";
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromBracket,
  faChevronLeft,
  faChevronRight,
  IconDefinition
} from "@fortawesome/free-solid-svg-icons";
import {usePersistentState} from "../../util/usePersistentState";
import {ModalProvider} from "./ModalProvider";
import {RefreshController} from "../RefreshController";
import {usePermissions} from "@hostingbv/frontend-medewerker/src/resources/PermissionsHook";
import {ToastContainer} from "../../toasts/toasts";

export type NavigationDefinition = ({
  link: string,
  icon: IconDefinition,
  text: string,
  beta?: true,
  requiredPermissions?: (keyof ReturnType<typeof usePermissions>)[]
}|{
  header: string
})[]
export const AuthenticatedLayout: FC<{children: React.ReactNode, topChildren?: React.ReactNode, navigation: NavigationDefinition, title: string, subtitle: string, image: string|null}> = ({children, topChildren, navigation, title, subtitle, image}) => {
  const {keycloak} = useKeycloak()
  const [isCollapsed, setIsSideCollapsed] = usePersistentState<boolean>('collapse_sidebar', false)
  const permissionsHook = usePermissions()

  const navigate = useNavigate()

  const filteredNavigation = navigation.filter(item => {
    if ('header' in item) {
      return true
    }
    if (item.requiredPermissions === undefined) {
      return true
    }
    return item.requiredPermissions.every(permission => permissionsHook[permission])
  })

  return <div className={"flex flex-col min-h-screen bg-brand-surface text-brand-on-surface"}>
    <RefreshController>
      <ToastContainer />
      <ModalProvider>
        <nav className={"bg-brand-primary text-brand-on-primary h-20 flex items-stretch justify-start"}>
          {image !== null ? <>
            <div className={"flex items-center px-3"}>
              <img src={image} className={"h-16"} alt={""} />
            </div>
          </> : <div className={"w-6"} />}
          <div className={"flex-1 flex flex-col justify-center items-start pr-6"}>
            <h1 className={"font-bold text-xl leading-none"}>{title}</h1>
            <span className={"font-light opacity-75 text-sm"}>{subtitle}</span>
          </div>
          <button className={"flex items-center hover:bg-[#0002] px-6"} onClick={() => keycloak.logout()}>
            <div className={"flex flex-col items-end justify-center mr-4"}>
              <small className={"leading-tight opacity-90"}>Ingelogd als {keycloak.idTokenParsed?.email ?? '-'}</small>
              <div className={"leading-tight"}>Uitloggen</div>
            </div>
            <FontAwesomeIcon icon={faArrowRightFromBracket} />
          </button>
        </nav>
        <div className={"flex-1 flex items-stretch"}>
          <aside
            className={`bg-brand-on-secondary text-brand-secondary ${isCollapsed ? 'w-16' : 'w-48'} transition-all border-r border-slate-200 flex flex-col justify-between pt-8 overflow-hidden`}>
            {/* Navigation */}
            <div className={'flex flex-col'}>
              {filteredNavigation.map((item, i) => {
                if ('header' in item) {
                  return <div key={i} className={"text-sm font-medium px-3 mt-6 mb-2"}>{item.header}</div>
                }
                const isActive = item.link === "/" ? window.location.pathname === "/" : window.location.pathname.startsWith(item.link)
                return <button key={i}
                               className={`mx-3 px-3 mb-1 py-2 rounded-full flex font-medium ${isActive && "bg-brand-primary-container text-brand-primary"}`}
                               onClick={() => navigate(item.link)}>
                  <div className={`h-6 w-6 flex items-center justify-center ${!isCollapsed && 'mr-2'}`}>
                    <FontAwesomeIcon icon={item.icon} />
                  </div>
                  {!isCollapsed && item.text}
                </button>
              })}
            </div>
            <button
              className={"h-10 py-6 flex items-center justify-center px-4 overflow-hidden whitespace-nowrap text-lg font-medium text-brand-primary hover:bg-brand-100 dark:text-zinc-300 hover:dark:bg-brand-700"}
              onClick={() => setIsSideCollapsed(x => !x)}>
              <div>
                <FontAwesomeIcon icon={isCollapsed ? faChevronRight : faChevronLeft}/>
              </div>
            </button>
          </aside>
          {children}
        </div>
      </ ModalProvider>
    </RefreshController>
  </div>
}
