import {createContext, FC, ReactNode, useCallback, useContext, useEffect, useState} from "react";
import {usePermissions} from "../../resources/PermissionsHook";
import {config} from "../../config";
import {useApiCall} from "../../../../lib/src/api";

type TelephoneContextParams = {
  children: ReactNode
}
type TelephoneAuthenticationContextType = {
  status: "booting"
} | {
  status: "no_access"
} | {
  status: "login_required"
  saveCredentials: (username: string, password: string) => Promise<void>
  errors: string[]
} | {
  status: "ready"
  onLoginFailed: (reason?: string) => void
  credentials: { token: string|null, user: string|null }
}
export const TelephoneAuthenticationContext = createContext<TelephoneAuthenticationContextType>({} as TelephoneAuthenticationContextType)

export const TelephoneAuthenticationProvider: FC<TelephoneContextParams> = ({children}) => {
  const { canAccessPhone, canSeePhoneSidebar } = usePermissions()
  const {telephone} = useApiCall(config)
  const [credentials, setCredentials] = useState<{ token: string|null, user: string|null }|null>(null)
  const [isLoadingCredentials, setIsLoadingCredentials] = useState(false)
  const [isSavingCredentials, setIsSavingCredentials] = useState(false)
  const [errors, setErrors] = useState<string[]>([])

  useEffect(() => {
    if (!(canAccessPhone || canSeePhoneSidebar)) return
    setIsLoadingCredentials(true)
    telephone.getCredentials().then((credentials) => {
      setCredentials(credentials)
      setIsLoadingCredentials(false)
    })
  }, [canAccessPhone])
  const saveCredentials = useCallback(async (username: string, password: string) => {
    setIsSavingCredentials(true)
    await telephone.saveCredentials(username, password).then((credentials) => {
      setCredentials(credentials)
      setIsSavingCredentials(false)
    })
  }, [canAccessPhone])

  if (!(canAccessPhone || canSeePhoneSidebar)) {
    return <TelephoneAuthenticationContext.Provider value={{status: "no_access"}}>{children}</TelephoneAuthenticationContext.Provider>
  }
  if (isLoadingCredentials) {
    return <TelephoneAuthenticationContext.Provider value={{status: "booting"}}>{children}</TelephoneAuthenticationContext.Provider>
  }
  if (!credentials) {
    return <TelephoneAuthenticationContext.Provider value={{status: "login_required", saveCredentials, errors}}>{children}</TelephoneAuthenticationContext.Provider>
  }

  return <TelephoneAuthenticationContext.Provider value={{
    status: "ready",
    onLoginFailed:  (reason) => {
      console.log('onLoginFailed')
      setCredentials(null)
      setErrors(reason ? [reason] : [])
    },
    credentials: credentials,
  }}>
    {children}
  </TelephoneAuthenticationContext.Provider>
}
