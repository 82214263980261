import {useApiCall, useApiEndpoint} from "@jane/lib/src/api";
import {Employee} from "@jane/lib/src/api/repositories/employees-repository"
import {Card} from "@jane/lib/src/components/Card";
import React from "react"
import {config} from "../config";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileCertificate} from "@fortawesome/pro-light-svg-icons";
import {Button} from "../../../lib/src/components/form/Button";
import {LearnCertificate} from "../../../lib/src/api/repositories/learn-repository";
import {faFileDownload} from "@fortawesome/free-solid-svg-icons";

export default function CertificateForm(props: { employee: Employee }): JSX.Element {
  const {learn} = useApiCall(config)
  const {resource: certificates} = useApiEndpoint(() => learn.listEmployeeCertificates(props.employee.id), true)

  function downloadCertificate(certificate: LearnCertificate) {
    window.open(certificate.pdf, '_blank')
  }

  return <Card title={'Certificaten'} id={'certificaten'}>
    <p className={'text-sm'}>
      Door het afronden van Kennisbank modules verzamelt een medewerker certificaten.
    </p>
    <div className={'mt-2'}>
      {certificates !== undefined ? certificates.map((certificate, i) => <div key={i} className={`flex items-center h-10`}>
        <FontAwesomeIcon icon={faFileCertificate}/>
        <div className={'mx-3 flex-1 font-medium'}>{certificate.learn_module?.name}</div>
        <Button type={'secondary'} size={'xs'} icon={faFileDownload} text={'Download PDF'}
                onClick={() => downloadCertificate(certificate)}/>
      </div>) : <></>}
    </div>
  </Card>
}
