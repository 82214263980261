import { useApiCall } from "@jane/lib/src/api";
import { Checkbox } from "@jane/lib/src/components/form/Checkbox";
import { Input } from "@jane/lib/src/components/form/Input";
import { useRefresh } from "@jane/lib/src/components/RefreshController";
import {FC, useState} from "react";
import {ModalFooter} from "../components/ModalProvider";
import {config} from "../config";
import {Select} from "../../../lib/src/components/form/Select";
import {LearnCategory} from "../../../lib/src/api/repositories/learn-repository";

export const MoveLearnModuleModal: FC<{moduleId: string, categories: LearnCategory[], onFinished: () => void}> = (props) => {
  const {learn} = useApiCall(config)
  const [category, setCategory] = useState('-')
  const save = async () => {
    await learn.moveModuleToCategory(props.moduleId, category === '-' ? null : category)
    props.onFinished()
  }
  return <div className={"flex flex-col space-y-3"}>
    <Select value={category} onChange={setCategory} options={Object.fromEntries([['-','- Geen -'], ...props.categories.map(x => [x.id, x.name])])} label={'Categorie'} />
    <ModalFooter text={'Opslaan'} onSubmit={save} />
  </div>
}