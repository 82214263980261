import { ContentContainer } from "@jane/lib/src/components/content/ContentContainer"
import { PageHeader } from "@jane/lib/src/components/content/PageHeader"
import {Button, IconButton} from "@jane/lib/src/components/form/Button"
import { Paragraph } from "@jane/lib/src/components/Paragraph"
import {faAdd, faChevronRight, faFolder, faFolderOpen, faGraduationCap} from "@fortawesome/free-solid-svg-icons";
import {useApiCall, useApiEndpoint} from "@jane/lib/src/api";
import {config} from "../../config";
import { Loading } from "@jane/lib/src/components/Loading";
import {LearnCard, LearnCategory, LearnModule} from "@jane/lib/src/api/repositories/learn-repository";
import React, {useState} from "react";
import { Input } from "@jane/lib/src/components/form/Input";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useModal} from "../../components/ModalProvider";
import {AddLearnModuleModal} from "../../modals/AddLearnModuleModal";
import {useNavigate} from "react-router-dom";
import { Breadcrumbs } from "@jane/lib/src/components/content/Breadcrumbs";
import {AddLearnCategoryModal} from "../../modals/AddLearnCategoryModal";
import {faShuffle} from "@fortawesome/pro-light-svg-icons";
import {MoveLearnModuleModal} from "../../modals/MoveLearnModuleModal";
import {ConfirmationModal} from "../../modals/ConfirmationModal";

export const LearnModulesListPage: React.FC = () => {
  const {learn} = useApiCall(config)
  const {resource, reload} = useApiEndpoint(() => learn.listModules())
  const { resource: categoryResource, reload: reloadCategories} = useApiEndpoint(() => learn.listCategories())
  const [search, setSearch] = useState('')
  const addModal = useModal({title: "Nieuw onderwerp", body: <AddLearnModuleModal onFinished={() => reload()} />})
  const modules = resource?.filter(module =>
    module.name.toLowerCase().includes(search.toLowerCase()) ||
    module.description.toLowerCase().includes(search.toLowerCase()) ||
    module.components?.some(component => component.name.toLowerCase().includes(search.toLowerCase()))
  ) ?? [];
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null)

  const showCategories = selectedCategory === null ? null : [selectedCategory, ...getChildCategoryIds(categoryResource ?? [], selectedCategory)]

  const deleteSelectedCategoryModal = useModal({title: "Categorie verwijderen", body: <ConfirmationModal text={'Weet je zeker dat je deze categorie wilt verwijderen?'} confirmText={'Verwijderen'} onConfirmed={async () => {
    await learn.deleteCategory(selectedCategory!)
    setSelectedCategory(old => old === selectedCategory ? null : old)
    reloadCategories()
  }} />})

  return <ContentContainer>
    <Breadcrumbs currentPage={'Overzicht'} crumbs={[
      {label: 'Kennisbank', href: '/learn'}
    ]} />
    <PageHeader>Kennisbank</PageHeader>
    <section className={"flex justify-between"}>
      <div className={"max-w-2xl"}>
        <Paragraph>
          De kennisbank bestaat uit onderwerpen, welke bestaan uit onderdelen. Waaronder de informatie uit het traject beschikbaar is in meerdere kaarten.<br />
          Kaarten kunnen tekst bevatten, afbeeldingen, video’s of interactieve vragen.
        </Paragraph>
      </div>
      <Button text={'Nieuw onderwerp'} size={'sm'} type={'primary'} icon={faAdd} onClick={() => addModal.open()} />
    </section>
    <section>
      <Loading loading={resource === undefined || categoryResource === undefined}>
        <Input label={'Zoeken'} type={'search'} placeholder={'Zoeken op modules en onderdelen'} value={search} onChange={setSearch} />
        <div className={'flex flex-col 2xl:flex-row mt-4'}>
          <div className={'mr-4'}>
            <CategoryBrowser reloadCategories={() => reloadCategories()} categories={categoryResource??[]} modules={modules} selected={selectedCategory} onSelect={(category) => setSelectedCategory(category)} />
          </div>
          {(selectedCategory !== null && !categoryResource?.some(c => c.parent_category_id === selectedCategory) && !modules.some(m => m.category_id === selectedCategory)) && <div className={'w-72 bg-brand-surface border border-brand-surface-variant px-4 py-2 rounded-lg text-brand-on-primary-container text-sm font-medium flex flex-col items-center justify-center'}>
            <h2 className={'font-medium text-base'}>Geen modules</h2>
            <div className={'font-normal text-sm my-2 text-center'}>Deze categorie is leeg. Je kunt modules of subcategorieën toevoegen.</div>
            <Button type={'danger'} size={'xs'} text={'Verwijder categorie'} onClick={() => deleteSelectedCategoryModal.open()} />
          </div>}
          <div>
            {modules.filter(m => showCategories === null || (m.category_id !== null && showCategories.includes(m.category_id))).map(module => <ModuleRow key={module.id} module={module} categories={categoryResource??[]} reload={() => reload()} />)}
          </div>
        </div>
      </Loading>
    </section>
  </ContentContainer>
}

const ModuleRow: React.FC<{module: LearnModule, categories: LearnCategory[], reload: () => void}> = ({module, categories, reload}) => {
  const navigate = useNavigate()
  const moveModal = useModal({title: "Verplaatsen", body: <MoveLearnModuleModal moduleId={module.id} categories={categories} onFinished={() => {reload()}} />})
  return <div
    className={'mt-4 bg-brand-primary-container text-brand-on-primary-container px-4 py-3 rounded-xl flex items-stretch'}>
    <div className={"flex-1 flex flex-col items-start justify-between"}>
      <div className={'flex items-center'}>
        <FontAwesomeIcon icon={faGraduationCap} className={'text-sm'}/>
        <h2 className={"text-lg font-medium mx-3"}>{module.name}</h2>
        {module.mandatory && <span
          className={'px-2 text-xs font-medium rounded border border-brand-on-primary-container'}>Verplicht</span>}
      </div>
      <div className={'flex items-center'}>
        <div className={'text-xs uppercase my-1 text-brand-secondary'}>
          Categorie&nbsp;:&nbsp;&nbsp;<span className={'font-medium'}>{module.category_id !== null ? categories.find(c => c.id === module.category_id)?.name : '-'}</span>
        </div>
        <button className={'h-6 w-6 hover:bg-[#0001] flex items-center justify-center rounded-lg ml-3'} onClick={() => moveModal.open()}>
          <FontAwesomeIcon icon={faShuffle} className={'text-xs'}/>
        </button>
      </div>
      {module.description.length > 200 ? <p className={'text-sm font-light'}>{module.description.substring(0, 200) + "..."}</p>: <p className={'text-sm font-light'}>{module.description}</p>}
    </div>
    <div className={"ml-4 flex items-center justify-center"}>
      <IconButton icon={faChevronRight} size={'sm'} type={'primary'} onClick={() => navigate(`/learn/${module.id}`)} />
    </div>
  </div>
}

const CategoryBrowser: React.FC<{reloadCategories: () => void, categories: LearnCategory[],   modules: LearnModule[], selected: string | null, onSelect: (category: string|null) => void}> = (props) => {
  const addModal = useModal({title: "Nieuwe categorie", body: <AddLearnCategoryModal parentId={null} onFinished={() => {
    props.reloadCategories()
  }} />})
  const sortedCategories = props.categories.sort((a, b) => a.name.localeCompare(b.name))
  return <div
    className={'2xl:sticky 2xl:top-4 w-72 bg-brand-surface border border-brand-surface-variant px-4 py-2 rounded-lg text-brand-on-primary-container text-sm font-medium'}>
    <div className={'h-10 flex items-center justify-between'}>
      <h2>Categorieën</h2>
      <IconButton icon={faAdd} size={'xs'} type={'secondary'} onClick={() => {
        addModal.open()
      }}/>
    </div>
    <div>
      {sortedCategories.filter(category => category.parent_category_id === null).map(category => <CategoryBrowserItem
        key={category.id}
        categories={props.categories}
        modules={props.modules}
        category={category}
        selected={props.selected}
        onSelect={props.onSelect}
        reloadCategories={props.reloadCategories}
      />)}
    </div>
    <div
      className={`h-10 flex items-center justify-between rounded-lg px-3 -mx-3 cursor-pointer hover:bg-brand-surface-variant ${props.selected === null ? 'text-brand-primary' : ''}`}
      onClick={() => props.onSelect(null)}>
      <FontAwesomeIcon icon={props.selected === null ? faFolderOpen : faFolder} className={'text-base'}/>
      <h2 className={'flex-1 px-3'}>Ongesorteerd</h2>
      <span
        className={'text-xs font-medium mr-9 text-brand-secondary text-right'}>({props.modules.filter(c => c.category_id === null).length})</span>
    </div>
  </div>
}
const CategoryBrowserItem: React.FC<{
  category: LearnCategory,
  categories: LearnCategory[],
  modules: LearnModule[],
  selected: string | null,
  onSelect: (category: string | null) => void
  reloadCategories: () => void
}> = props => {
  const addModal = useModal({
    title: "Nieuwe categorie", body: <AddLearnCategoryModal parentId={props.category.id} onFinished={() => {
      props.reloadCategories()
    }}/>
  })
  const sortedCategories = props.categories.sort((a, b) => a.name.localeCompare(b.name))

  return <div>
    <div
      className={`h-10 flex items-center justify-between rounded-lg px-3 -mx-3 cursor-pointer hover:bg-brand-surface-variant ${props.selected === props.category.id ? 'text-brand-primary' : ''}`}
      onClick={() => props.onSelect(props.selected === props.category.id ? null : props.category.id)}>
      <FontAwesomeIcon icon={props.selected === props.category.id ? faFolderOpen : faFolder} className={'text-base'}/>
      <h2 className={'flex-1 px-3'}>{props.category.name}</h2>
      <span className={'text-xs font-medium mr-1 text-brand-secondary text-right'}>({props.modules.filter(c => c.category_id === props.category.id).length})</span>
      <IconButton icon={faAdd} size={'xs'} type={'secondary'} onClick={() => {
        addModal.open()
      }}/>
    </div>
    <div className={'pl-3'}>
      {sortedCategories.filter(category => category.parent_category_id === props.category.id).map(category => <CategoryBrowserItem
        key={category.id}
        categories={props.categories}
        modules={props.modules}
        category={category}
        selected={props.selected}
        onSelect={props.onSelect}
        reloadCategories={props.reloadCategories}
      />)}
    </div>
  </div>
}


function getChildCategoryIds(categories: LearnCategory[], categoryId: string): string[] {
  return categories
    .filter(category => category.parent_category_id === categoryId)
    .map(category => [category.id, ...getChildCategoryIds(categories, category.id)])
    .flat()
}