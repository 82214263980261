import React, {createContext, FC, ReactNode, useCallback, useContext, useEffect, useState} from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import { Button } from "@jane/lib/src/components/form/Button";


export const ModalProvider: FC<{children: ReactNode}> = ({children}) => {
    return <ModalProviderContextProvider>
        <ModalOverlay />
        {children}
    </ModalProviderContextProvider>
}

export function useModal(modal: Modal) {
    const modalContext = useContext(ModalProviderContext)

    const open = useCallback((): void => {
        modalContext.open(modal)
    }, [modal, modalContext.open])

    return {open}
}

type Modal = {
    title: string,
    body: ReactNode
    size?: 'md'|'lg'|'xl'|'2xl'
}|null

interface ModalProviderContextType {
    modal: Modal
    close: () => void
    // eslint-disable-next-line no-unused-vars
    open: (modal: Modal) => void
}

const ModalProviderContext = createContext({} as ModalProviderContextType)

const ModalProviderContextProvider: FC<{children: ReactNode}> = ({children}) => {
    const [modal, setModal] = useState<Modal>(null)
    useEffect(() => {
        const listener = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                setModal(null)
            }
        }
        window.addEventListener('keydown', listener)
        return () => {
            window.removeEventListener('keydown', listener)
        }
    }, [])

    const close = useCallback(() => {
        setModal(null)
    }, [])

    const open = useCallback((modal: Modal) => {
        setModal(modal)
    }, [])

    return <ModalProviderContext.Provider value={{
        modal,
        close,
        open,
    }}>{children}</ModalProviderContext.Provider>
}

const ModalOverlay: FC = () => {
    const modalContext = useContext(ModalProviderContext)
    if (modalContext.modal === null) {
        return <></>
    }
    const size = modalContext.modal.size ?? 'md'
    const width = {
        'md':  'md:w-2/3 lg:w-1/2 xl:w-1/3 2xl:w-1/4',
        'lg':  'md:w-2/3 lg:w-1/2 xl:w-1/3 2xl:w-1/3',
        'xl':  'md:w-3/4 lg:w-2/3 xl:w-1/2 2xl:w-1/2',
        '2xl': 'md:w-4/5 lg:w-4/5 xl:w-2/3 2xl:w-2/3',
    }[size]
    return <div className={"fixed inset-0 z-50 h-[100vh] w-[100vw] bg-[#000A] flex flex-col items-center justify-center"}>
        <div className={"fixed z-[-1] h-[100vh] w-[100vw]"} onClick={() => modalContext.close()}></div>
        <div className={`h-full md:h-auto w-full ${width} bg-brand-surface text-brand-on-surface rounded-lg shadow-lg`}>
            <header className={"h-12 flex items-center justify-between px-4"}>
                <span className={"font-medium"}>{modalContext.modal.title}</span>
                <button className={"h-10 w-10 -mr-3 text-lg hover:bg-brand-surface-variant rounded"} onClick={() => modalContext.close()}><FontAwesomeIcon icon={faTimes} /></button>
            </header>
            <main className={"max-h-[calc(100vh-8rem)] md:max-h-[calc(80vh-8rem)] overflow-y-auto"}>
              <div className={"mx-4 mt-3"}>
                {modalContext.modal.body}
              </div>
            </main>
        </div>
    </div>
}

export const ModalFooter: FC<{text: string, isDisabled?: boolean, onSubmit?: () => void|Promise<void>}> = props => {
    const {close} = useContext(ModalProviderContext)
    const [isSaving, setIsSaving] = useState(false)
    const clickButton = useCallback(() => {
        const promise = props.onSubmit?.()
        if (promise) {
            setIsSaving(true)
            promise.then(() => {
                setIsSaving(false)
                close()
            }).catch((e) => {
                console.warn('Modal promise rejected', e)
                setIsSaving(false)
            })
        }
    }, [props.onSubmit])

    return <footer className={"-mx-4 px-4 mt-3 py-3 flex items-center space-x-4"}>
      <Button type={'primary'} size={'sm'} text={isSaving ? 'Opslaan...' : props.text} disabled={isSaving || props.isDisabled} onClick={clickButton} />
      <Button type={'secondary'} size={'sm'} text={'Terug'} disabled={isSaving} onClick={() => close()} />
    </footer>
}

export const useModalControls = () => {
    const modalContext = useContext(ModalProviderContext)
    return {
        close: modalContext.close,
    }
}