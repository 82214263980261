import React, {FC, MouseEvent, useEffect, useMemo, useRef} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as fa from "@fortawesome/free-solid-svg-icons";
import {KeyProperties} from "../data/DataTable";

interface AutocompleteProps {
  label: string
  options: {[key: string]: string}
  value: string
  onChange: (newValue: string) => void
}

export const Autocomplete: FC<AutocompleteProps> = (props) =>  {
  const [search, setSearch] = React.useState("")
  const [hasFocus, setHasFocus] = React.useState(false)

  const input = useRef<HTMLInputElement>(null)

  const selectedOptionText = useMemo(() => {
    return props.options[props.value]
  }, [props.options, props.value])

  const options = useMemo(() => Object.entries(props.options).filter(([, value]) => {
    return value.toLowerCase().includes(search.toLowerCase())
  }), [props.options, search])

  const selectOption = (key: string) => {
    setSearch(props.options[key])
    props.onChange(key)
    setHasFocus(false)
    input.current?.blur()
  }

  const [selectedPosition, setSelectedPosition] = React.useState<number>(0)

  useEffect(() => {
    if (! options[selectedPosition]) {
      setSelectedPosition(0)
    }
  }, [selectedPosition, options])

  const onItemClick = (event: MouseEvent<HTMLButtonElement>, key: string) => {
    event.preventDefault()
    selectOption(key)
  }
  const onInputFocus = () => {
    setHasFocus(true)
    setSearch("")
  }
  const onInputBlur = () => {
    setHasFocus(false)
  }

  const onInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "ArrowDown") {
      event.preventDefault()
      if (selectedPosition < options.length - 1) {
        setSelectedPosition(selectedPosition + 1)
      }
    }
    if (event.key === "ArrowUp") {
      event.preventDefault()
      if (selectedPosition > 0) {
        setSelectedPosition(selectedPosition - 1)
      }
    }
    if (event.key === "Enter" || event.key === "Tab") {
      event.preventDefault()
      selectOption(options[selectedPosition][0])
    }
  }

  return <label className={"flex flex-col text-sm font-medium text-brand-900 w-full max-w-md"}>
    {props.label}
    <div className={"relative mt-1 bg-white"}>
      <input
        ref={input}
        onFocus={() => onInputFocus()}
        onBlur={() => onInputBlur()}
        onKeyDown={(e) => onInputKeyDown(e)}
        className={"border-2 border-slate-200 outline-brand-700 text-black rounded text-base font-normal px-2 h-10 w-full"}
        onChange={(e) => setSearch(e.target.value)}
        value={hasFocus ? search : selectedOptionText}
      />

      {/* Chevron icon*/}
      <div className={"absolute top-0 right-2 h-full flex items-center justify-end"}>
        <FontAwesomeIcon icon={fa.faChevronDown} className={"text-xs text-brand-900"} />
      </div>

      {hasFocus && <div className={"absolute top-10 left-0 w-full text-black bg-white rounded shadow z-10 max-h-[220px] overflow-y-scroll"}>
        <div className={"flex flex-col items-stretch"}>
          {options.map(([key, value], index) => {
            return <button key={index} className={`h-10 w-full px-4 ${index === selectedPosition ? "bg-slate-100" : ""} hover:bg-slate-100 ${key === props.value ? "text-brand-800" : ''} text-left`} onMouseDown={(e) => onItemClick(e, key)}>
              {value}
            </button>
          })}
        </div>
      </div>}
    </div>
  </label>
}

interface ObjectAutocompleteProps<T> {
  label: string
  options: T[]
  value: T|null
  onChange: (newValue: T) => void
  displayItem: (obj: T) => string
  idProperty: KeyProperties<T>
}
export const ObjectAutocomplete = function<T>(props: ObjectAutocompleteProps<T>): JSX.Element {
  const getDisplayValue = (option: T) => {
    return props.displayItem(option)
  }
  const getKey = (option: T) => {
    return option[props.idProperty]
  }

  const options = useMemo(() => {
    return props.options.reduce<AutocompleteProps["options"]>((map, option) => {
      const key = getKey(option)
      // @ts-ignore
      map[key] = getDisplayValue(option)
      return map
    }, {})
  }, [props.options])

  const onChange = (newKey: string) => {
    const value = props.options.find(option => String(getKey(option)) === String(newKey))
    if (value) {
      props.onChange(value)
    }
  }
  if (! props.value) {
    return <AutocompletePlaceholder label={props.label} />
  }
  return <Autocomplete label={props.label} options={options} value={getKey(props.value) as string} onChange={onChange} />
}

const AutocompletePlaceholder: FC<{label: string}> = (props) => {
  return <div className={"flex flex-col text-sm font-medium text-brand-900 w-full max-w-md"}>
    {props.label}
    <div className={"relative mt-1"}>
      <div className={"border-2 border-slate-200 outline-brand-700 text-black rounded text-base font-normal px-2 h-10 w-full"} />
      {/* Chevron icon*/}
      <div className={"absolute top-0 right-2 h-full flex items-center justify-end"}>
        <FontAwesomeIcon icon={fa.faChevronDown} className={"text-xs text-brand-900"} />
      </div>
    </div>
  </div>
}